import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { Goods } from '../app.class/goods';
import { map } from 'rxjs/operators';
import { GlobalConstants } from '../common/global-constants';
import { TokenStorageService } from '../_services/token-storage.service';


@Injectable({
  providedIn: 'root'
})
export class GoodsService {

  private baseURL = GlobalConstants.httpPath + "goods";
  private baseVisitURL = GlobalConstants.httpPath + "goods_count";
  private person : any;

  constructor(private httpClient: HttpClient, private tokenStorage: TokenStorageService) { }
  
  getGoodsList(): Observable<Goods[]>{
    
    this.person= this.httpClient.get(this.baseURL)
    .pipe(map((response: any) => response.json()));

    // this.httpClient.get<Goods[]>(`${this.baseURL}`);
    // alert(this.baseURL)
    return this.httpClient.get<Goods[]>(`${this.baseURL}`);
  }

  // getDataList(ind? :number ,mt? : c_billingdoc): Observable<c_billingdoc[]>{
  //   this.person= this.httpClient.get(this.baseURL)
  //   .pipe(map((response: any) => response.json()));
 
  //   this.baseURL = GC.httpPath + this.tP +"/allkey?ind=" + ind +"&id="+ mt.id + "&documentno=" + mt.documentno + "&documentdate=" + mt.documentdate + 
  //   "&statusflag=" + mt.statusflag + "&cusid=" + mt.cusid + "&companyid=" + mt.companyid + "&doccumenttype=" + mt.documenttype + "&partid=" + mt.partid + 
  //   "&partgroupid=" + mt.partgroupid + "&supid=" + mt.supid;
  //       //  alert(this.baseURL);  
  //   return this.httpClient.get<c_billingdoc[]>(`${this.baseURL}`);
  // }


  getDataList(ind? :number ,mt? : Goods): Observable<Goods[]>{
    this.person= this.httpClient.get(this.baseURL)
    .pipe(map((response: any) => response.json()));
 
    this.baseURL = this.httpClient + this.baseURL +"/allkey?ind=" + ind +"&id="+ mt.id + "&groupId=" + mt.groupId;
        // alert(this.baseURL);  
    return this.httpClient.get<Goods[]>(`${this.baseURL}`);
  }

  createGoods(goods: Goods): Observable<Object>{
    return this.httpClient.post(`${this.baseURL}`, goods);
  }

  getGoodsById(id: number): Observable<Goods>{
    return this.httpClient.get<Goods>(`${this.baseURL}/${id}`);
  }

  updateGoods(id: number, goods: Goods): Observable<Object>{
    return this.httpClient.put(`${this.baseURL}/${id}`, goods);
  }

  updateVisits(id: number, goods: Goods): Observable<Object>{
    return this.httpClient.put(`${this.baseVisitURL}/${id}`, goods);
  }

  deleteGoods(id: number): Observable<Object>{
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }
}
