import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenubarComponent } from './app.components/menubar/menubar.component';
import { FooterbarComponent } from './app.components/footerbar/footerbar.component';
import { MainpageComponent } from './app.components/mainpage/mainpage.component';
import { AboutusComponent } from './app.components/aboutus/aboutus.component';
import { BusinessusComponent } from './app.components/businessus/businessus.component';
import { ProductAndServicesComponent } from './app.components/product-and-services/product-and-services.component';
import { NewsAndEventsComponent } from './app.components/news-and-events/news-and-events.component';
import { WorkWithUsComponent } from './app.components/work-with-us/work-with-us.component';
import { ContactusComponent } from './app.components/contactus/contactus.component';
import { TestareaComponent } from './app.components/testarea/testarea.component';
import { EmployeeListComponent } from './app.components/employee-list/employee-list.component';
import { HttpClientModule } from '@angular/common/http'
import { ReactiveFormsModule } from '@angular/forms';
import { GoodsPopupComponent } from './app.components/goods-popup/goods-popup.component';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductItem1Component } from './app.components/product-item1/product-item1.component';
import { NewsComponent } from './app.components/news/news.component';
import { Testarea2Component } from './app.components/testarea2/testarea2.component';

// import { ProductItem1Component } from './app.components/product-item1/product-item1.component';

import { UploadFilesComponent } from './app.components/upload-files/upload-files.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { WorkWithUsItemsComponent } from './app.components/work-with-us-items/work-with-us-items.component';
import { QualityManagementComponent } from './app.components/quality-management/quality-management.component';
import { RoleComponent } from './app.components/role/role.component';
import { ProductAndServicesDetailComponent } from './app.components/product-and-services-detail/product-and-services-detail.component';
import { NgxImgZoomModule  } from 'ngx-img-zoom';
import { ArticleComponent } from './app.components/article/article.component';
import { ArticleItemComponent } from './app.components/article-item/article-item.component';
import { AddEditComponent } from './app.components/add-edit/add-edit.component';
import { QuestionnaireComponent } from './app.components/questionnaire/questionnaire.component';
import { KingdomFeedmillsComponent } from './app.components/kingdom-feedmills/kingdom-feedmills.component';
import { KingdomAnimalMedicineComponent } from './app.components/kingdom-animal-medicine/kingdom-animal-medicine.component';
import { KingdomFarmComponent } from './app.components/kingdom-farm/kingdom-farm.component';
import { KingdomSupplementaryFoodComponent } from './app.components/kingdom-supplementary-food/kingdom-supplementary-food.component';
import { LoginComponent } from './app.components/login/login.component';
import { RegisterComponent } from './app.components/register/register.component';

import { authInterceptorProviders } from './_helpers/auth.interceptor';

import { WorkWithUsFeedmillsComponent } from './app.components/work-with-us-feedmills/work-with-us-feedmills.component';
import { WorkWithUsFarmComponent } from './app.components/work-with-us-farm/work-with-us-farm.component';
import { ContactusFeedmillsComponent } from './app.components/contactus-feedmills/contactus-feedmills.component';
import { ContactusFarmComponent } from './app.components/contactus-farm/contactus-farm.component';
import { IntroPageComponent } from './app.components/intro-page/intro-page.component';


@NgModule({
  declarations: [
    AppComponent,
    MenubarComponent,
    FooterbarComponent,
    MainpageComponent,
    AboutusComponent,
    BusinessusComponent,
    ProductAndServicesComponent,
    NewsAndEventsComponent,
    WorkWithUsComponent,
    ContactusComponent,
    TestareaComponent,
    EmployeeListComponent,
    GoodsPopupComponent,
    NewsComponent,
    Testarea2Component,
    ProductItem1Component,
    UploadFilesComponent,
    WorkWithUsItemsComponent,
    QualityManagementComponent,
    RoleComponent,
    ProductAndServicesDetailComponent,
    ArticleComponent,
    ArticleItemComponent,
    AddEditComponent,
    QuestionnaireComponent,
    KingdomFeedmillsComponent,
    KingdomAnimalMedicineComponent,
    KingdomFarmComponent,
    KingdomSupplementaryFoodComponent,
    LoginComponent,
    RegisterComponent,
    WorkWithUsFeedmillsComponent,
    WorkWithUsFarmComponent,
    ContactusFeedmillsComponent,
    ContactusFarmComponent,
    IntroPageComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgxImgZoomModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
