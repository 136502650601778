
<!DOCTYPE html>
<html lang="th">

<head>

    
    <meta charset="utf-8">
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="google-site-verification" content="n3VTml-esr2g9NT_9mPu8TarlmrlckV4IxG1RG0o84s">
    <meta name="csrf-token" content="2Z2cTnBDXqypFTkXW0HWQINTAQfM7yKxQom7dafh">

    
    <link href="https://www.betagro-investor.com/storage/favicon.ico" rel="shortcut icon"/>

    <title>ข่าวแจ้งตลาดหลักทรัพย์ | BETAGRO (BTG)</title>

    <link rel="stylesheet" href="/themes/default/assets/vendor.b2b2b23f9b720784e6ff.css">
<link rel="stylesheet" href="/themes/default/assets/theme.74a26c886bddf44f8393.css">
<link rel="stylesheet" href="/themes/default/assets/cookie.bbc2571fc9c1ff9fc3e3.css">

    <!-- Cookie Consent -->
<script type="text/javascript" src="https://cookiecdn.com/cwc.js"></script>
<script id="cookieWow" type="text/javascript" src="https://cookiecdn.com/configs/gbp92JxLnkbyMvayHm83VdyL" data-cwcid="gbp92JxLnkbyMvayHm83VdyL"></script>
    
    
        

    

</head>
<body>

    <div id="vue-app" v-cloak>

        <div class="preloader" :class="reloadStyle">
            <div class="preloader__image">
                <img class="w-100" src="https://www.betagro-investor.com/themes/default/assets/static/images/default/preload.gif" alt="" />
            </div>
        </div>

        <header class="header fixed-top ">
    <div class="container">
        <div class="row">
            <div class="col-auto position-relative">
                <a href="https://www.betagro.com/th/home" class="header__brand" target="_blank">
                    <img class="header__logo" src="https://www.betagro-investor.com/themes/default/assets/static/images/default/logo.svg"/>
                </a>
            </div>
            <div class="col pt-1">
                <div class="header__top">
                    <div class="row gx-0">
                        <div class="col-auto ms-auto">
                            <nav class="nav nav--top">

            <li class="nav-item nav-item--stock">
            <div class="nav-wrapper">


            <div class="stock stock--bar">
                BTG (BETAGRO PCL)
                : 31.75 THB - (-%)
            </div>


            <a class="nav-link" href="https://www.betagro-investor.com/th/stock-information/stock-quote" target="_self">
                ราคาหลักทรัพย์
            </a>
            </div>
        </li>
    
        
            <li class="nav-item " >
                <a class="nav-link" href="https://www.betagro.com/th/contact-us" target="_self">
                    ติดต่อเรา
                </a>
            </li>

        
    <li class="nav-item nav-item--social">
        
            <a class="nav-link " href="https://www.facebook.com/BetagroGroupOfficial" target="_blank">
                <span class="icon-social-fb"><span class="path1"></span><span class="path2"></span></span>
            </a>

        
            <a class="nav-link " href="https://www.youtube.com/channel/UCagW4zzCJjygyqbc251S9Aw" target="_blank">
                <span class="icon-social-youtube"><span class="path1"></span><span class="path2"></span></span>
            </a>

        
            <a class="nav-link " href="https://th.linkedin.com/company/betagro-group" target="_blank">
                <span class="icon-social-linkedin"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
            </a>

        
            <a class="nav-link " href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#99;&#111;&#110;&#116;&#97;&#99;&#116;&#99;&#101;&#110;&#116;&#101;&#114;&#64;&#98;&#101;&#116;&#97;&#103;&#114;&#111;&#46;&#99;&#111;&#109;" target="_self">
                <div class="nav-icon"><i class="icon-mail"></i></div>
            </a>

            </li>

    <li class="nav-item nav-item--search">
        <a class="nav-link" href="https://www.betagro-investor.com/th/search" target="_self">
            <i class="fal fa-search"></i>
        </a>
    </li>
</nav>
                        </div>
                        <div class="col-auto">
                            <nav class="nav nav--lang">
                                <li class="nav-item">
                                    <a class="nav-link" target="_self" href="https://www.betagro-investor.com/en/newsroom/set-announcements">
                                      EN
                                    </a>
                                </li>
                            </nav>
                        </div>
                        <div class="col-auto d-xl-none">
                            <a class="menu-toggler" data-bs-toggle="collapse" data-bs-target="#mainMenu" href="javascript:void(0);">
                                <div class="hamburger">
                                    <div class="hamburger__line"></div>
                                    <div class="hamburger__line"></div>
                                    <div class="hamburger__line"></div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <nav class="navbar navbar-expand-xl navbar--main ">
    <div class="collapse navbar-collapse" id="mainMenu">
        <div class="container px-0">

            

            <div class="row gx-0 justify-content-xl-end">
                <div class="col-12 col-xl-auto">
                    <ul class="navbar-nav">
                                                                        
                                                <li class="nav-item d-xl-none">
                            <a class="nav-link " href="https://www.betagro.com/th/home" target="_self">
                                หน้าหลัก
                            </a>
                        </li>
                                                                                                
                                                <li class="nav-item dropdown ">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0);">
                                เกี่ยวกับเรา
                            </a>
                            <div class="dropdown-menu dropdown-menu--wrapper">
                                <div class="container px-xl-4">
                                    <div class="row">
                                        
                                        <div class="col-xl-4 ps-4 pe-3 d-none d-xl-block">
                                            <div class="dropdown-menu__image">

                                                <img class="img-fluid" src="https://www.betagro.com/storage/menu/about-us.png" alt="">
                                            </div>
                                        </div>
                                        <div class="col-12 col-xl-8">
                                            <ul class="dropdown-menu dropdown-menu--lv2">
                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/about-us/betagro-at-a-glance"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        ภาพรวมเบทาโกร

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/about-us/purpose-and-belief"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        วัตถุประสงค์และความเชื่อ

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/about-us/key-milestones"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        การเปลี่ยนแปลงและพัฒนาการที่สำคัญ

                                                                                                            </a>
                                                </li>
                                                                                                                                                                                                <li class="dropdown-item dropdown">
                                                    <a href="javascript:void(0);" class="dropdown-link dropdown-toggle">
                                                        คณะกรรมการและโครงสร้างการดำเนินธุรกิจ
                                                    </a>
                                                    <ul class="dropdown-menu dropdown-menu--lv3">
                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/about-us/committee-group-business-structure/group-business-structure"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                โครงสร้างการดำเนินธุรกิจ
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/about-us/committee-group-business-structure/organization-chart"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ผังองค์กร
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/management/board-of-directors"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                คณะกรรมการบริษัท
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/management/audit-committee"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                คณะกรรมการตรวจสอบ
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/management/nomination-and-remuneration-committee"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                คณะกรรมการสรรหาและพิจารณาค่าตอบแทน
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/management/risk-management-committee"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                คณะกรรมการบริหารความเสี่ยง
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/management/corporate-governance-and-sustainable-development-committee"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                คณะกรรมการบรรษัทภิบาลและการพัฒนาอย่างยั่งยืน
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/management/management-committee-charter"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                คณะผู้บริหารระดับสูง
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/about-us/committee-group-business-structure/subsidiaries-and-associated-companies"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                บริษัทย่อย บริษัทร่วมและกิจการร่วมค้า
                                                            </a>
                                                        </li>

                                                        
                                                    </ul>
                                                </li>
                                                                                                                                                                                                <li class="dropdown-item dropdown">
                                                    <a href="javascript:void(0);" class="dropdown-link dropdown-toggle">
                                                        รางวัลและการรับรอง
                                                    </a>
                                                    <ul class="dropdown-menu dropdown-menu--lv3">
                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/about-us/awards-and-recognitions"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ทั้งหมด
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/about-us/awards-and-recognitions/quality-and-safety"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ด้านคุณภาพและความปลอดภัย
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/about-us/awards-and-recognitions/workforce-management"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ด้านการจัดการแรงงาน
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/about-us/awards-and-recognitions/organization"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ด้านองค์กร
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/about-us/awards-and-recognitions/product-and-marketing"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ด้านผลิตภัณฑ์และการตลาด
                                                            </a>
                                                        </li>

                                                        
                                                    </ul>
                                                </li>
                                                                                                
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </li>
                                                                                                
                                                <li class="nav-item dropdown ">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0);">
                                ธุรกิจเบทาโกร
                            </a>
                            <div class="dropdown-menu dropdown-menu--wrapper">
                                <div class="container px-xl-4">
                                    <div class="row">
                                        
                                        <div class="col-xl-4 ps-4 pe-3 d-none d-xl-block">
                                            <div class="dropdown-menu__image">

                                                <img class="img-fluid" src="https://www.betagro.com/storage/menu/business.png" alt="">
                                            </div>
                                        </div>
                                        <div class="col-12 col-xl-8">
                                            <ul class="dropdown-menu dropdown-menu--lv2">
                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/our-business/business-overview"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        ภาพรวมของธุรกิจ

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/our-business/food-and-protein-business"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        กลุ่มธุรกิจอาหารและโปรตีน

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/our-business/agro-business"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        กลุ่มธุรกิจเกษตร

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/our-business/international-business"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        กลุ่มธุรกิจต่างประเทศ

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/our-business/pet-business"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        กลุ่มธุรกิจสัตว์เลี้ยง

                                                                                                            </a>
                                                </li>
                                                                                                
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </li>
                                                                                                
                                                <li class="nav-item dropdown ">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0);">
                                ระบบจัดการคุณภาพและนวัตกรรม
                            </a>
                            <div class="dropdown-menu dropdown-menu--wrapper">
                                <div class="container px-xl-4">
                                    <div class="row">
                                        
                                        <div class="col-xl-4 ps-4 pe-3 d-none d-xl-block">
                                            <div class="dropdown-menu__image">

                                                <img class="img-fluid" src="https://www.betagro.com/storage/menu/innovation.png" alt="">
                                            </div>
                                        </div>
                                        <div class="col-12 col-xl-8">
                                            <ul class="dropdown-menu dropdown-menu--lv2">
                                                                                                                                                <li class="dropdown-item dropdown">
                                                    <a href="javascript:void(0);" class="dropdown-link dropdown-toggle">
                                                        การวิจัยและพัฒนา
                                                    </a>
                                                    <ul class="dropdown-menu dropdown-menu--lv3">
                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/innovation-and-quality-management/research-and-development/research-and-development-center"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ศูนย์วิจัยและพัฒนา
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/innovation-and-quality-management/research-and-development/agro-innovation-center"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ศูนย์นวัตกรรมการเกษตร
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/innovation-and-quality-management/research-and-development/food-innovation-center"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ศูนย์นวัตกรรมอาหาร
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/innovation-and-quality-management/research-and-development/pet-innovation-center"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ศูนย์นวัตกรรมสัตว์เลี้ยง
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/innovation-and-quality-management/research-and-development"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                การวิจัยและพัฒนา
                                                            </a>
                                                        </li>

                                                        
                                                    </ul>
                                                </li>
                                                                                                                                                                                                <li class="dropdown-item dropdown">
                                                    <a href="javascript:void(0);" class="dropdown-link dropdown-toggle">
                                                        ระบบจัดการคุณภาพมาตรฐาน
                                                    </a>
                                                    <ul class="dropdown-menu dropdown-menu--lv3">
                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/innovation-and-quality-management/quality-management/betagro-quality-management-bqm"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                การรับประกันคุณภาพ
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/innovation-and-quality-management/quality-management/bqm-for-poultry-and-egg-production"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ระบบการจัดการคุณภาพเบทาโกร สำหรับการผลิตสัตว์ปีกและไข่ไก่
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/innovation-and-quality-management/quality-management/bqm-for-swine-production"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ระบบการจัดการคุณภาพเบทาโกร สำหรับการผลิตสุกร
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/innovation-and-quality-management/quality-management/betagro-e-traceability"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ระบบตรวจสอบย้อนกลับอิเล็กทรอนิกส์ เบทาโกร
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/innovation-and-quality-management/quality-management/geographic-information-system"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ระบบสารสนเทศภูมิศาสตร์
                                                            </a>
                                                        </li>

                                                        
                                                    </ul>
                                                </li>
                                                                                                                                                                                                <li class="dropdown-item dropdown">
                                                    <a href="javascript:void(0);" class="dropdown-link dropdown-toggle">
                                                        ความปลอดภัยทางชีวภาพ
                                                    </a>
                                                    <ul class="dropdown-menu dropdown-menu--lv3">
                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro.com/th/innovation-and-quality-management/biosecurity/betagro-biosecurity-management"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                มาตรการความปลอดภัยทางชีวภาพเบทาโกร
                                                            </a>
                                                        </li>

                                                        
                                                    </ul>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/innovation-and-quality-management/information-technology"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        ระบบเทคโนโลยีสารสนเทศ

                                                                                                            </a>
                                                </li>
                                                                                                
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </li>
                                                                                                
                                                <li class="nav-item dropdown ">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0);">
                                การกำกับดูแลกิจการ
                            </a>
                            <div class="dropdown-menu dropdown-menu--wrapper">
                                <div class="container px-xl-4">
                                    <div class="row">
                                        
                                        <div class="col-xl-4 ps-4 pe-3 d-none d-xl-block">
                                            <div class="dropdown-menu__image">

                                                <img class="img-fluid" src="https://www.betagro.com/storage/menu/corporate-governance.png" alt="">
                                            </div>
                                        </div>
                                        <div class="col-12 col-xl-8">
                                            <ul class="dropdown-menu dropdown-menu--lv2">
                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/corporate-governance/corporate-policy"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        นโยบายด้านการกำกับดูแลกิจการ

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/corporate-governance/whistleblowing"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        การแจ้งเบาะแส

                                                                                                            </a>
                                                </li>
                                                                                                
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </li>
                                                                                                
                        
                                                <li
                            class="nav-item dropdown  ">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0);">
                                นักลงทุนสัมพันธ์
                            </a>
                            <div class="dropdown-menu dropdown-menu--wrapper ">
                                <div class="container px-xl-4">
                                    <div class="row">
                                                                                <div class="col-xl-4 ps-4 pe-3 d-none d-xl-block">
                                            <div class="dropdown-menu__image">
                                                <img class="img-fluid" src="https://www.betagro-investor.com/storage/menu/ir.png" alt="">
                                            </div>
                                            
                                        </div>
                                        <div class="col-12 col-xl-8">
                                            <ul class="dropdown-menu dropdown-menu--lv2">
                                                                                                                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro-investor.com/th/home"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        หน้าหลักนักลงทุนสัมพันธ์
                                                    </a>
                                                </li>
                                                                                                                                                                                                <li class="dropdown-item dropdown">
                                                    <a href="javascript:void(0);" class="dropdown-link dropdown-toggle">
                                                        ข้อมูลทางการเงิน
                                                    </a>
                                                    <ul class="dropdown-menu dropdown-menu--lv3">
                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro-investor.com/th/financial-information/financial-highlights"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ข้อมูลสำคัญทางการเงิน
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro-investor.com/th/downloads/financial-statements"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                งบการเงิน
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro-investor.com/th/downloads/mdna"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                คำอธิบายและการวิเคราะห์ของฝ่ายจัดการ
                                                            </a>
                                                        </li>

                                                        
                                                    </ul>
                                                </li>
                                                                                                                                                                                                <li class="dropdown-item dropdown">
                                                    <a href="javascript:void(0);" class="dropdown-link dropdown-toggle">
                                                        ข้อมูลราคาหลักทรัพย์
                                                    </a>
                                                    <ul class="dropdown-menu dropdown-menu--lv3">
                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro-investor.com/th/stock-information/stock-quote"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ราคาหลักทรัพย์
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro-investor.com/th/stock-information/historical-price"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ราคาหลักทรัพย์ย้อนหลัง
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro-investor.com/th/stock-information/investment-calculator"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                เครื่องคำนวณการลงทุน
                                                            </a>
                                                        </li>

                                                        
                                                    </ul>
                                                </li>
                                                                                                                                                                                                <li class="dropdown-item dropdown">
                                                    <a href="javascript:void(0);" class="dropdown-link dropdown-toggle">
                                                        ห้องข่าวนักลงทุน
                                                    </a>
                                                    <ul class="dropdown-menu dropdown-menu--lv3">
                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro-investor.com/th/newsroom/set-announcements"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ข่าวแจ้งตลาดหลักทรัพย์
                                                            </a>
                                                        </li>

                                                        
                                                    </ul>
                                                </li>
                                                                                                                                                                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro-investor.com/th/updates/ir-press-releases"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        ข่าวแจ้งสื่อมวลชน
                                                    </a>
                                                </li>
                                                                                                                                                                                                <li class="dropdown-item dropdown">
                                                    <a href="javascript:void(0);" class="dropdown-link dropdown-toggle">
                                                        เอกสารเผยแพร่
                                                    </a>
                                                    <ul class="dropdown-menu dropdown-menu--lv3">
                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro-investor.com/th/downloads/prospectus"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                หนังสือชี้ชวนเสนอขายหลักทรัพย์
                                                            </a>
                                                        </li>

                                                        
                                                    </ul>
                                                </li>
                                                                                                                                                                                                <li class="dropdown-item dropdown">
                                                    <a href="javascript:void(0);" class="dropdown-link dropdown-toggle">
                                                        เอกสารนำเสนอและเว็บแคสต์
                                                    </a>
                                                    <ul class="dropdown-menu dropdown-menu--lv3">
                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro-investor.com/th/downloads/presentations-webcasts/analyst-meeting"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                เอกสารนำเสนอการประชุมและเว็บแคสต์
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro-investor.com/th/downloads/download-center"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ดาวน์โหลดเอกสารนักลงทุน
                                                            </a>
                                                        </li>

                                                        
                                                    </ul>
                                                </li>
                                                                                                                                                                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro-investor.com/th/calendar"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        ปฏิทินนักลงทุนสัมพันธ์ 
                                                    </a>
                                                </li>
                                                                                                                                                                                                <li class="dropdown-item dropdown">
                                                    <a href="javascript:void(0);" class="dropdown-link dropdown-toggle">
                                                        ข้อมูลผู้ถือหุ้น
                                                    </a>
                                                    <ul class="dropdown-menu dropdown-menu--lv3">
                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro-investor.com/th/shareholder-information/major-shareholders"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                รายชื่อผู้ถือหุ้นรายใหญ่
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro-investor.com/th/shareholder-information/dividend-policy-and-payment"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                นโยบายการจ่ายเงินปันผล
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro-investor.com/th/downloads/shareholders-meeting"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                การประชุมผู้ถือหุ้น
                                                            </a>
                                                        </li>

                                                        
                                                    </ul>
                                                </li>
                                                                                                                                                                                                <li class="dropdown-item dropdown">
                                                    <a href="javascript:void(0);" class="dropdown-link dropdown-toggle">
                                                        สอบถามข้อมูล
                                                    </a>
                                                    <ul class="dropdown-menu dropdown-menu--lv3">
                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro-investor.com/th/information-inquiry/ir-contact"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                ติดต่อนักลงทุนสัมพันธ์
                                                            </a>
                                                        </li>

                                                        

                                                        <li class="dropdown-item">
                                                            <a href="https://www.betagro-investor.com/th/information-inquiry/email-alert"
                                                                target="_self"
                                                                class="dropdown-link">
                                                                อีเมลรับข่าวสาร
                                                            </a>
                                                        </li>

                                                        
                                                    </ul>
                                                </li>
                                                                                                
                                            </ul>
                                        </div>
                                                                            </div>
                                </div>
                            </div>
                        </li>
                        
                                                                                                
                                                <li class="nav-item dropdown ">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0);">
                                ความยั่งยืน
                            </a>
                            <div class="dropdown-menu dropdown-menu--wrapper">
                                <div class="container px-xl-4">
                                    <div class="row">
                                        
                                        <div class="col-xl-4 ps-4 pe-3 d-none d-xl-block">
                                            <div class="dropdown-menu__image">

                                                <img class="img-fluid" src="https://www.betagro.com/storage/menu/sustainability.png" alt="">
                                            </div>
                                        </div>
                                        <div class="col-12 col-xl-8">
                                            <ul class="dropdown-menu dropdown-menu--lv2">
                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/sustainability/sustainability-at-a-glance"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        เบทาโกรกับความยั่งยืน

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/sustainability/contributing-to-the-sdgs"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        การสนับสนุนเป้าหมายการพัฒนาที่ยั่งยืน

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/sustainability/sustainability-policies"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        นโยบายด้านความยั่งยืน

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/sustainability/corporate-governance-dimension"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        มิติด้านการกำกับดูแลกิจการ

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/sustainability/economic-dimension"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        มิติด้านเศรษฐกิจ 

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/sustainability/social-dimension"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        มิติด้านสังคม

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/sustainability/environmental-dimension"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        มิติด้านสิ่งแวดล้อม

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/management/corporate-governance-and-sustainable-development-committee"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        คณะกรรมการบรรษัทภิบาลและการพัฒนาอย่างยั่งยืน

                                                                                                            </a>
                                                </li>
                                                                                                
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </li>
                                                                                                
                                                <li class="nav-item dropdown ">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0);">
                                ข่าวและกิจกรรม
                            </a>
                            <div class="dropdown-menu dropdown-menu--wrapper">
                                <div class="container px-xl-4">
                                    <div class="row">
                                        
                                        <div class="col-xl-4 ps-4 pe-3 d-none d-xl-block">
                                            <div class="dropdown-menu__image">

                                                <img class="img-fluid" src="https://www.betagro.com/storage/menu/news-activites.png" alt="">
                                            </div>
                                        </div>
                                        <div class="col-12 col-xl-8">
                                            <ul class="dropdown-menu dropdown-menu--lv2">
                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/updates/news-release"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        ข่าวประชาสัมพันธ์

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/updates/company-activities"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        กิจกรรมของบริษัท

                                                                                                            </a>
                                                </li>
                                                                                                
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </li>
                                                                                                
                                                <li class="nav-item dropdown ">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0);">
                                ร่วมงานกับเรา
                            </a>
                            <div class="dropdown-menu dropdown-menu--wrapper">
                                <div class="container px-xl-4">
                                    <div class="row">
                                        
                                        <div class="col-xl-4 ps-4 pe-3 d-none d-xl-block">
                                            <div class="dropdown-menu__image">

                                                <img class="img-fluid" src="https://www.betagro.com/storage/menu/20230127-btg-career.png" alt="">
                                            </div>
                                        </div>
                                        <div class="col-12 col-xl-8">
                                            <ul class="dropdown-menu dropdown-menu--lv2">
                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://careers.betagro.com/"
                                                        target="_blank"
                                                        class="dropdown-link">
                                                        สมัครงาน

                                                                                                                <div class="dropdown-icon">
                                                            <i class="icon-linkout"></i>
                                                        </div>
                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/join-us/why-join-betagro"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        ร่วมงานกับเบทาโกร...ดีอย่างไร

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/join-us/lifebetagro"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        ชีวิตดีๆที่เบทาโกร

                                                                                                            </a>
                                                </li>
                                                                                                                                                
                                                <li class="dropdown-item">
                                                    <a href="https://www.betagro.com/th/join-us/internship-program"
                                                        target="_self"
                                                        class="dropdown-link">
                                                        ฝึกงานกับเบทาโกร

                                                                                                            </a>
                                                </li>
                                                                                                
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </li>
                                                                                                
                                                <li class="nav-item d-xl-none">
                            <a class="nav-link " href="https://www.betagro.com/th/contact-us" target="_self">
                                ติดต่อเรา
                            </a>
                        </li>
                                                                                                
                    </ul>
                </div>
            </div>
        </div>
    </div>
</nav>

                    </div>
                </div>

            </div>
        </div>
    </div>
</header>

        

        <main class="main">
            
    <div class="section section--intro" style="background-image: url('https://www.betagro-investor.com/themes/default/assets/static/images/default/banner-highlight.jpg');">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="section__title">
                        ข่าวแจ้งตลาดหลักทรัพย์
                    </h1>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-10">
                                        <div class="card card--horizontal">
                        <img src="https://www.betagro-investor.com/themes/default/assets/static/images/default/newsroom-highlight.jpg" class="card__image">
                        <div class="card__body">
                            <time class="card__time">
                            27 ม.ค. 2566
                            </time>
                            <p>
                            รายงานการใช้เงินเพิ่มทุนที่ได้รับจากการเสนอขายหุ้นสามัญเพิ่มทุนต่อประชาชนทั่วไปเป็นครั้งแรก (IPO)
                            </p>
                            <a href="//ir.listedcompany.com/tracker.pl?type=6&amp;amp;id=2423142" class="btn btn--primary">
                                <span class="btn__text">
                                    อ่านเพิ่มเติม
                                </span>
                                <span class="btn__icon">
                                    <i class="icon-arrow-right"></i>
                                </span>
                            </a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <div class="navbar navbar--submenu">
    <div class="container justify-content-center">
        <ul class="navbar-nav">

            <li class="nav-item">
                <a class="nav-link nav-link--home" href="https://www.betagro-investor.com/th/home" target="_self">
                    <i class="icon-home"></i>
                </a>
            </li>
             
                          
                    <li class="nav-item dropdown ">
                        <a class="nav-link dropdown-toggle" href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false">
                            นักลงทุนสัมพันธ์
                        </a>
                        <div class="dropdown-menu dropdown-menu--submenu">
                            <ul class="nav nav--submenu">
                                                                <li class="nav-item ">
                                    <a class="nav-link" href="https://www.betagro-investor.com/th/home" target="_self">
                                        นักลงทุนสัมพันธ์
                                    </a>
                                </li>
                                
                            </ul>
                        </div>
                    </li>
               
                          
                    <li class="nav-item dropdown ">
                        <a class="nav-link dropdown-toggle" href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false">
                            ห้องข่าวนักลงทุน
                        </a>
                        <div class="dropdown-menu dropdown-menu--submenu">
                            <ul class="nav nav--submenu">
                                                                <li class="nav-item ">
                                    <a class="nav-link" href="https://www.betagro-investor.com/th/home" target="_self">
                                        หน้าหลักนักลงทุนสัมพันธ์
                                    </a>
                                </li>
                                                                <li class="nav-item ">
                                    <a class="nav-link" href="https://www.betagro-investor.com/th/financial-information/financial-highlights" target="_self">
                                        ข้อมูลทางการเงิน
                                    </a>
                                </li>
                                                                <li class="nav-item ">
                                    <a class="nav-link" href="https://www.betagro-investor.com/th/stock-information/stock-quote" target="_self">
                                        ข้อมูลราคาหลักทรัพย์
                                    </a>
                                </li>
                                                                <li class="nav-item ">
                                    <a class="nav-link" href="https://www.betagro-investor.com/th/newsroom/set-announcements" target="_self">
                                        ห้องข่าวนักลงทุน
                                    </a>
                                </li>
                                                                <li class="nav-item ">
                                    <a class="nav-link" href="https://www.betagro-investor.com/th/updates/ir-press-releases" target="_self">
                                        ข่าวแจ้งสื่อมวลชน
                                    </a>
                                </li>
                                                                <li class="nav-item ">
                                    <a class="nav-link" href="https://www.betagro-investor.com/th/downloads/prospectus" target="_self">
                                        เอกสารเผยแพร่
                                    </a>
                                </li>
                                                                <li class="nav-item ">
                                    <a class="nav-link" href="https://www.betagro-investor.com/th/downloads/download-center" target="_self">
                                        เอกสารนำเสนอและเว็บแคสต์
                                    </a>
                                </li>
                                                                <li class="nav-item ">
                                    <a class="nav-link" href="https://www.betagro-investor.com/th/calendar" target="_self">
                                        ปฏิทินนักลงทุนสัมพันธ์ 
                                    </a>
                                </li>
                                                                <li class="nav-item ">
                                    <a class="nav-link" href="https://www.betagro-investor.com/th/shareholder-information/major-shareholders" target="_self">
                                        ข้อมูลผู้ถือหุ้น
                                    </a>
                                </li>
                                                                <li class="nav-item ">
                                    <a class="nav-link" href="https://www.betagro-investor.com/th/information-inquiry/ir-contact" target="_self">
                                        สอบถามข้อมูล
                                    </a>
                                </li>
                                
                            </ul>
                        </div>
                    </li>
               
                          
                    <li class="nav-item dropdown ">
                        <a class="nav-link dropdown-toggle" href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false">
                            ข่าวแจ้งตลาดหลักทรัพย์
                        </a>
                        <div class="dropdown-menu dropdown-menu--submenu">
                            <ul class="nav nav--submenu">
                                                                <li class="nav-item ">
                                    <a class="nav-link" href="https://www.betagro-investor.com/th/newsroom/set-announcements" target="_self">
                                        ข่าวแจ้งตลาดหลักทรัพย์
                                    </a>
                                </li>
                                
                            </ul>
                        </div>
                    </li>
               
                                </ul>
    </div>
</div>

    <section class="section section--gradient-gray py-5">
        <div class="container">
            <div class="row justify-content-end">
                <div class="col-md-5 col-lg-3">
                    <form class="filter filter--year mb-5">
        <label for="year2" class="d-block h4 mr-md-2 mb-0">ปี</label>
        <div class="filter__form">
            <div class="filter__icon"><i class="fas fa-chevron-down"></i></div>
            <select class="form-control" id="year" name="year" onchange="this.form.submit()">
                                    <option value="2023"  selected>2566</option>
                                    <option value="2022" >2565</option>
                            </select>
        </div>
    </form>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row row-cols-lg-3 row-cols-md-2 row-cols-1 g-5 mb-5">
                
                    <div class="col">
                        <a href="//ir.listedcompany.com/tracker.pl?type=6&amp;amp;id=2423142" target="_blank" class="card card--announcement">
                            <div class="card__body">
                                <div class="card__date">27 ม.ค. 2566</div>
                                <div class="card__title">รายงานการใช้เงินเพิ่มทุนที่ได้รับจากการเสนอขายหุ้นสามัญเพิ่มทุนต่อประชาชนทั่วไปเป็นครั้งแรก (IPO)</div>
                                <div class="card__filter">
                                    <div class="btn btn--primary-white">
                                        <span class="btn__text">
                                            อ่านเพิ่มเติม
                                        </span>
                                        <span class="btn__icon">
                                            <i class="fal fa-chevron-right"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                
            </div>

            <div class="row mb-4">
                <div class="col-12">
                    

                </div>
            </div>
        </div>
    </section>

                    <section class="section section--blackdrop bg-cover" style="background-image:url('/storage/updates/press-releases/img-related.jpg');">
    <div class="section__wrapper">
        <div class="container text-center">
            <div class="row">
                <div class="col-12">
                    <h3 class="text-white mb-4">
                        ข่าวจากสื่อสิ่งพิมพ์
                    </h3>
                    <a href="https://www.betagro-investor.com/th/newsroom/news-clippings" class="btn btn--primary">
                        <span class="btn__text"> อ่านเพิ่มเติม </span>
                        <span class="btn__icon">
                            <i class="icon-arrow-right"></i>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
  </section>


        
        </main>

        <div class="sticky">
    
    <div class="sticky__item sticky__item--top" >
        <a class="sticky__link" href="javscript:;" id="scrollTop">


            <div class="sticky__icon ">
                <i class="fal fa-angle-up"></i>
            </div>


        </a>
    </div>
</div>
        <footer class="footer">
    <div class="footer__top">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <div class="footer__address">
                        <img class="footer__logo" src="https://www.betagro-investor.com/themes/default/assets/static/images/default/logo.svg"/>
                        <div class="footer__title">บริษัท เบทาโกร จํากัด (มหาชน)</div>
                        <p class="footer__text">
                           เบทาโกรทาวเวอร์ (นอร์ธปาร์ค)<br>323 ถนนวิภาวดีรังสิต แขวงทุ่งสองห้อง<br>เขตหลักสี่ กรุงเทพฯ 10210
                        </p>
                        <a href="https://www.betagro.com/th/corporate-governance/whistleblowing" target="_blank" class="btn btn--whistleblowing">
                            <span class="btn__icon"><i class="icon-whistleblowing"></i></span>
                            <span class="btn__text">ช่องทางการแจ้งร้องเรียน</span>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="footer__block mb-3 mb-lg-0">
    <div class="footer__title">แผนผังเว็บไซต์</div>

    <nav class="nav nav--footer ">
                    <li class="nav-item ">
                <a class="nav-link" href="https://www.betagro.com/th/about-us/betagro-at-a-glance" target="_self">
                    เกี่ยวกับเรา
                </a>
            </li>
                    <li class="nav-item ">
                <a class="nav-link" href="https://www.betagro.com/th/our-business/business-overview" target="_self">
                    ธุรกิจของเรา
                </a>
            </li>
                    <li class="nav-item ">
                <a class="nav-link" href="https://www.betagro.com/th/corporate-governance/corporate-policy" target="_self">
                    การกำกับดูแลกิจการ
                </a>
            </li>
                    <li class="nav-item ">
                <a class="nav-link" href="https://www.betagro.com/th/sustainability/sustainability-at-a-glance" target="_self">
                    ความยั่งยืน
                </a>
            </li>
                    <li class="nav-item ">
                <a class="nav-link" href="https://www.betagro-investor.com/th/home" target="_self">
                    นักลงทุนสัมพันธ์
                </a>
            </li>
                    <li class="nav-item ">
                <a class="nav-link" href="https://www.betagro.com/th/updates/news-release" target="_self">
                    ข่าวและกิจกรรม
                </a>
            </li>
                    <li class="nav-item ">
                <a class="nav-link" href="https://careers.betagro.com/" target="_blank">
                    ร่วมงานกับเรา
                </a>
            </li>
                    <li class="nav-item ">
                <a class="nav-link" href="https://www.betagro.com/th/contact-us" target="_self">
                    ติดต่อเรา
                </a>
            </li>
            </nav>
</div>

                    <div class="footer__block pb-0 mb-4 mb-lg-0">

    <div class="footer__title">Downloads</div>

    <div class="row">
        <div class="col-sm-8">
            <nav class="nav nav--footer-download">
                
                <li class="nav-item">
                    <a class="nav-link" href="https://www.betagro.com/th/downloads/corporate" target="_self" >
                      <div class="nav-icon">
                        <i class="icon-download"></i>
                      </div>
                      ข้อมูลเกี่ยวกับองค์กร
                    </a>
                </li>

                
                <li class="nav-item">
                    <a class="nav-link" href="https://www.betagro-investor.com/th/downloads/download-center" target="_self" >
                      <div class="nav-icon">
                        <i class="icon-download"></i>
                      </div>
                      สำหรับนักลงทุน
                    </a>
                </li>

                
            </nav>
        </div>
    </div>
</div>


                </div>
                <div class="col-lg-3">

                    <div class="footer__block footer__block--related">
                        <div class="footer__title">ลิงก์ที่เกี่ยวข้อง</div>
                        <nav class="nav nav--footer-related mb-2">
        <li class="nav-item">
        <a class="nav-link" href="https://www.betagro-agro.com/" target="_blank" >
          กลุ่มธุรกิจเกษตร
        </a>
    </li>
        <li class="nav-item">
        <a class="nav-link" href="https://www.betagro-food.com/" target="_blank" >
          กลุ่มธุรกิจอาหารและโปรตีน
        </a>
    </li>
        <li class="nav-item">
        <a class="nav-link" href="https://www.betagro-pet.com" target="_blank" >
          กลุ่มธุรกิจสัตว์เลี้ยง
        </a>
    </li>
    

</nav>
                        <div class="footer__social mb-3">
            <a class="footer__icon" href="https://www.facebook.com/BetagroGroupOfficial" target="_blank">
            <span class="icon-social-fb"><span class="path1"></span><span class="path2"></span></span>
        </a>
            <a class="footer__icon" href="https://www.youtube.com/channel/UCagW4zzCJjygyqbc251S9Aw" target="_blank">
            <span class="icon-social-youtube"><span class="path1"></span><span class="path2"></span></span>
        </a>
            <a class="footer__icon" href="https://th.linkedin.com/company/betagro-group" target="_blank">
            <span class="icon-social-linkedin"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
        </a>
            <a class="footer__icon" href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#99;&#111;&#110;&#116;&#97;&#99;&#116;&#99;&#101;&#110;&#116;&#101;&#114;&#64;&#98;&#101;&#116;&#97;&#103;&#114;&#111;&#46;&#99;&#111;&#109;" target="_self">
            <div class="nav-icon"><i class="icon-mail"></i></div>
        </a>
    </div>


                        <ul class="list list--icon">
                            <li class="list__item">
                                <span class="list__icon"><i class="icon-call"></i></span>
                                <span class="list__text">+66 2833 8000</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer__bottom">
        <div class="container">
            <div class="row align-items-center h-100">
                <div class="col-lg">
                    <div class="footer__text">© สงวนลิขสิทธิ์ พ.ศ. 2565 บริษัท เบทาโกร จํากัด (มหาชน)</div>
                </div>
                <div class="col-lg-auto">
                    <nav class="nav nav--footer-sitemap">
        <li class="nav-item">
        <a class="nav-link" href="https://www.betagro-investor.com/th/sitemap" target="_self" >
          แผนผังเว็บไซต์
        </a>
    </li>
        <li class="nav-item">
        <a class="nav-link" href="https://www.betagro.com/th/cookie-policy" target="_self" >
          นโยบายคุกกี้
        </a>
    </li>
        <li class="nav-item">
        <a class="nav-link" href="https://www.betagro.com/th/terms-conditions" target="_self" >
          ข้อตกลงและเงื่อนไข
        </a>
    </li>
        <li class="nav-item">
        <a class="nav-link" href="https://www.betagro.com/th/privacy-statement" target="_self" >
          ข้อชี้แจงสิทธิ์ส่วนบุคคล
        </a>
    </li>
    </nav>

                </div>
            </div>
        </div>
    </div>
</footer>

    </div>


    <script>
    var cookieSetting = {
        'accept': "1d",
        'reject': "1d",
        'cookie-performance': "1"
    }
</script>



<div id="cookie">

    
		
        

        
    
</div>

    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js" integrity="sha512-bLT0Qm9VnAYZDflyKcBaQ2gg0hSYNQrJ8RilYldYQ1FxQYoCLtUjuuRuZo+fjqhx/qtq/1itJ0C2ejDxltZVFg==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>

<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/2.9.2/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossorigin="anonymous"></script>


<script src="/themes/default/assets/vendor.08240b42c9340a686be4.js"></script>
<script src="/themes/default/assets/theme.2bbc940958d9982709b9.js"></script>
<script src="/themes/default/assets/cookie.7391143c57249ed1eef3.js"></script>
<script src="/themes/default/assets/app.4e8bedaeef0cba15268a.js"></script>



    
    
        

    




</body>
</html>
