<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
    link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
    <title>Document</title>
</head>

<body class="ht">

  <div #back></div>
  <div id="bottom" (click)="scrollToElement(back)" >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
   </div>

   <app-menubar></app-menubar>
   <div class="ma">


      <section id="sushi-header" style="background: url('http://mittraphapservice.dyndns.info:8081/api/v1/files/corona-5174671_960_720.jpg'); background-repeat: no-repeat; background-size: 100% 100%;" >
        <div class="Iam">
          <p style="color: #fff;">แบบสอบถาม เพื่อความปลอดภัย</p><br>
          <b style="color: #fff;">ด้วยความห่วงใยจาก &nbsp;</b>
          <b style="color: red;">
            <div class="innerIam">
              เครือบริษัท<br />
              มิตรภาพโภคภัณฑ์<br />
              จำกัด (มหาชน)<br />
            </div>
          </b>
        </div>    
        </section>

  <div [style.display]="role2 ? 'block' : 'none'">

    <div class="modal-dialog" style="max-width: 100%;" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true" >
      <div class="modal-dialog modal-dialog-scrollable" style="max-width: 100%;" role="document" >
        <div class="modal-content" >
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> ตารางแสดงรายการบุคลากรที่กรอกแบบสอบถามเพื่อเข้าทำงาน </h2></h5>
          </div>

          <div class="modal-body">
            
            <div *ngIf="role2;then content"></div>    
            <ng-template #content>
              <div class ="row">
              <div class="col col-md-12">
                <table class="table table-striped table-bordered">
                  <thead class="thead-light">
                    <tr class="text-center">
                      <th scope="col">#</th>
                      <th scope="col">ชื่อ-นามสกุล</th>
                      <th scope="col">เบอร์โทร</th>
                      <th scope="col">ที่อยู่</th>
                      <th scope="col">กลุ่มบุคลากร</th>
                      <th scope="col">กลุ่มอื่นๆ</th>
                      <th scope="col">สังกัด</th>
                      <th scope="col">หมายเหตุ</th>
                      <th scope="col">เวลาเข้า</th>
                      <th scope="col">คำถาม 1</th>
                      <th scope="col">คำถาม 2</th>
                      <th scope="col">คำถาม 3</th>
                      <th scope="col">คำถาม 4</th>
                      <th scope="col">คำถาม 5</th>
                      <th scope="col">คำถาม 6.1</th>
                      <th scope="col">คำถาม 6.2</th>
                      <th scope="col">คำถาม 6.3</th>
                      <th scope="col">คำถาม 6.4</th>
                      <th scope="col">คำถาม 6.5</th>
                      <th scope="col">คำถาม 6.6</th>
                      <th scope="col">สรุปผล</th>
                      <th scope="col" > Actions </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let contact of contacts">
                      <th scope="row">{{ contact.id }}</th>
                      <td>{{ contact.firstName }} {{ contact.lastName }}</td>
                      <td>{{ contact.tel }}</td>
                      <td>{{ contact.addr }}</td>
                      <td>{{ contact.groupPeple }}</td>
                      <td>{{ contact.groupPepleDetail }}</td>
                      <td>{{ contact.agency }}</td>
                      <td>{{ contact.note }}</td>
                      <td>{{ contact.createDate }}</td>
                      <td>{{ contact.q1yn }}</td>
                      <td>{{ contact.q2yn }}</td>
                      <td>{{ contact.q3yn }}</td>
                      <td>{{ contact.q4yn }}</td>
                      <td>{{ contact.q5yn }}</td>
                      <td>{{ contact.q61yn }}</td>
                      <td>{{ contact.q62yn }}</td>
                      <td>{{ contact.q63yn }}</td>
                      <td>{{ contact.q64yn }}</td>
                      <td>{{ contact.q65yn }}</td>
                      <td>{{ contact.q66yn }} {{processResult(contact)}}</td>
                      <td style="background-color:{{bgColor}}">{{result}} </td>
                      <td>
                        <button (click)="updateContact(contact.id)" class="btn btn-info"> Update</button>
                        <button (click)="deleteContact(contact.id)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                      </td>
                      
                    </tr>
                    <div>{{clearResult()}}</div>
                  </tbody>
                </table>
              </div>
              </div>
            </ng-template>


           
          </div>
         
        </div>
      </div>
    </div>


    

  </div>

  <div [style.display]="role2 ? 'none' : 'block'">
    <div class="container text-center">
      <div class ="row">
        <div class="col col-md-12" style="background-color: {{bgColor}}">
         <h1 class="showmessage">{{result}}</h1> 
        </div>
      </div>
    <!-- <div class ="row">
      <div class="col col-md-12">
     <table class="table table-striped table-bordered">
       <thead class="thead-light">
         <tr class="text-center">
           <th scope="col">#</th>
           <th scope="col">ชื่อ-นามสกุล</th>
           <th scope="col">เบอร์โทร</th>
           <th scope="col">สรุปผล</th>
           <th scope="col" >  </th>
         </tr>
       </thead>
       <tbody>
         <tr *ngFor="let contact of contacts2">
           <th scope="row">{{ contact.id }}</th>
           <td scope="row">{{ contact.firstName }} {{ contact.lastName }}</td>
           <td scope="row">{{ contact.tel }} {{processResult(contact)}}</td>
           <td scope="row" style="background-color:{{bgColor}}">{{result}}</td>
           <td>
             <button (click)="updateContact(contact.id)" class="btn btn-info"> แก้ไข</button>
             <button (click)="deleteContact(contact.id)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
           </td>
           
         </tr>
     
       </tbody>
     </table>
   </div>
   </div> -->
  </div>
 </div>

<br>
  <div class="container text-center">
    <button type="button" (click)="addContact(2)" class="btn btn-primary" style="margin-left: 10px">กรอกแบบสอบถาม</button>
  </div>
<br>

</div>



<!-- Creates the bootstrap modal where the image will appear -->
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <form (ngSubmit)="onSubmit(eventsFlag)">
    <div class="modal-dialog modal-xl" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
        <div class="modal-content">
          
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
            <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
          </div>
      
          <!-- <div class="alert alert-primary alert-dismissible fade show">
            <strong>{{message}} {{txtValue}}</strong> 
          </div> -->
          <div class="modal-body">

            <label for="name"> ชื่อ :</label>
            <input type="text" class="form-control" id="firstName" placeholder="กรุณาระบุ ชื่อ" [(ngModel)]="contact.firstName" name="firstName">

            <label for="name"> นามสกุล :</label>
            <input type="text" class="form-control" id="lastName" placeholder="กรุณาระบุ นามสกุล" [(ngModel)]="contact.lastName" name="lastName">

            <label for="name"> เบอร์โทร :</label>
            <input type="text" class="form-control" id="tel" placeholder="กรุณาระบุ เบอร์ติดต่อ" [(ngModel)]="contact.tel" name="tel" >

            <label for="name"> ที่อยู่ :</label>
            <textarea class="form-control" rows="2" id="addr" placeholder="กรุณาระบุ ที่อยู่" [(ngModel)]="contact.addr" name="addr"></textarea>

            <label for="groupPeple"> กลุ่มบุคลากร :</label>
            <select class="form-control" name="groupPeple" id="groupPeple" [(ngModel)]="contact.groupPeple">
              <option value="0"></option>
              <option value="1">พนักงาน บริษัท มิตรภาพอาหารสัตว์ จำกัด</option>
              <option value="2">ผู้รับเหมาเข้ามาปฏิบัติงาน</option>
              <option value="3">บุคคลภายนอกเข้ามาติดต่องาน</option>
              <option value="4">อื่นๆ ระบุ</option>
            </select><br>
            <input type="text" class="form-control" id="groupPepleDetail" placeholder="อืนๆ ระบุ" [(ngModel)]="contact.groupPepleDetail" name="groupPepleDetail">

            <label for="name"> ต้นสังกัด :</label>
            <input type="text" class="form-control" id="agency" placeholder="กรุณาระบุ ต้นสังกัด" [(ngModel)]="contact.agency" name="agency" (ngModelChange)="onTextChange($event,'ต้นสังกัด')">

            <label for="name"> หมายเหตุ :</label>
            <input type="text" class="form-control" id="note" placeholder="" [(ngModel)]="contact.note" name="note">

            <hr>
            <tr>
              <td><label>กรุณาตอบคำถาม :</label></td>
            </tr>

            <label for="gender">1. ท่านมีประวัติเดินทางไปต่างประเทศที่มีการระบาดของโรคติดเชื้อไวรัสโคโรนา 2019 (COVID-19) ในช่วงเวลา 14 วัน ก่อนหน้านี้ ใช่หรือไม่ ?</label>
            <div class="container text-center">
              <input class="form-control" type="radio"  name="q1yn" value="Y" [(ngModel)]="contact.q1yn" > <label for="q1yn">ใช่</label>
              <input class="form-control" type="radio"  name="q1yn" value="N" [(ngModel)]="contact.q1yn" > <label for="q1yn">ไม่ใช่</label>
            </div> 
            <hr>

            <label for="gender">2. ท่านมีประวัติเดินทางไปจัดหวัดพื้นที่สีแดงเข้มที่มีการระบาดของโรคติดเชื้อไวรัสโคโรนา 2019(COVID-19) ตามประกาศกระทรวงสาธารณสุขฯในช่วงเวลา 7 วัน ก่อนหน้านี้ ใช่หรือไม่ ?</label>
            <div class="container text-center">
              <input class="form-control" id="male" type="radio" value="Y" name="q2yn" [(ngModel)]="contact.q2yn" checked> <label for="q2yn">ใช่</label>
              <input class="form-control" id="male" type="radio" value="N" name="q2yn" [(ngModel)]="contact.q2yn"> <label for="q2yn">ไม่ใช่</label>
            </div> 
            <hr>

            <label for="gender">3. ท่านสัมผัสใกล้ชิดกับบุคคลที่มาจากพื้นที่ที่มีรายงานการระบาดต่อเนื่องของโรคติดเชื้อไวรัสโคโรนา 2019(COVID-19) ใช่หรือไม่ ?</label>
            <div class="container text-center">
              <input class="form-control" id="male" type="radio" value="Y" name="q3yn" [(ngModel)]="contact.q3yn" checked> <label for="q3yn">ใช่</label>
              <input class="form-control" id="male" type="radio" value="N" name="q3yn" [(ngModel)]="contact.q3yn"> <label for="q3yn">ไม่ใช่</label>
            </div> 
            <hr>

            <label for="gender">4. ท่านมีประวัติใกล้ชิดหรือสัมผัสกับผู้ป่วยเข้าข่ายหรือยืนยันโรคติดเชื้อไวรัสโคโรนา 2019 (COVID-19) ใช่หรือไม่ ?</label>
            <div class="container text-center">
              <input class="form-control" id="male" type="radio" value="Y" name="q4yn" [(ngModel)]="contact.q4yn" checked> <label for="q4yn">ใช่</label>
              <input class="form-control" id="male" type="radio" value="N" name="q4yn" [(ngModel)]="contact.q4yn"> <label for="q4yn">ไม่ใช่</label>
            </div> 
            <hr>

            <label for="gender">5. ท่านหรือบุคคลใกล้ชิดเข้าร่วมกิจกรรมที่มีผู้ชุมนุมเกิน 100 คน ในช่วงเวลา 14 วัน ก่อนหน้านี้ ใช่หรือไม่ ?</label>
            <div class="container text-center">
              <input class="form-control" id="male" type="radio" value="Y" name="q5yn" [(ngModel)]="contact.q5yn" checked> <label for="q5yn">ใช่</label>
              <input class="form-control" id="male" type="radio" value="N" name="q5yn" [(ngModel)]="contact.q5yn"> <label for="q5yn">ไม่ใช่</label>
            </div> 
            <hr>


            <label for="name">6. ท่านมีอาการดังต่อไปนี้หรือไม่ ?</label><br>
            <div class="container text-center">
              <input class="form-control" id="male" type="checkbox" checked={{contact.q61yn}} name="q61yn"  [(ngModel)]="contact.q61yn">
              <label for="q61yn"> มีไข้ (37.5 °C ขึ้นไป)</label><hr width="70%">
            
              <input class="form-control" id="male" type="checkbox" checked={{contact.q62yn}} name="q62yn"  [(ngModel)]="contact.q62yn">
              <label for="q61yn"> ไอ / เจ็บคอ</label><hr width="70%">
            
              <input class="form-control" id="male" type="checkbox" checked={{contact.q63yn}} name="q63yn"  [(ngModel)]="contact.q63yn">
              <label for="q61yn"> มีน้ำมูก</label><hr width="70%">
            
              <input class="form-control" id="male" type="checkbox" checked={{contact.q64yn}} name="q64yn"  [(ngModel)]="contact.q64yn">
              <label for="q61yn"> หายใจลำบาก/ไม่สะดวก</label><hr width="70%">
           
              <input class="form-control" id="male" type="checkbox" checked={{contact.q65yn}} name="q65yn"  [(ngModel)]="contact.q65yn">
              <label for="q61yn"> จมูกไม่ได้กลิ่น</label><hr width="70%">

              <input class="form-control" id="male" type="checkbox" checked={{contact.q66yn}} name="q66yn"  [(ngModel)]="contact.q66yn">
              <label for="q61yn"> ไม่มีอาการข้างต้น</label><hr width="70%">

              <h4>*** ขอบคุณค่ะ ***</h4>
            </div>

            <!-- <tr><td>{{txtValue}}</td><td>{{message}}</td></tr> -->

            <!-- <div class="alert alert-success">
              <strong>Success!</strong> {{message}}
            </div> -->

            
           
          </div>
          <div class="modal-footer">
            <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
            <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
          </div>
       
        </div>
      </div>
    </div>
   </form>
  </div>
    <!-- end  Creates the bootstrap modal where the image will appear -->


</body>
<app-footerbar></app-footerbar>

</html>