<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name=viewport content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>

<body class="ht">

  <div #back></div>
  <div id="bottom" (click)="scrollToElement(back)" >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
   </div>


   <div class="bg_2_1">
    <div class="bg_1_2">
      <div class ="bg_0_3">
    <!-- <div>{{getDataForBlog(611)}}</div> -->
    <!-- <div *ngFor="let goods of goodss"> -->
      <!-- <section id="sushi-header" style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
      </section> -->
      
      <div class="btn-animated margin_head">
        <section id="sushi-header" >
          <div class="Iam">
            <p style="color: #fff;">ติดต่อเราได้ที่</p>
            <b style="color: red;">
              <div class="innerIam">
                เครือบริษัท<br/>
                มิตรภาพโภคภัณฑ์<br/>
                จำกัด (มหาชน)<br/>
              </div>
            </b>
          </div>    
          </section> 
      </div>




      <div class="container margin_head3">
        <div class="card-">
          <div class="row text-center">
            <div  class="col-xl-2 col-md-2 col-sm-1"> </div>

            <div  class="col-xl-4 col-md-4 col-sm-1"> 
              <div class="card3- text-center">
                <a class="nav-link " href="#home1">
                  <div class="item-zoom">
                    <img src="{{httpPath}}files/head-office.png" alt="Jim Hawkins" title="เครือบริษัทมิตรภาพโภคภัณฑ์ จำกัด (มหาชน)" class="round2 text-center">
                  </div>
                </a>
              
                <p class="f-white fs9 btn-animated">
                  สำนักงานใหญ่
                </p>
              </div>
            </div>

  
            <div  class="col-xl-4 col-md-4 col-sm-1"> 
              <div class="card3- text-center">
                <a class="nav-link " [routerLink]="['/contactus-farm-component']" routerLinkActive="router-link" href="#">
                  <div class="item-zoom">
                    <img src="{{httpPath}}files/farm-icon.png" alt="Jim Hawkins" title="บริษัท มิตรภาพโภคภัณฑ์ จำกัด (มหาชน)" class="round2 text-center">
                  </div>
                </a>
              
                <p class="f-white fs9 btn-animated">
                  ฟาร์มสุกร
                </p>
              </div>
            </div>

            <!-- <div  class="col-xl-3 col-md-3 col-sm-1"> 
              <div class="card3- text-center">
                <a class="nav-link " [routerLink]="['/contactus-feedmills-component']" routerLinkActive="router-link" href="#">
                  <div class="item-zoom">
                    <img src="{{httpPath}}files/factory-icon.png" alt="Jim Hawkins" title="บริษัท มิตรภาพอาหารสัตว์ จำกัด" class="round2 text-center">
                  </div>
                </a>
              
                <p class="f-white fs9 btn-animated">
                  โรงงานอาหารสัตว์
                </p>
              </div>
            </div> -->

            <div  class="col-xl-2 col-md-2 col-sm-1"> </div>
          </div>
        </div>
      </div>

    </div>


 
    
  <div id="home1" class="id-top"></div> 

  <div class="bg_4_3 margin_head2 btn-animated" >
    <div class="container">
      <div class="row">
          <div class="col-md-8 col-sm-8 btn-animated-">
          <div class="container text-left" id="myHeader-">
            <h2 class="f-white text-left">
              <i class="fa fa-quote-left" aria-hidden="true" ></i> 
                เครือบริษัท มิตรภาพโภคภัณฑ์ จำกัด (มหาชน)
              <i class="fa fa-quote-right" aria-hidden="true" ></i>
            </h2>
          </div>

          <div class="container">
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
              <div class="col-md-1"></div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 btn-animated-"></div>
      </div>
  

    </div>
  </div>

</div>
</div>


 <div *ngIf="role1">
    <table class="table table-striped">
      <thead>
        <tr>
          <th> First Name</th>
          <th> Last Name </th>
          <th> Tel</th>
          <th> Email Address</th>
          <th> Note </th>
          <th> Actions </th>
          <!-- <th> test </th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let contact of contacts">
          <td> {{ contact.firstName }} </td>
          <td> {{ contact.lastName }} </td>
          <td> {{ contact.tel }} </td>
          <td> {{ contact.emailAddr }} </td>
          <td> {{ contact.note }} </td>
          <td>
            <button (click)="updateContact(contact.id)" class="btn btn-info"> Update</button>
            <button (click)="deleteContact(contact.id)" class="btn btn-danger" style="margin-left: 10px">
              Delete</button>
            <button (click)="contactDetails(contact.id)" class="btn btn-info" style="margin-left: 10px"> View</button>
          </td>
          <!-- <td><a href="https://www.w3schools.com" (click)="setVisitCount(contact.id,contact.countVisits)">Visit W3Schools.com!</a>  {{ contact.countVisits }} </td> -->
        </tr>
      </tbody>
    </table>
  </div>


<div class="bg_1_1">
 <div class="bg_1_2">
  <div class="bg_2_3 margin_head2"></div>
  <div class="bg_3_3-">
    <br>
    <div class="container">
      <div class="row">
      
      <div class="col-md-12 card">
        <br>
        <h3 style="background-color: #358821">ที่ตั้งสำนักงานใหญ่</h3><br>
        <!-- <h3><font color="#ooooFF"> MITRAPHAP GROUP</font></h3> -->
        <h4 class="f-green fs4">MITTRAPHAP PRODUCT COMPANY LIMITED (HEAD OFFICE)</h4>
        <h6 class="f-green fs6">บริษัท มิตรภาพโภคภัณฑ์ จำกัด (มหาชน)(สำนักงานใหญ่)</h6>
        <!-- <p class="font-weight-bold" >สำนักงานใหญ่ :</p> -->
        <p class="f-gray fs12">11 ซ.รามคำแหง 81/1 ถ.รามคำแหง แขวงหัวหมาก เขตบางกะปิ กทม. 10240</p>
        <p class="f-gray fs12 font-weight-bold">เบอร์โทรศัพท์ : 02-7322448-57  แฟกซ์ : 02-3779246</p>
        <!-- <p class="font-weight-bold">แฟกซ์ : 02-3779246</p> -->
        

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d244.41139151690817!2d100.63416547183186!3d13.763135321719963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d61ee36449a71%3A0x7403575dd80f0e32!2z4Lih4Li04LiV4Lij4Lig4Liy4Lie4Lit4Liy4Lir4Liy4Lij4Liq4Lix4LiV4Lin4LmM!5e1!3m2!1sth!2sth!4v1646031213573!5m2!1sth!2sth" 
          class="map" width="510" height="350" style="border:0;" allowfullscreen="" loading="lazy">
        </iframe>
      
        <br>
      </div>

    </div>
  </div>
  </div>


  <div id="link3" class="id-top"></div> 
  <div class="bg_3_3-">
    <br>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 card">
            <br>
            <h3 style="background-color: #358821;">แบบฟอร์มติดต่อ</h3>
            <form (ngSubmit)="onSubmit()">
              <div class="form-group"><br>
                <label class="F6 fs6" for="firstName"> ชื่อ :</label>
                <input type="text" class="form-control" id="firstName" placeholder="Please enter your name"
                  [(ngModel)]="contact.firstName" name="firstName">
                <label class="F6 fs6" for="lastName">นามสกุล :</label>
                <input type="text" class="form-control" id="lastName" placeholder="Please enter last name"
                  [(ngModel)]="contact.lastName" name="lastName">
                <label class="F6 fs6" for="Tel">เบอร์โทร :</label>
                <input type="text" class="form-control" id="tel" placeholder="Please enter your phone number"
                  [(ngModel)]="contact.tel" name="tel">
                <label class="F6 fs6" for=" e-mail">อีเมล :</label>
                <input type="text" class="form-control" id="emailAddr" placeholder="Please enter your email"
                  [(ngModel)]="contact.emailAddr" name="emailAddr">
                <label  class="F6 fs6" for="comment">แสดงความคิดเห็น :</label>
                <!-- <textarea class="form-control" rows="7" id="comment" name="text"></textarea> -->
                <textarea class="form-control" rows="7" id="note" [(ngModel)]="contact.note" name="note"></textarea>
              </div>
          <br>
              <div class="form-group text-center">
                <button type="submit" class="btn btn-success" id="submit">ตกลง</button>&nbsp;
                <!-- <button type="submit" name="ok" class="btn btn-primary">ตกลง</button> -->
                <button type="reset" name="reset" class="btn btn-danger">ยกเลิก</button>
              </div>
            </form>
            <br>
          </div>

    </div>
  </div>
  </div>


<div class="bg_5_3 margin_footer"></div>
</div>
</div>


</body>

</html>