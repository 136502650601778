<form (ngSubmit)="onSubmit(eventsFlag)">
    <div class="modal-dialog" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
            <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
          </div>
          <div class="modal-body">
  
             <tr>
              <td><label> Groups : {{BlogId}}</label></td>
              <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupId"
                  name="groupId"></td>
            </tr>
            <tr>
              <td><label> Order No : </label></td>
              <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="goods.orderNo" name="orderNo"></td>
            </tr>
            <tr>
              <td><label> GroupsRef1</label></td>
              <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef1"
                  name="groupIdRef1"></td>
            </tr>
            <tr>
              <td><label> GroupsRef2</label></td>
              <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef2"
                  name="groupIdRef2"></td>
            </tr>
            <tr>
              <td><label> GroupsRef3</label></td>
              <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef3"
                  name="groupIdRef3"></td>
            </tr>
            <tr>
              <td><label> Href1</label></td>
              <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href1" name="href1">
              </td>
            </tr>
            <tr>
              <td><label> Href2</label></td>
              <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href2" name="href2">
              </td>
            </tr>
            <tr>
              <td><label> Href3</label></td>
              <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href3" name="href3">
              </td>
            </tr>
            <tr>
              <td><label> Name</label></td>
              <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.namePoint"
                  name="namePoint"></td>
            </tr>
            <tr>
              <td><label> Path1</label></td>
              <td><input type="text" class="form-control control-size" id="imgPath1" [(ngModel)]="goods.imgPath1"
                  name="imgPath1"></td>
            </tr>
            <tr>
              <td>
                <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                    (click)="upload('imgPath1')">Upload</button></div>
              </td>
              <td>
                <div class="col-8">
                  <label class="btn btn-default p-0 control-size">
                    <input type="file" (change)="selectFile($event)" />
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td><label> Path2</label></td>
              <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.imgPath2"
                  name="imgPath2"></td>
            </tr>
            <tr>
              <td>
                <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                    (click)="upload('imgPath2')">Upload</button></div>
              </td>
              <td>
  
  
                <div class="col-8">
                  <label class="btn btn-default p-0 control-size">
                    <input type="file" (change)="selectFile($event)" />
                  </label>
                </div>
  
  
              </td>
            </tr>
            <tr>
              <td><label> Desc1</label></td>
              <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc1"
                  name="desc1"></textarea></td>
            </tr>
            <tr>
              <td><label> Desc2</label></td>
              <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc2"
                  name="desc2"></textarea></td>
            </tr>
           <tr>
              <td></td>
              <td>
                <div *ngIf="currentFile" class="progress my-3">
                  <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                    attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                    [ngStyle]="{ width: progress + '%' }">
                    {{ progress }}%
                  </div>
                </div>
              </td>
            </tr>
          </div>
          <div class="modal-footer">
            <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
            <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
          </div>
       
        </div>
      </div>
    </div>
   </form>