@@ -0,0 +1,75 @@
<!DOCTYPE html>
<html lang="en">


<head>
    <meta charset="UTF-8">
    <meta name=viewport content="width=device-width, initial-scale=1">
    <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
    <title>Document</title>
</head>
<app-menubar></app-menubar>

<body>
    <div class="container">
        <div class="row "id="id1">
            <div class="col-md-5" style="margin-top: 11vh;">
                <div id="myCarousel" class="carousel slide" data-ride="carousel" align="center">
                    <div class="carousel-inner">
                        <div class="carousel-item active"> <img src="https://www.helloshopsonline.com/media/catalog/product/b/l/blackmores-marine-q10-collagen-advance-_-60-_.jpg" class="rounded">
                        </div>
                        <div class="carousel-item"> <img src="https://th-test-11.slatic.net/p/529e2f5963fcb0814d3baff189f0d65e.jpg" class="rounded"> </div>
                        <div class="carousel-item"> <img src="https://th-test-11.slatic.net/p/5e02271a38c4c85c33af9e7d1e5d8c65.jpg" class="rounded"> </div>
                    </div>
                    <ol class="carousel-indicators list-inline">
                        <li class="list-inline-item active"> <a id="carousel-selector-0" class="selected"
                                data-slide-to="0" data-target="#myCarousel"> <img src="https://www.helloshopsonline.com/media/catalog/product/b/l/blackmores-marine-q10-collagen-advance-_-60-_.jpg"
                                    class="img-fluid rounded"> </a> </li>
                        <li class="list-inline-item"> <a id="carousel-selector-1" data-slide-to="1"
                                data-target="#myCarousel"> <img src="https://th-test-11.slatic.net/p/529e2f5963fcb0814d3baff189f0d65e.jpg"
                                    class="img-fluid rounded"> </a> </li>
                        <li class="list-inline-item"> <a id="carousel-selector-2" data-slide-to="2"
                                data-target="#myCarousel"> <img src="https://th-test-11.slatic.net/p/5e02271a38c4c85c33af9e7d1e5d8c65.jpg"
                                    class="img-fluid rounded"> </a> </li>
                    </ol>
                </div>
            </div>
            <div class="col-md-7" style="margin-top: 11vh;">
                <p id="t1">{{productname}}</p> 
                <hr style="border-top: 2px dashed #ddd8d8;">
                <div id="price">
                    <p id="p2" style="color: rgb(248, 90, 33);">{{price}}</p>
                </div><br>
                <p>การจัดส่ง:<span><img id="img"src="assets/img/free.png" width="17px"></span><span style="margin-left: 8px;color: #000;">ฟรีค่าจัดส่ง</span></p>
                <p id="Ar2">ฟรีค่าจัดส่ง เมื่อสั่งสินค้าขั้นต่ำ฿1000</p>

                <p id="Ar4">
                    <svg id="Ar3" xmlns="http://www.w3.org/2000/svg"width="20" height="20" fill="currentColor"
                         viewBox="0 0 16 16" style="color: #000;">
                        <path
                            d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                    </svg>
                    การจัดส่ง ถึง<span> <select >
                    <option >กรุงเทพมหานคร</option>
                    <option >กำแพงเพชร</option>
                    <option >กาญจนบุรี</option>
                    <option >กระบี่</option>
                  </select></span></p>
                <p id="Ar5">ค่าจัดส่งเริ่มต้น<span style="color: #000;">฿0 -
                        ฿50</span></p>
                <p style="line-height:100px;">จำนวน</p>  
              <form name=form1>
                     <input type=button value=" + " onclick="document.form1.input1.value++">
                    <input name=input1 value=" 1" size=5 onfocus="buffer=this.value" onchange="if (isNaN(this.value)) { this.value=buffer}">
                    <input type=button value=" - " onclick="document.form1.input1.value--">
                </form><br> 
        <button type="button" class="btn btn-danger">ซื้อสินค้า</button>
            </div>
        </div>
        <div class="container" style="background-color: #fff"><br>
        <h4>{{title}}</h4><br>
        <h5>หมวดหมู่:<span id="c1"style="color: #7f7d3f" >{{Category}}</span></h5>
        <h5>ยี่ห้อ:<span id="c2"style="color: #7f7d3f" >{{brand}}</span></h5>
        <h5>หมายเลขอย.:<span id="c3"style="color: #7f7d3f" >{{id}}</span></h5>
        <h5>ลักษณะ:<span id="c4"style="color: #7f7d3f" >{{look}}</span></h5>
        <h4 >{{title1}}</h4><br>
        <p>{{ details}}</p>
        <div class="list">
        <p style="color: #000;">> จุดเด่นและคุณสมบัติ < </p>
        <p>{{property}}</p>
        <p>{{property1}}</p>
        <p>{{property2}}</p><br>
        <p id="e1" style="color: #000;">> วิธีการรับประทาน < </p>
        <p>{{ item}}</p><br>
        <p style="color: #000;">> ส่วนประกอบ < </p>
        <p>{{ingredient}}</p><br>
        </div>
        </div>
        <br>
        <h3>สินค้าแนะนำ</h3><hr class="style2"><br>
     
         <div class="container">
            <div class="row">
               <div class="col-md-3 col-sm-6">
                <div class="card">
                    <img class="card-img-top" src="https://www.blackmores.co.th/media/product/thmb-1019.jpg" alt="Card image cap" >
                    <div class="card-body"><a  href="#id1" ><i>
                      <p class="card-text">Blackmores Multi Vitamins+Minerals แบลคมอร์ส วิตามินและเกลือแร่รวม</p>
                      <b>฿500</b></i> </a>
                    </div>
                  </div>
               </div>
               <div class="col-md-3 col-sm-6">
                <div class="card">
                    <img class="card-img-top" src="https://www.blackmores.co.th/media/product/thmb-1023.jpg" alt="Card image cap">
                    <div class="card-body">
                      <p class="card-text">Blackmores Bio Zinc  แบลคมอร์ส ไบโอ ซิงค์</p>
                      <b>฿500</b>
                    </div>
                    <br>
                  </div>
               </div>
               <div class="col-md-3 col-sm-6">
                <div class="card">
                    <img class="card-img-top" src="https://www.blackmores.co.th/media/product/thmb-1022.jpg" alt="Card image cap">
                    <div class="card-body">
                      <p class="card-text">Blackmores Choles-Bloc
                        แบลคมอร์ส โคเลส-บลอค (ผลิตภัณฑ์เสริมอาหาร)</p>
                        <b>฿500</b>
                    </div>
                  </div>
               </div>
               <div class="col-md-3 col-sm-6">
                <div class="card">
                    <img class="card-img-top" src="https://www.blackmores.co.th/media/product/thmb-2058.jpg" alt="Card image cap">
                    <div class="card-body">
                      <p class="card-text">Blackmores 12+ Multi
                        แบลคมอร์ส 12+ มัลติ (ผลิตภัณฑ์เสริมอาหาร)</p>
                      <b>฿500</b>
                    </div>
                  </div>
               </div>
            </div>
        </div> 
        <br>
    </div>
</body>
<app-footerbar></app-footerbar>

</html>