export class Job {
    id: number =0;
    jobCode: string ="";
    jobName: string ="";
    placeOfWorkShort: string ="";
    rate: string ="";
    jobDesc: string ="";
    qualification: string ="";
    howToApply: string ="";
    contact: string ="";
    placeOfWorkFull: string ="";
    location: string ="";
    isEnable: number =0;
    groupId: number =0;
    groupIdRef1: number =0;
    groupIdRef2: number =0;
    groupIdRef3: number =0;
    href1: string ="";
    href2: string ="";
    href3: string ="";
    orderNo: number=0;
    imgPath1: string ="";
    imgPath2: string ="";
    salary: string ="";


    isSwap: boolean =false;
    isFirst: boolean =false;
    isShowB1: boolean=false;
    isShowB2: boolean=false;
    isShowB3: boolean=false;
    isShowB4: boolean=false;

    txtSelector :string="";
    idSelector :string="";
    imgPathTemp1: string ="";
    imgPathTemp2: string ="";

    countVisits: number=0;
}