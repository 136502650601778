<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">



  <title>Document</title>

</head>


<body class="ht">
  <!-- <div class="head-menu"></div> -->

    <div #back></div>
    <div id="bottom" (click)="scrollToElement(back)" >
      <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
    </div>


     <div class="bg_2_1"> 
      <div class="bg_1_2">
      <div class="bg_0_3">

        <script type='text/javascript' src='https://www.siamecohost.com/member/gcounter/graphcount.php?page=http://localhost:4200/#/newsandevents-component&style=02&maxdigits=10'></script>
        
      <div>{{getDataForBlog(111)}}</div>
      <div *ngFor="let goods of goodss">
        <!-- <section id="sushi-header"
          style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;">
         
        </section> -->
        
        <div class="btn-animated margin_head">
          <h1 class="f-white fs1" >{{goods.namePoint}}</h1><br>
          <!-- <div class="text-center"><img class="mtp_underline" src="assets\img\underline\underline.png"> </div> -->
          <h5 class="f-white fs7" >{{goods.desc1}}</h5>
          <!-- <div class="container text-center"><img class="mtp_underline" src="assets\img\underline\underline.png"> </div> -->
        </div>
       
        <div *ngIf="role1">
          <div class="container">
            <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"
              style="margin-left: 10px"> Update</button>
            <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger"
              style="margin-left: 10px"> Delete</button>
          </div>
        </div>
      </div>
      
    </div>
<!-- 
    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="http://mittraphapservice.dyndns.info:8081/api/v1/files/IMG_6285.jpg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="http://mittraphapservice.dyndns.info:8081/api/v1/files/banner-workwithus5.jpg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
          <img src="http://mittraphapservice.dyndns.info:8081/api/v1/files/IMG_6284.JPG" class="d-block w-100" alt="...">
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div> -->


    <div class="container margin_head2">
        <div class="card-">
        <div>{{getDataForBlog(30304)}}</div>
          <div class="row text-center">
            <div  class="col-xl-1 col-md-1 col-sm-2"> </div>

              <div  class="col-xl-2 col-md-2 col-sm-2" *ngFor="let goods of goodss"> 
              <div class="text-center">
                <a href={{goods.href1}}>
                  <div class="item-zoom">
                    <img src={{goods.imgPathTemp1}} alt="" title={{goods.namePoint}} class="round2 text-center">
                  </div>
                </a>
                <div *ngIf="role1">
                  <div>
                    <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                    <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                </div>

           
             
                  <p class="f-white fs9 btn-animated">
                  {{goods.namePoint}}
                  </p>
              </div>
              
            </div>
            <div  class="col-xl-1 col-md-1 col-sm-2"> </div>

            <div *ngIf="role1">
              <div class="container text-center">
                <button type="button" (click)="addGoods(30304)" class="btn btn-primary" style="margin-left: 10px">Add new (30304)</button>
              </div>
            </div>

          </div>
        </div>
        </div><br><br><br>


<!-- <div class="blackLayer"></div> -->
<div id="home5"></div>

<div class="bg_4_3 margin_head2 btn-animated" >
  <div class="container">
    <div class="row">
        <div class="col-md-8 col-sm-8 btn-animated-">
        <div class="container text-left" id="myHeader-">
          <h1 class="f-white fs3 ">วิสัยทัศน์(Vision)</h1><br>
          <h5 class="F2 fs2 text-left">
            <i class="fa fa-quote-left" aria-hidden="true" ></i> 
            เครือมิตรภาพเติบโตต่อเนื่องอย่างมั่นคง จากฐานธุรกิจสุกร สู่ธุรกิจเชื่อมโยงเพื่อประโยชน์ร่วมกันของทุกฝ่ายด้วยคุณภาพและคุณธรรม
            <i class="fa fa-quote-right" aria-hidden="true" ></i>
          </h5>
        </div>
      </div>
      <div class="col-md-4 col-sm-4 btn-animated-"></div>
    </div>

    <!-- <div class="container">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
        <div class="col-md-1"></div>
      </div>
    </div> -->
  </div>
</div>

   
  <!-- <div class="container content-text">
    <h1 class="F3 fs3">วิสัยทัศน์(Vision)</h1>
    
    <h5 class="F2 fs11">tets</h5>
    <div><img class="mtp_underline" src="assets\img\underline\underline.png"> </div>

</div> -->
   
   <!--<br><br><br><br>
      <div class="container">
        <div id="carouselExample" class="carousel slide" data-ride="carousel" data-interval="9000">
          <div class="carousel-inner row w-10 mxauto" role="listbox">
            <div class="carousel-item col-md-4 active">
              <div class="cards-wrapper">
                <div class="card card-img">
                  <a href="#home">
                    <img src="assets/img\logo/1.jpg" class="card-img-top" alt="Title">
                    <div class="card-body">
                      <h5 class="card-title"><i>ความเป็นมา</i>
                        </h5>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div class="carousel-item col-md-4">
                <div class="cards-wrapper">
                  <div class="card card-img">
                    <a href="#home2">
                      <img src="assets/img\logo/vision.png" class="card-img-top " alt="Title">
                      <div class="card-body">
                        <h5 class="card-title"><i>พันธกิจ</i> </h5>
                      </div>
                    </a>
                </div>
              </div>
            </div>
            <div class="carousel-item col-md-4">
              <div class="cards-wrapper">
                <div class="card card-img">
                  <a href="#home3">
                    <img src="assets/img\logo/values.jpg" class="card-img-top" alt="Title">
                    <div class="card-body">
                      <h5 class="card-title"><i>ค่านิยมองค์กร</i>
                        </h5>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="carousel-item col-md-4">
              <div class="cards-wrapper">
                <div class="card card-img">
                  <a href="#home4">
                    <img src="assets/img\logo/executive.png" class="card-img-top" alt="Title">
                    <div class="card-body">
                      <h5 class="card-title"><i>ผู้บริหาร</i>
                        </h5>
                    </div>
                  </a>
                </div>
              </div>
            </div>

          </div>
          <a class="carousel-control-prev" id="carousel-control-prev" href="#carouselExample" role="button"
            data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" id="carousel-control-next" href="#carouselExample" role="button"
            data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>

        </div>
      </div> 
      <br>-->
   
  </div>
  </div>


  <div id="home" class="id-top"></div>

<div class="bg_5_1">
  <div class="bg_2_2">
    <div class="bg_2_3" >
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-8 btn-animated">
            
          <div>{{getDataForBlog(121)}}</div>
          <div *ngFor="let goods of goodss">
              <h1 class="f-white fs3 text-center">{{goods.namePoint}}</h1>
              <h5 class="f-white fs2 text-center">{{goods.desc1}}</h5>
              <div class="container">
                <div class="row">
                  <div class="col-xl-1 col-md-1"></div>
                  <div class="col-xl-10 col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
                  <div class="col-xl-1 col-md-1"></div>
                </div>
              </div>
  
          <div *ngIf="role1">
            <div>
              <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
              <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-4 btn-animated-"></div>
      </div>
    </div>
  </div>
      
        
        <div class="container" id="myHeader-">
              <!-- <div>{{getDataForBlog(13)}}</div>
              <div *ngFor="let goods of goodss">
                <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>    
                <ng-template #content>
                  <div class="row" >
                    <div id="first" class="col-md-6">
                      <img src={{goods.imgPath1}} class="card-img-1" style="width: 100%; height: 100%;" alt="Title5" id="block1">
                    </div>
                    <div id="second"  class="col-md-6">
                      <div class="content-text">
                        <b class="F5" id="block">{{goods.namePoint}}</b>
                        <p class="F6" id="block2">{{goods.desc1}}</p>
                      </div>
                    </div>
                </div>
                </ng-template>
    
                <ng-template #other_content>
                  <div class="row">
                    <div id="second" class="col-md-6"> 
                      <div class="content-text">
                        <b class="F5" id="block">{{goods.namePoint}}</b>
                        <p class="F6" id="block2">{{goods.desc1}}</p>
                    </div>
                      
                    </div>
                    <div id="first" class="col-md-6">
                      <img src={{goods.imgPath1}} class="card-img-1"id="block1" alt="Title5" height="90%">
                    </div>
                </div>
                </ng-template>
    
                <div *ngIf="role1">
                  <div>
                    <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                    <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPath1,goods.imgPath2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                </div>
                <br>
    
            </div> -->


            <div>{{getDataForBlog(13)}}</div>
            <div *ngFor="let goods of goodss">
              <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>    
              <ng-template #content>
                <div class="row bg3" >
                  <div id="first" class="col-md-6">
                    <div class="card2 text-center" >
                      <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
                    </div><br>
                  </div>
                  <div id="second"  class="col-md-6">
                    <div class="content-text">
                      <b class="f-green fs5" id="block">{{goods.namePoint}}</b>
                      <p class="f-gray fs6" id="block2">{{goods.desc1}}</p>

                      
                    <div *ngIf="goods.isNotShowAddition;then canshow else notshow">here is ignored</div>    
                    <ng-template #canshow>
                      <!-- <button class="btn btn-primary" type="button" data-bs-toggle="collapse" [attr.data-target]="#i1" aria-expanded="false" aria-controls="collapseExample">
                        Button with data-bs-target
                      </button> -->
                      <!-- <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                        Button with data-bs-target
                      </button> -->
                      <!-- <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="'#i' + goods.id" aria-expanded="true" aria-controls="collapseExample">
                        Button with data-bs-target
                      </button> -->

                      <button type="button" class="btn btn-info-" data-bs-toggle="collapse" [attr.data-bs-target]="'#i' + goods.id" >>>></button>
                      <div id="i{{goods.id}}" class="collapse">
                        <p class="f-gray fs6" id="block2">{{goods.desc2}}</p>
                      </div>
                    </ng-template>
                    <ng-template #notshow>
                       
                    </ng-template>

                    </div>
                  </div>
                </div>
              </ng-template>
  
              <ng-template #other_content>
                <div class="row bg4">
                  <div id="second" class="col-md-6"> 
                    <div class="content-text">
                      <b class="f-green fs5" id="block">{{goods.namePoint}}</b>
                      <p class="f-gray fs6" id="block2">{{goods.desc1}}</p>

                      <div *ngIf="goods.isNotShowAddition;then canshow2 else notshow2">here is ignored</div>    
                      <ng-template #canshow2>
                        <button type="button" class="btn btn-info-" data-bs-toggle="collapse" [attr.data-bs-target]="'#i' + goods.id" >>>></button>
                        <div id="i{{goods.id}}" class="collapse">
                          <p class="f-gray fs6" id="block2">{{goods.desc2}}</p>
                        </div>
                      </ng-template>
                      <ng-template #notshow2>
                         
                      </ng-template>

                    </div>
                  </div>
                  <div id="first" class="col-md-6">
                    <div class="card2 text-center" >
                      <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
                    </div><br>
                  </div>
              </div>
              </ng-template>
  
              <div *ngIf="role1">
                <div>
                  <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                  <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
              </div>
              <br>
  
          </div>
    
            <div *ngIf="role1">
              <div class="container text-center">
                <button type="button" (click)="addGoods(13)" class="btn btn-primary" style="margin-left: 10px">Add new (13)</button>
              </div>
            </div>

        </div>
        <div class="bg_5_3 margin_footer"></div>
      </div>
    </div>

    
      

        <!-- <div class="bg2">
          <div id="home1"></div>
          <br><br><br><br><br><br><br><br><br>

          <div class="container ">

            <main id="main">
              <section class="intro">
                <div>{{getDataForBlog(122)}}</div>
                <div *ngFor="let goods of goodss">
                  <h3 id="title">
                    <span id="" class="F1">{{goods.namePoint}}</span>
                  </h3>
                  <h4 class="text-center F2">{{goods.desc1}}</h4>

                  <div *ngIf="role1">
                    <div>
                      <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                      <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger"
                        style="margin-left: 10px"> Delete</button>
                    </div>
                  </div>
                </div>
              </section>
            </main>
            <br>
            <div class="row">
              <div>{{getDataForBlog(14)}}</div>
              <div class="col-md-12 " style="opacity: 1;">

                <h4>
                  <ul *ngFor="let goods of goodss">
                    <li class="list-policy">{{goods.desc1}}</li>

                    <div *ngIf="role1">
                      <div class="container text-center">
                        <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info">
                          Update</button>
                        <button type="button"
                          (click)="deleteGoods(goods.id,goods.groupId,goods.imgPath1,goods.imgPath2)"
                          class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                      </div>
                    </div>
                    <br>
                  </ul>
                </h4>

                <div *ngIf="role1">
                  <div class="container text-center">
                    <button type="button" (click)="addGoods(14)" class="btn btn-primary" style="margin-left: 10px">Add
                      new (14)</button>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div> -->


        <!-- ห้ามลบ
          <div class="bg2"> 
          <div id="home1"></div>
          <br><br><br><br><br><br><br><br><br>

          <main id="main">
            <section class="intro">
              <h3 id="title">
                <span id="">
                  นโยบายการดำเนินธุรกิจ
                </span>
              </h3>
            </section>
          </main>
          <br>

          <div class="container">
            <div class="row">
              <div>{{getDataForBlog(14)}}</div>
              <div class="col-md-4 " style="opacity: 1;" *ngFor="let goods of goodss">
                <div class="content-box text-center">
                  <img alt="Real-time information" title="Real-time information" class="finbyz-icon img-responsive"
                    src={{goods.imgPath1}}>
                  <br>
                  <br>
                  <h4 class="content-box-title">{{goods.desc1}}</h4>
                  <hr width="30%" color="red">
                  <p class="content-box-sub">
                    {{goods.desc2}}</p>
                </div>

                <div *ngIf="role1">
                  <div class="container text-center">
                    <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                    <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPath1,goods.imgPath2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                </div>
                <br>
              </div>

              <div *ngIf="role1">
                <div class="container text-center">
                  <button type="button" (click)="addGoods(14)" class="btn btn-primary" style="margin-left: 10px">Add new (14)</button>
                </div>
              </div>

            </div>
          </div>

          <br>
          <br>
          <br>
          <div class="text-center">
            <a href="#back"><i class="fa fa-home" style="font-size:50px;color:#3b9122"></i></a>
          </div>
          <br>

        </div> -->

        <!-- <div id="home2" class="id-top"></div>
        <div class="container">
          <div class="" >
            <section id="pricing">
              <div class="product" id="Large">
                <div class="level">
                  <main id="main">
                    <section class="intro">
                      <div>{{getDataForBlog(123)}}</div>
                      <div *ngFor="let goods of goodss">
                        <h3 id="title">
                          <span id="" class="F1-2">{{goods.namePoint}}</span>
                        </h3>
                        <h4 class="text-center F2-2">{{goods.desc1}}</h4>
                        
                        <div *ngIf="role1">
                          <div>
                            <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                            <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger"
                              style="margin-left: 10px"> Delete</button>
                          </div>
                        </div>
                      </div>
                    </section>
                  </main>
                </div>

                <div>{{getDataForBlog(152)}}</div>
                <div class="col-md-12" style="opacity: 1;">
                  <h4>
                    <ol *ngFor="let goods of goodss">
                      <li class="F6-4 text-left">{{goods.desc1}}</li>

                      <div *ngIf="role1">
                        <div class="container text-center">
                          <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info">Update</button>
                          <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPath1,goods.imgPath2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                        </div>
                      </div>
                      <br>
                    </ol>

                    <div *ngIf="role1">
                      <div class="container text-center">
                        <button type="button" (click)="addGoods(152)" class="btn btn-primary"
                          style="margin-left: 10px">Add new (152)</button>
                      </div>
                    </div>
                  </h4>
                </div>

              </div>
            </section>
          </div>
        </div> -->

        <div id="home2" class="id-top"></div>
        <div class="bg_3_1">
         <div class="bg_2_2">
          <div class="bg_2_3 " >
            <div class="container">
              <div class="row">
                <div class="col-md-8 col-sm-8 btn-animated">
                  
                <div>{{getDataForBlog(123)}}</div>
                <div *ngFor="let goods of goodss">
                    <h1 class="f-white fs3 text-center">{{goods.namePoint}}</h1>
                    <h5 class="f-white fs2 text-center">{{goods.desc1}}</h5>
                    <div class="container">
                      <div class="row">
                        <div class="col-xl-1 col-md-1"></div>
                        <div class="col-xl-10 col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
                        <div class="col-xl-1 col-md-1"></div>
                      </div>
                    </div>
        
                <div *ngIf="role1">
                  <div>
                    <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                    <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 btn-animated-"></div>
            </div>
          </div>
        </div>
          

          <div class="container" >
            <div class="row text-center">
              <div class="col-xl-3 col-md-3 col-sm-6 mb-5">
              </div>

              <div>{{getDataForBlog(152)}}</div>
              <div class="col-xl-6 col-md-6 col-sm-6 mb-5" *ngFor="let goods of goodss">
                <div class="bg-white rounded shadow-sm py-5 px-4">
                  <img src={{goods.imgPathTemp1}}  width="200"
                    class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm round4">
                  <br>
                  <!-- <h5 class="F5">{{goods.desc1}}</h5>
                  <br>
                  <span class="F6">{{goods.desc2}}</span> -->

                  <div class="content-text">
                    <b class="f-green fs5" id="block">{{goods.desc1}}</b>
                    <p class="f-gray fs6" id="block2">{{goods.desc2}}</p>
                  </div>
                </div>

                <div *ngIf="role1">
                  <div class="container">
                    <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info">Update</button>
                    <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                </div>

              </div> 
              <div *ngIf="role1">
                <div class="container text-center">
                  <button type="button" (click)="addGoods(171)" class="btn btn-primary" style="margin-left: 10px">Addnew (152)</button>
                </div>
              </div>
           
              <!-- <div>{{getDataForBlog(152)}}</div>
              <div class="col-md-12" style="opacity: 1;">
                <h4>
                  <ol *ngFor="let goods of goodss">
                    <li class="F6-4 text-left">{{goods.desc1}}</li>

                    <div *ngIf="role1">
                      <div class="container text-center">
                        <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info">Update</button>
                        <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPath1,goods.imgPath2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                      </div>
                    </div>
                    <br>
                  </ol>

                  <div *ngIf="role1">
                    <div class="container text-center">
                      <button type="button" (click)="addGoods(152)" class="btn btn-primary"
                        style="margin-left: 10px">Add new (152)</button>
                    </div>
                  </div>
                </h4>
              </div> -->

       


              <div class="col-xl-3 col-md-3 col-sm-6 mb-5">

              </div>
            </div>
          </div>
          <div class="bg_5_3 margin_footer"></div>
      </div>
    </div>



    <div id="home3" class="id-top"></div>
    <div class="bg_4_1">
      <div class="bg_2_2">
        <div class="bg_2_3 " >
          <div class="container">
            <div class="row">
              <div class="col-md-8 col-sm-8 btn-animated">
                
              <div>{{getDataForBlog(124)}}</div>
              <div *ngFor="let goods of goodss">
                  <h1 class="f-white fs3 text-center">{{goods.namePoint}}</h1>
                  <h5 class="f-white fs2 text-center">{{goods.desc1}}</h5>
                  <div class="container">
                    <div class="row">
                      <div class="col-xl-1 col-md-1"></div>
                      <div class="col-xl-10 col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
                      <div class="col-xl-1 col-md-1"></div>
                    </div>
                  </div>
      
              <div *ngIf="role1">
                <div>
                  <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                  <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 btn-animated-"></div>
          </div>
        </div>
      </div>

        <div class="container card2">
            <section id="about" class="about">
              <div>{{getDataForBlog(16)}}</div>
              <ul class="grid">
                <li   *ngFor="let goods of goodss"> 
                    <img  src={{goods.imgPathTemp1}}  class="round zoom2- card-img-" width="200px" height="200px">
                  <br>
                  <br>
                  <h4 class="f-green fs5">{{goods.namePoint}}</h4>
                  <h6 class="f-gray fs6">{{goods.desc1}}</h6>
      
                  <div *ngIf="role1">
                    <div>
                      <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                      <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                    </div>
                  </div>
                  <br>
                </li>
      
                <div *ngIf="role1">
                  <div class="container text-center">
                    <button type="button" (click)="addGoods(21)" class="btn btn-primary" style="margin-left: 10px">Add new (21)</button>
                  </div>
                </div>
              </ul>
            </section>
          </div>
          <div class="bg_5_3 margin_footer"></div>
      </div>
      </div>

  <!-- <div class="container">
      <h3 class="text-center">อาหารเสริมเพื่อสุขภาพ</h3>
      <hr width="100%" color="red">
      <br>
      <h5 class="text-center">"เราคัดสรรแต่สารสกัดและวัตถุดิบคุณภาพจากทั่วโลก เพื่อผลิตภัณฑ์อาหารเสริมที่มีคุณภาพ"
      </h5>
      <br>
      <br>
    <div>{{getDataForBlog(23)}}</div>
      <main class="article-content" >
        <div *ngFor="let goods of goodss">
        <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>   
        <ng-template #content>
        <div class="page page1">
          <div class="header">
            <br>
            <img
              src={{goods.imgPath1}}
              alt="" width="100%" height="350px" />
            <br>
            <br>
            <h4 style="color: rgb(21, 7, 212);">{{goods.desc1}}</h4>
            <h5>
              {{goods.desc2}}
            </h5>
            <hr width="100%" color="red">
          </div>
        </div>
      </ng-template>
      
      <ng-template #other_content>
        <div class="page page2">
          <div class="header">
            <h4 style="color: rgb(21, 7, 212);">{{goods.desc1}}</h4>
            <h5>{{goods.desc2}}</h5>
            <img
              src={{goods.imgPath1}}
              alt="" width="100%" height="350px" />
          </div>
          
        </div>
        <hr width="100%" color="red">
      </ng-template>

      <div *ngIf="role1">
        <div>
          <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
          <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPath1,goods.imgPath2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
        </div>
      </div>
      <br>
      </div>
      <div *ngIf="role1">
        <div class="container text-center">
          <button type="button" (click)="addGoods(23)" class="btn btn-primary" style="margin-left: 10px">Add new (23)</button>
        </div>
      </div>
      
      </main>
    </div> -->


          <!-- <div class="container" id="myHeader-">
            <div class="bg3-">
            <section id="pricing2">
              <div class="product2" id="Large">
                <div class="level">
                  <main id="main">
                    <section class="intro">
                      <div>{{getDataForBlog(124)}}</div>
                      <div *ngFor="let goods of goodss">
                        <h3 id="title">
                          <span id="" class="F3 fs3">{{goods.namePoint}}</span>
                        </h3>
                        <h4 class="text-center F4 fs4">{{goods.desc1}}</h4>
                        
                        <div *ngIf="role1">
                          <div>
                            <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                            <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger"
                              style="margin-left: 10px"> Delete</button>
                          </div>
                        </div>
                      </div>
                    </section>
                  </main>
                </div>
                
                <div>{{getDataForBlog(16)}}</div>
                <div style="width:100%"  *ngFor="let goods of goodss">
                  <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>
                  <ng-template #content>
                    <span id="" class="F3 fs3">{{goods.desc1}}</span>
                     <marquee class="marq F-marq F3 fs3" direction="left" Scrolldelay=100  >{{goods.desc1}}</marquee> 
                  </ng-template>
                  <ng-template #other_content>
                    <span id="" class="F3 fs3">{{goods.desc1}}</span>
                     <marquee class="marq F-marq F3 fs3" direction="right" Scrolldelay=100  >{{goods.desc1}}</marquee> 
                  </ng-template>
                  
                  
                  <div *ngIf="role1">
                    <br>
                    <div class="container text-center">
                      <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info">Update</button>
                      <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                    </div>
                    <br>
                  </div>
                  
                </div>

                <div *ngIf="role1">
                  <div class="container text-center">
                    <button type="button" (click)="addGoods(16)" class="btn btn-primary"
                      style="margin-left: 10px">Add new (16)</button>
                  </div>
                </div> -->


                <!-- <div>{{getDataForBlog(16)}}</div>
                <div class="col-md-12" style="opacity: 1;">
                  <h4><br>
                    <ol *ngFor="let goods of goodss">
                      <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>
                      <ng-template #content>
                        <li class="list-values text-left">{{goods.desc1}}</li>
                      </ng-template>

                      <ng-template #other_content>
                        <li class="list-values text-right">{{goods.desc1}}</li>
                      </ng-template>

                      <div *ngIf="role1">
                        <div class="container text-center">
                          <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info">
                            Update</button>
                          <button type="button"
                            (click)="deleteGoods(goods.id,goods.groupId,goods.imgPath1,goods.imgPath2)"
                            class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                        </div>
                      </div>
                      <br>
                    </ol>

                    <div *ngIf="role1">
                      <div class="container text-center">
                        <button type="button" (click)="addGoods(16)" class="btn btn-primary"
                          style="margin-left: 10px">Add new (16)</button>
                      </div>
                    </div>
                  </h4>
                </div> -->
              <!-- </div>
            </section>
          </div>

        </div> -->
    

        <!-- <div class="bg4">
    
          <div id="home3"></div>
          <br><br><br><br><br><br><br><br><br>
          <main id="main">
            <section class="intro">
              <h3 id="title">
                <span id="home3">
                  ค่านิยมองค์กร
                </span>
              </h3>
            </section>
          </main>

          <br>
          <div class="text-center">
            <h4>เพื่อรวมพลังความคิดและความเชื่อของพนักงานทุกคนให้เป็นไปในทิศทางเดียวกัน TFG มีค่านิยมองค์กร คือ</h4>
          </div>
          <br>
          <div class="container text-center">
          <div class="main">
            <div class="row">
              <div>{{getDataForBlog(16)}}</div>
              <div class="col-md-4 col-sm-6" *ngFor="let goods of goodss">
                <div class="block">
                  <div class="pic">
                    <img src={{goods.imgPath1}} alt="RV">
                  </div>
                </div>
                <div class="text-center">
                  <h4>{{goods.desc1}}</h4>
                  <hr width="20%" color="red">
                  <p>{{goods.desc2}}</p>
                </div>
                <br>
                <div *ngIf="role1">
                  <div class="container text-center">
                    <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                    <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPath1,goods.imgPath2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                  <br>
                </div>
              </div>

              <div *ngIf="role1">
                <div class="container text-center">
                  <button type="button" (click)="addGoods(16)" class="btn btn-primary" style="margin-left: 10px">Add new (16)</button>
                </div>
              </div>
            </div>
          </div>
        </div>
          <br>
          
          <div class="text-center">
            <a href="#back"><i class="fa fa-home" style="font-size:50px;color:#3b9122"></i></a>
          </div>
          <br>
          <br>

        </div> -->
 
    <div id="home4" class="id-top"></div> 
    <div class="bg_3_1">
      <div class="bg_2_2">
        <div class="bg_2_3 " >
          <div class="container">
            <div class="row">
              <div class="col-md-8 col-sm-8 btn-animated">
                
              <div>{{getDataForBlog(125)}}</div>
              <div *ngFor="let goods of goodss">
                  <h1 class="f-white fs3 text-center">{{goods.namePoint}}</h1>
                  <h5 class="f-white fs2 text-center">{{goods.desc1}}</h5>
                  <div class="container">
                    <div class="row">
                      <div class="col-xl-1 col-md-1"></div>
                      <div class="col-xl-10 col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
                      <div class="col-xl-1 col-md-1"></div>
                    </div>
                  </div>
      
              <div *ngIf="role1">
                <div>
                  <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                  <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 btn-animated-"></div>
          </div>
        </div>
      </div>

        <div class="container card2"><br>
        <div class="container">
            <div class="row">
            

              <div>{{getDataForBlog(171)}}</div>
              <div class="col-xl-12" *ngFor="let goods of goodss">
                <!-- rounded shadow-sm py-5 px-4 -->
                <div class="bg-white">
                  <img src={{goods.imgPathTemp1}} alt="" width="350"
                    class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm">
                  <br>
                  <h5 class="f-green fs5 fw">{{goods.namePoint}}</h5>
                  <br>
                  <span class="f-gray fs8 fw">{{goods.desc1}}</span>
                  <br><br>
                </div>
               

                <div *ngIf="role1">
                  <div class="container">
                    <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info">Update</button>
                    <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                </div>

              </div>

              <div *ngIf="role1">
                <div class="container text-center">
                  <button type="button" (click)="addGoods(171)" class="btn btn-primary" style="margin-left: 10px">Addnew (171)</button>
                </div>
              </div>
            
            </div>
          </div>

         
            <span class="f-gray fs10 ">
              <p style="text-indent: 2.5em;">ผมเป็นผู้ก่อตั้ง บริษัท มิตรภาพโภคภัณฑ์ จำกัด (มหาชน) ตั้งแต่ปี 2521 มีความตั้งใจแน่วแน่ที่จะทำธุรกิจอย่างมีคุณธรรม 
              คิดถึงผลประโยชน์ร่วมกันระหว่างผู้ขายและผู้บริโภค โดยบริษัทจะขายสินค้าที่มีคุณภาพดี, ราคายุติธรรม และให้การบริการกับลูกค้าเป็นอย่างดี 
              ผลประโยชน์หรือกำไรจากการทำการค้าจะต้องมีการปันส่วนระหว่างผู้ถือหุ้น, ผู้บริหาร, พนักงาน และคู่ค้าอย่างยุติธรรม ผู้ที่จะเข้ามาบริหารงานในบริษัทจะต้องเป็นผู้นำที่เที่ยงตรง,
              มีคุณธรรม,คำนึงถึงความถูกต้อง และใช้เหตุผลที่สมเหตุสมผลเท่านั้น ผู้บริหารของบริษัทฯ ต้องดูแลพนักงานด้วยการจัดสวัสดิการที่เหมาะสม 
              และมีการให้ความรู้และเพิ่มความสามารถอย่างเป็นขั้นเป็นตอนด้วยการฝึกอบรมอย่างต่อเนื่อง บริษัทฯ มีนโยบายการขยายกิจการอย่างต่อเนื่องด้วยความรอบคอบ 
              เพื่อให้พนักงานได้รับโอกาสการเลื่อนตำแหน่ง และมีรายได้ที่เหมาะสมในอนาคต  ผมจะพัฒนาผู้บริหารทุกระดับให้เป็นผู้บริหารอย่างมืออาชีพ และเปิดโอกาสให้ทุก ๆ 
              ฝ่ายมีโอกาสได้ออกความคิดเห็น และรับการพิจารณาเพื่อกลั่นกรองไปใช้อย่างเหมาะสม เพื่อให้ผลงานที่เกิดขึ้นมีประสิทธิภาพและมีประสิทธิผล 
              ผู้บริหารและพนักงานทำงานร่วมกันในบรรยากาศที่เป็นพี่น้องกัน พนักงานส่วนใหญ่จะทำงานอยู่กับองค์กรของเราชั่วชีวิต และจะมีการชดเชยเมื่อครบอายุเกษียณไม่ต่ำกว่าที่กฎหมายกำหนด</p>
              <p style="text-indent: 2.5em;">พนักงานของบริษัทฯ ได้รับมอบหมายให้เป็นผู้ดูแลคู่ค้าเป็นอย่างดี โดยจัดหาสินค้าที่มีคุณภาพดีมอบให้ ตลอดจนดูแลการจัดการ และให้ความรู้ที่ถูกต้องสม่ำเสมอแก่ผู้มีอุปการะคุณ 
              และที่ไม่ยิ่งหย่อนอีกอย่างก็คือ การคืนกำไรให้กับสังคม เช่น การบริจาคให้กับหน่วยงานต่าง ๆ ได้แก่ วัด, โรงพยายาล, โรงเรียน และให้ทุนการศึกษาแก่ลูกหลานของพนักงานทุก ๆ ปี 
              ผมมีความมั่นใจว่าบริษัทมิตรภาพฯ จะมั่นคงและเติบโตไปอย่างต่อเนื่องเป็นบริษัทที่มีคุณค่าต่อผู้บริโภค, คู่ค้า, พนักงาน, ผู้ถือหุ้น และสังคม</p>
              <p style="text-indent: 2.5em;">ขอขอบคุณทุก ๆ คน ทุก ๆ หน่วยงานที่มีพระคุณช่วยเหลือกันมาตั้งแต่ต้นจนถึงปัจจุบันและต่อไปในอนาคต สุดท้ายนี้ ขอให้คำมั่นว่าผมจะบริหารโดยให้โอกาสคนดีเป็นผู้นำเท่านั้น 
              เพื่อให้องค์กรของเรามีแต่เรื่องดี ๆ ที่มีคุณธรรม ความถูกต้อง และความมีเหตุมีผล และบริษัทมิตรภาพจะพยายามช่วยรักษาดูแลสิ่งแวดล้อมให้สุดความสามารถ
            </p>
            </span>
          </div>
        
            <!-- <div class="bg_3_3" style="padding-top: 70px;padding-bottom: 50px">
              <div class="container"><img class="mtp_underline" src="assets\img\underline\underline.png"> </div>
            </div> -->
        


          <!-- <hr width="50%" color="white" style="height: 1px;"> -->

          <!-- <div class="container">
            
            <div class="row text-center">
              <div>{{getDataForBlog(172)}}</div>
              <div class="col-xl-3 col-md-3 col-sm-6 mb-5">

              </div>
              <div class="col-xl-6 col-md-6 col-sm-6 mb-5" *ngFor="let goods of goodss">
                <div class="bg-white rounded shadow-sm py-5 px-4">
                  <img src={{goods.imgPathTemp1}} alt="" width="200"
                    class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm">
                  <br>
                  <h4 class="F9 fs9">{{goods.desc1}}</h4>
                  <br>
                  <span class="F10 fs10">{{goods.desc2}}</span>
                </div>

                <div *ngIf="role1">
                  <div class="container">
                    <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info">Update</button>
                    <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                </div>

              </div>
              <div *ngIf="role1">
                <div class="container text-center">
                  <button type="button" (click)="addGoods(172)" class="btn btn-primary" style="margin-left: 10px">Addnew (172)</button>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-6 mb-5">

              </div>
            </div>
          </div> -->
          <div class="bg_5_3 margin_footer"></div>
        </div>
      </div>


  <!-- Creates the bootstrap modal where the image will appear -->
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <form (ngSubmit)="onSubmit(eventsFlag)">
  <div class="modal-dialog" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
          <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
        </div>
        <div class="modal-body">
          <tr>
            <td><label> Id </label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.id" name="id" disabled></td>
          </tr>

          <tr>
            <td><label> Groups : {{BlogId}}</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupId" name="groupId"></td>
          </tr>
          <tr>
            <td><label> Order No : </label></td>
            <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="goods.orderNo" name="orderNo"></td>
          </tr>
          <tr>
            <td><label> GroupsRef1</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef1"
                name="groupIdRef1"></td>
          </tr>
          <tr>
            <td><label> GroupsRef2</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef2"
                name="groupIdRef2"></td>
          </tr>
          <tr>
            <td><label> GroupsRef3</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef3"
                name="groupIdRef3"></td>
          </tr>
          <tr>
            <td><label> Href1</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href1" name="href1">
            </td>
          </tr>
          <tr>
            <td><label> Href2</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href2" name="href2">
            </td>
          </tr>
          <tr>
            <td><label> Href3</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href3" name="href3">
            </td>
          </tr>
          <tr>
            <td><label> Name</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.namePoint"
                name="namePoint"></td>
          </tr>
          <tr>
            <td><label> Path1</label></td>
            <td><input type="text" class="form-control control-size" id="imgPath1" [(ngModel)]="goods.imgPath1"
                name="imgPath1"></td>
          </tr>
          <tr>
            <td>
              <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                  (click)="upload('imgPath1')">Upload</button></div>
            </td>
            <td>
              <div class="col-8">
                <label class="btn btn-default p-0 control-size">
                  <input type="file" (change)="selectFile($event)" />
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td><label> Path2</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.imgPath2"
                name="imgPath2"></td>
          </tr>
          <tr>
            <td>
              <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                  (click)="upload('imgPath2')">Upload</button></div>
            </td>
            <td>


              <div class="col-8">
                <label class="btn btn-default p-0 control-size">
                  <input type="file" (change)="selectFile($event)" />
                </label>
              </div>


            </td>
          </tr>
          <tr>
            <td><label> Desc1</label></td>
            <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc1"
                name="desc1"></textarea></td>
          </tr>
          <tr>
            <td><label> Desc2</label></td>
            <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc2"
                name="desc2"></textarea></td>
          </tr>
          <tr>
            <td></td>
            <td>
              <div *ngIf="currentFile" class="progress my-3">
                <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                  attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                  [ngStyle]="{ width: progress + '%' }">
                  {{ progress }}%
                </div>
              </div>
            </td>
          </tr>
        </div>
        <div class="modal-footer">
          <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
          <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
        </div>
     
      </div>
    </div>
  </div>
 </form>
</div>
  <!-- end  Creates the bootstrap modal where the image will appear -->


</body>

</html>