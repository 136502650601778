<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>

<body class="ht">

   <div #back></div>
   <div id="bottom" (click)="scrollToElement(back)" >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
    </div>

    <app-menubar></app-menubar>

    <div class="ma">
      <div class="bg">
      <div>{{getDataForBlog(221)}}</div>
      <div *ngFor="let goods of goodss">
        <section id="sushi-header" style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
        </section>
        
        <br><br>
        <div class="btn-animated">
          <h1 class="F1 fs1" >{{goods.namePoint}}</h1><br><br>
          <h5 class="F2 fs2" >{{goods.desc1}}</h5>
        </div>

          <div [style.display]="role1 ? 'block' : 'none'">
              <div class="container">
                <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
            </div>
      </div>
      <br><br>

      <div class="container">

      <!-- <section id="about" class="about-"> -->
      
          <div class="content-text">
          <div>{{getDataForBlog(211)}}</div>
          <div *ngFor="let goods of goodss">
          <h1 class="F3 fs3">{{goods.namePoint}}</h1>
          <hr class="hr1">
          <h5 class="F4 fs4 AL1">{{goods.desc1}}</h5>

          <div [style.display]="role1 ? 'block' : 'none'">
            <div>
              <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
              <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
          </div>
        </div>
      </div>
      <br>
      <!-- </section> -->


        <div>{{getDataForBlog(24)}}</div>
        <div *ngFor="let goods of goodss">
          <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>    
          <ng-template #content>
            

            <div class="row bg3" >
              <div id="first" class="col-md-6">
                <div class="card2 text-center" >
                  <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
                </div><br>
              </div>
              <div id="second"  class="col-md-6">
                <div class="content-text">
                  <b class="F5 fs5" id="block">{{goods.namePoint}}</b>
                  <p class="F6 fs6" id="block2">{{goods.desc1}}</p>

                  <button type="button" class="btn btn-info" data-toggle="collapse" [attr.data-target]="'#i' + goods.id">เพิ่มเติม...</button>
                  <div id="i{{goods.id}}" class="collapse">
                    <p class="F6 fs6" id="block2">{{goods.desc2}}</p>
                  </div>


                </div>
              </div>
          </div>

       
          </ng-template>

          <ng-template #other_content>
            <div class="row bg4">
              <div id="second" class="col-md-6"> 
                <div class="content-text">
                  <b class="F5 fs5" id="block">{{goods.namePoint}}</b>
                  <p class="F6 fs6" id="block2">{{goods.desc1}}</p>

                  <button type="button" class="btn btn-info" data-toggle="collapse" [attr.data-target]="'#i' + goods.id">เพิ่มเติม...</button>
                  <div id="i{{goods.id}}" class="collapse">
                    <p class="F6 fs6" id="block2">{{goods.desc2}}</p>
                  </div>
                </div>
              </div>
              <div id="first" class="col-md-6">
                <div class="card2 text-center" >
                  <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
                </div><br>
              </div>
          </div>
          </ng-template>

          <div [style.display]="role1 ? 'block' : 'none'">
            <div>
              <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
              <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
          </div>
          <br>

      </div>

          <!-- <div *ngFor="let goods of goodss">
            <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>    
            <ng-template #content>
              <div class="row" >
                <div id="first" class="col-md-6">
                  <img src={{goods.imgPath1}} class="card-img-1" alt="Title5" id="block1">
                </div>
                <div id="second"  class="col-md-6">
                  <div class="content-text">
                    <b class="F5" id="block">{{goods.namePoint}}</b>
                    <p class="F6-2" id="block2">{{goods.desc1}}</p>
                  </div>
                </div>
            </div>
            </ng-template>

            <ng-template #other_content>
              <div class="row">
                <div id="second" class="col-md-6"> 
                  <div class="content-text">
                    <b class="F5" id="block">{{goods.namePoint}}</b>
                    <p class="F6-2" id="block2">{{goods.desc1}}</p>
                </div>
                  
                </div>
                <div id="first" class="col-md-6">
                  <img src={{goods.imgPath1}} class="card-img-1"id="block1" alt="Title5" height="90%">
                </div>
            </div>
            </ng-template>

            <div [style.display]="role1 ? 'block' : 'none'">
              <div>
                <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPath1,goods.imgPath2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
            </div>
            <br>

        </div> -->

        <div [style.display]="role1 ? 'block' : 'none'">
          <div class="container text-center">
            <button type="button" (click)="addGoods(24)" class="btn btn-primary" style="margin-left: 10px">Add new (24)</button>
          </div>
        </div>
     </div>


    <!-- <div class="container">
      <h3 class="text-center F1 fs1">สุกรแปรรูป</h3>
      <hr width="30%" color="red">
      <h5 class="text-center F2 fs2">"มีการแปรรูปเนื้อสุกร"</h5>
    </div> -->
    <div class="container">
    <div class="content-text">
      <div>{{getDataForBlog(213)}}</div>
      <div *ngFor="let goods of goodss">
      <h1 class="F3 fs3">{{goods.namePoint}}</h1>
      <hr class="hr1">
      <h5 class="F4 fs4 AL1">{{goods.desc1}}</h5>

      <div [style.display]="role1 ? 'block' : 'none'">
        <div>
          <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
          <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
        </div>
      </div>
    </div>
  </div>
</div>
  <br>
    

    <div>{{getDataForBlog(22)}}</div>
    <ul class="cards">
      <li *ngFor="let goods of goodss">
        <a href="" class="card">
          <img src={{goods.imgPathTemp1}}
            class="card__image" alt="" />
          <div class="card__overlay">
            <div class="card__header">
              <svg class="card__arc" xmlns="http://www.w3.org/2000/svg">
                <path />
              </svg>
              <img class="card__thumb" src={{goods.imgPathTemp2}} alt="" />
              <div class="card__header-text">
                <h3 class="card__title">{{goods.desc1}}</h3>
              </div>
            </div>
            <p class="card__description">{{goods.desc2}}</p>
          </div>
        </a>
        <div [style.display]="role1 ? 'block' : 'none'">
          <div>
            <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
            <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPath1,goods.imgPath2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
          </div>
        </div>
        <br>
      </li>

      <div [style.display]="role1 ? 'block' : 'none'">
        <div class="container text-center">
          <button type="button" (click)="addGoods(22)" class="btn btn-primary" style="margin-left: 10px">Add new (22)</button>
        </div>
      </div>
    </ul>
  
    

     <!-- <div class="container">
      <h3 class="text-center">สุกรแปรรูป</h3>
      <hr width="30%" color="red">
      <h5 class="text-center">"มีการแปรรูปเนื้อสุกร"</h5>
    </div>
    <br>
    <br>
    <div>{{getDataForBlog(22)}}</div>
    <ul class="cards">
      <li *ngFor="let goods of goodss">
        <a href="" class="card">
          <img src={{goods.imgPath1}}
            class="card__image" alt="" />
          <div class="card__overlay">
            <div class="card__header">
              <svg class="card__arc" xmlns="http://www.w3.org/2000/svg">
                <path />
              </svg>
              <img class="card__thumb" src={{goods.imgPath2}} alt="" />
              <div class="card__header-text">
                <h3 class="card__title">{{goods.desc1}}</h3>
              </div>
            </div>
            <p class="card__description">{{goods.desc2}}</p>
          </div>
        </a>
        <div [style.display]="role1 ? 'block' : 'none'">
          <div>
            <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
            <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPath1,goods.imgPath2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
          </div>
        </div>
        <br>
      </li>

      <div [style.display]="role1 ? 'block' : 'none'">
        <div class="container text-center">
          <button type="button" (click)="addGoods(22)" class="btn btn-primary" style="margin-left: 10px">Add new (22)</button>
        </div>
      </div>
    </ul> -->

    <br>
    <hr>

    <div class="container">
    <section id="about" class="about">
      <table class="text-left">
        <div>{{getDataForBlog(212)}}</div>
          <div *ngFor="let goods of goodss">
          <h1 class="F3 fs3">{{goods.namePoint}}</h1>
          <hr class="hr1">
          <h5 class="F4 fs4">{{goods.desc1}}</h5>

        <div [style.display]="role1 ? 'block' : 'none'">
          <div>
            <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
            <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
          </div>
        </div>
      </div>
      </table>
      <br>
      <br>
      
        <div>{{getDataForBlog(21)}}</div>
        <ul class="grid">
          <li  *ngFor="let goods of goodss"> 
            <a class="nav-link" [routerLink]="['/productandservices-component',goods.href1]"  routerLinkActive="router-link">
              <img src={{goods.imgPathTemp1}} alt="Jim Hawkins" title="Jim Hawkins" class="round zoom2" width="200px" height="200px">
            </a>
            <br>
            <br>
            <h4 class="F7">{{goods.namePoint}}</h4>
            <h6 class="F8">{{goods.desc1}}</h6>

            <div [style.display]="role1 ? 'block' : 'none'">
              <div>
                <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
            </div>
            <br>
          </li>

          <div [style.display]="role1 ? 'block' : 'none'">
            <div class="container text-center">
              <button type="button" (click)="addGoods(21)" class="btn btn-primary" style="margin-left: 10px">Add new (21)</button>
            </div>
          </div>
        </ul>
      </section>
    </div>


     

      <!-- <div class="container">
        <h3 class="text-center">อาหารเสริมเพื่อสุขภาพ</h3>
        <hr width="40%" color="red">
        <br>
        <h5 class="text-center">"เราคัดสรรแต่สารสกัดและวัตถุดิบคุณภาพจากทั่วโลก เพื่อผลิตภัณฑ์อาหารเสริมที่มีคุณภาพ"
        </h5>
        <br>
        <br>
      
        <div>{{getDataForBlog(23)}}</div>
         
          <div *ngFor="let goods of goodss">
            <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>    
            <main class="article-content">
            <ng-template #content>
              <div class="page page1">
                <div class="header">
                  <br>
                  <img
                    src={{goods.imgPath1}}
                    class="card-img-1 " alt="Title5" height="90%"id="block1" />
                  <br>
                  <br>
                  <h4 style="color: rgb(21, 7, 212);">{{goods.desc1}}</h4>
                  <h5>
                    {{goods.desc2}}
                  </h5>
                  <hr width="100%" color="red">
                </div>
              </div>
            </ng-template>

            <ng-template #other_content>
              <div class="page page2">
                <div class="header">
                  <h4 style="color: rgb(21, 7, 212);">{{goods.desc1}}</h4>
                  <h5>{{goods.desc2}}</h5>
                  <hr width="100%" color="red">
                  <img
                    src={{goods.imgPath1}}
                    class="card-img-1 " alt="Title5" height="90%"id="block1" />
                </div>
              </div>
            </ng-template>
          </main>

            <div [style.display]="role1 ? 'block' : 'none'">
              <div>
                <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
            </div>
            <br>
         
        </div>
      
      

        <div [style.display]="role1 ? 'block' : 'none'">
          <div class="content">
            <button type="button" class="btn btn-primary" (click)="addGoods(23)">Add new</button>
          </div>
        </div>
      
     </div> -->
     <hr>

     <div>{{getDataForBlog(222)}}</div>
     <div *ngFor="let goods of goodss">
       <section id="sushi-header" style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
       </section>
<!--        
       <br><br>
       <div class="btn-animated">
         <h1 class="F3 fs3" >{{goods.namePoint}}</h1>
         <hr class="hr1">
         <h5 class="F4 fs4" >{{goods.desc1}}</h5>
       </div>

         <div [style.display]="role1 ? 'block' : 'none'">
             <div class="container">
               <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
               <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
             </div>
           </div> -->
     </div>
     <br><br>

     <div class="container">
      <div>{{getDataForBlog(214)}}</div>
      <div *ngFor="let goods of goodss">
        <div class="btn-animated">
          <h1 class="F3 fs3" >{{goods.namePoint}}</h1>
          <hr class="hr1">
          <h5 class="F4 fs4" >{{goods.desc1}}</h5>
        </div>
  
          <div [style.display]="role1 ? 'block' : 'none'">
              <div class="container">
                <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
            </div>
      </div>
      <br> 
      <br>
    
      <div>{{getDataForBlog(23)}}</div>
        <div *ngFor="let goods of goodss">
          <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>    
          <main class="article-content">
          <ng-template #content>
            <div class="page page1">
              <div class="header">
                <br>
                <a href={{goods.href1}} target="_blank"> <img src={{goods.imgPathTemp1}} class="card-img-1 " alt="Title5" height="90%"id="block1" /></a>
                <br><br>
                <b class="F5 fs5" id="block">{{goods.namePoint}}</b>
                <p class="F6 fs6" id="block2">{{goods.desc1}}</p>

                <button type="button" class="btn btn-info" data-toggle="collapse" [attr.data-target]="'#i' + goods.id">เพิ่มเติม...</button>
                <div id="i{{goods.id}}" class="collapse">
                  <p class="F6 fs6" id="block2">{{goods.desc2}}</p>
                </div>
                <hr class="hr1">
              </div>
            </div>
          </ng-template>

          <ng-template #other_content>
            <div class="page page2">
              <div class="header">
                <br>
                <a href={{goods.href1}} target="_blank"> <img src={{goods.imgPathTemp1}} class="card-img-1 " alt="Title5" height="90%"id="block1" /></a>
                  <br><br>
                  <b class="F5 fs5" id="block">{{goods.namePoint}}</b>
                  <p class="F6 fs6" id="block2">{{goods.desc1}}</p>

                  <button type="button" class="btn btn-info" data-toggle="collapse" [attr.data-target]="'#i' + goods.id">เพิ่มเติม...</button>
                  <div id="i{{goods.id}}" class="collapse">
                    <p class="F6 fs6" id="block2">{{goods.desc2}}</p>
                  </div>
                <hr class="hr1">
              </div>
            </div>
          </ng-template>
        </main>

          <div [style.display]="role1 ? 'block' : 'none'">
            <div>
              <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
              <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
          </div>
          <br>
      </div>
  

      <div [style.display]="role1 ? 'block' : 'none'">
        <div class="content">
          <button type="button" class="btn btn-primary" (click)="addGoods(23)">Add new</button>
        </div>
      </div>
    
   </div>

   <hr><br>

   <div>{{getDataForBlog(223)}}</div>
   <div *ngFor="let goods of goodss">
     <section id="sushi-header" style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
     </section>
   </div>
   <br><br>

       <div class="container">
        <div>{{getDataForBlog(215)}}</div>
        <div *ngFor="let goods of goodss">
          <div class="btn-animated">
            <h1 class="F3 fs3" >{{goods.namePoint}}</h1>
            <hr class="hr1">
            <h5 class="F4 fs4" >{{goods.desc1}}</h5>
          </div>
    
            <div [style.display]="role1 ? 'block' : 'none'">
                <div class="container">
                  <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                  <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
              </div>
        </div>
        <br> 
        <br>
      
        <div>{{getDataForBlog(25)}}</div>
          <div *ngFor="let goods of goodss">
            <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>    
            <main class="article-content">
            <ng-template #content>
              <div class="page page1">
                <div class="header">
                  <br>
                  <a href={{goods.href1}} target="_blank"> <img src={{goods.imgPathTemp1}} class="card-img-1 " alt="Title5" height="90%"id="block1" /></a>
                  <br><br>
                  <b class="F5 fs5" id="block">{{goods.namePoint}}</b>
                  <p class="F6 fs6" id="block2">{{goods.desc1}}</p>

                  <button type="button" class="btn btn-info" data-toggle="collapse" [attr.data-target]="'#i' + goods.id">เพิ่มเติม...</button>
                  <div id="i{{goods.id}}" class="collapse">
                    <p class="F6 fs6" id="block2">{{goods.desc2}}</p>
                  </div>
                  <hr class="hr1">
                </div>
              </div>
            </ng-template>

            <ng-template #other_content>
              <div class="page page2">
                <div class="header">
                  <br>
                  <a href={{goods.href1}} target="_blank"> <img src={{goods.imgPathTemp1}} class="card-img-1 " alt="Title5" height="90%"id="block1" /></a>
                    <br><br>
                    <b class="F5 fs5" id="block">{{goods.namePoint}}</b>
                    <p class="F6 fs6" id="block2">{{goods.desc1}}</p>
  
                    <button type="button" class="btn btn-info" data-toggle="collapse" [attr.data-target]="'#i' + goods.id">เพิ่มเติม...</button>
                    <div id="i{{goods.id}}" class="collapse">
                      <p class="F6 fs6" id="block2">{{goods.desc2}}</p>
                    </div>
                  <hr class="hr1">
                </div>
              </div>
            </ng-template>
          </main>

            <div [style.display]="role1 ? 'block' : 'none'">
              <div>
                <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
            </div>
            <br>
        </div>
    

        <div [style.display]="role1 ? 'block' : 'none'">
          <div class="content">
            <button type="button" class="btn btn-primary" (click)="addGoods(25)">Add new</button>
          </div>
        </div>
      
     </div>


     <!-- <div class="container">
      <h3 class="text-center">อาหารเสริมเพื่อสุขภาพ</h3>
      <hr width="100%" color="red">
      <br>
      <h5 class="text-center">"เราคัดสรรแต่สารสกัดและวัตถุดิบคุณภาพจากทั่วโลก เพื่อผลิตภัณฑ์อาหารเสริมที่มีคุณภาพ"
      </h5>
      <br>
      <br>
    <div>{{getDataForBlog(23)}}</div>
      <main class="article-content" >
        <div *ngFor="let goods of goodss">
        <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>   
        <ng-template #content>
        <div class="page page1">
          <div class="header">
            <br>
            <img
              src={{goods.imgPath1}}
              alt="" width="100%" height="350px" />
            <br>
            <br>
            <h4 style="color: rgb(21, 7, 212);">{{goods.desc1}}</h4>
            <h5>
              {{goods.desc2}}
            </h5>
            <hr width="100%" color="red">
          </div>
        </div>
      </ng-template>
      
      <ng-template #other_content>
        <div class="page page2">
          <div class="header">
            <h4 style="color: rgb(21, 7, 212);">{{goods.desc1}}</h4>
            <h5>{{goods.desc2}}</h5>
            <img
              src={{goods.imgPath1}}
              alt="" width="100%" height="350px" />
          </div>
          
        </div>
        <hr width="100%" color="red">
      </ng-template>

      <div [style.display]="role1 ? 'block' : 'none'">
        <div>
          <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
          <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPath1,goods.imgPath2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
        </div>
      </div>
      <br>
      </div>
      <div [style.display]="role1 ? 'block' : 'none'">
        <div class="container text-center">
          <button type="button" (click)="addGoods(23)" class="btn btn-primary" style="margin-left: 10px">Add new (23)</button>
        </div>
      </div>
      
      </main>
    </div> -->

    </div>
  </div>
  
    <!-- Creates the bootstrap modal where the image will appear -->
    <div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <form (ngSubmit)="onSubmit(eventsFlag)">
      <div class="modal-dialog" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable" role="document">
          <div class="modal-content">
            
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
              <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
            </div>
            <div class="modal-body">
    
              <tr>
                <td><label> Groups : {{BlogId}}</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupId"
                    name="groupId"></td>
              </tr>
              <tr>
                <td><label> Order No : </label></td>
                <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="goods.orderNo" name="orderNo"></td>
              </tr>
              <tr>
                <td><label> GroupsRef1</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef1"
                    name="groupIdRef1"></td>
              </tr>
              <tr>
                <td><label> GroupsRef2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef2"
                    name="groupIdRef2"></td>
              </tr>
              <tr>
                <td><label> GroupsRef3</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef3"
                    name="groupIdRef3"></td>
              </tr>
              <tr>
                <td><label> Href1</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href1" name="href1">
                </td>
              </tr>
              <tr>
                <td><label> Href2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href2" name="href2">
                </td>
              </tr>
              <tr>
                <td><label> Href3</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href3" name="href3">
                </td>
              </tr>
              <tr>
                <td><label> Name</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.namePoint"
                    name="namePoint"></td>
              </tr>
              <tr>
                <td><label> Path1</label></td>
                <td><input type="text" class="form-control control-size" id="imgPath1" [(ngModel)]="goods.imgPath1"
                    name="imgPath1"></td>
              </tr>
              <tr>
                <td>
                  <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                      (click)="upload('imgPath1')">Upload</button></div>
                </td>
                <td>
                  <div class="col-8">
                    <label class="btn btn-default p-0 control-size">
                      <input type="file" (change)="selectFile($event)" />
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td><label> Path2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.imgPath2"
                    name="imgPath2"></td>
              </tr>
              <tr>
                <td>
                  <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                      (click)="upload('imgPath2')">Upload</button></div>
                </td>
                <td>
    
    
                  <div class="col-8">
                    <label class="btn btn-default p-0 control-size">
                      <input type="file" (change)="selectFile($event)" />
                    </label>
                  </div>
    
    
                </td>
              </tr>
              <tr>
                <td><label> Desc1</label></td>
                <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc1"
                    name="desc1"></textarea></td>
              </tr>
              <tr>
                <td><label> Desc2</label></td>
                <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc2"
                    name="desc2"></textarea></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div *ngIf="currentFile" class="progress my-3">
                    <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                      attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                      [ngStyle]="{ width: progress + '%' }">
                      {{ progress }}%
                    </div>
                  </div>
                </td>
              </tr>
            </div>
            <div class="modal-footer">
              <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
              <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
            </div>
         
          </div>
        </div>
      </div>
     </form>
    </div>
      <!-- end  Creates the bootstrap modal where the image will appear -->

</body>
<app-footerbar></app-footerbar>
</html>