<!doctype html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description" content="">
    <meta name="author" content="">
    <link rel="icon" href="/docs/4.0/assets/img/favicons/favicon.ico">

    <title>Signin Template for Bootstrap</title>

    <link rel="canonical" href="https://getbootstrap.com/docs/4.0/examples/sign-in/">

    <!-- Bootstrap core CSS -->
    <!-- <link href="./dist/css/bootstrap.min.css" rel="stylesheet"> -->

    <!-- Custom styles for this template -->
    <!-- <link href="signin.css" rel="stylesheet"> -->
  </head>

  <body class="text-center">
    <form class="form-signin">
      <img width="250px" height="100%" src={{logoPath}} alt="logo"><br><br><br>
      <h1 class="h3 mb-3 font-weight-normal">กรุณาป้อนข้อมูล</h1>
      <label for="inputEmail" class="sr-only">Email user</label>
      <input type="email" id="Username" class="form-control" placeholder="User name" required autofocus name="Username" [(ngModel)]="Username">
      <br>
      <label for="inputPassword" class="sr-only">Password</label>
      <input type="password" id="Password" class="form-control" placeholder="Password" required name="Password" [(ngModel)]="Password">
      <div class="checkbox mb-3">
        <!-- <label>
          <input type="checkbox" value="remember-me"> Remember me
        </label> -->
      </div>

      <button class="btn btn-lg btn-primary btn-block" type="submit" (click)="login()">เข้าระบบ</button>
    
      <!-- <div *ngIf="role2;then content else other_content"></div>    
    <ng-template #content>
      <button class="btn btn-lg btn-primary btn-block" type="submit" (click)="login()">ออกจากระบบ</button>
    </ng-template>
    <ng-template #other_content>
      <button class="btn btn-lg btn-primary btn-block" type="submit" (click)="login()">เข้าระบบ</button>
    </ng-template> -->

      
      <!-- <p class="mt-5 mb-3 text-muted">&copy; 2017-2018</p> -->
    </form>
  </body>
</html>
