import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GoodsService } from 'src/app/app.service/goods.service';
import { UploadFilesService } from 'src/app/app.service/upload-files.service';
import { GlobalConstants } from '../../common/global-constants';

@Component({
  selector: 'app-product-item1',
  templateUrl: './product-item1.component.html',
  styleUrls: ['./product-item1.component.css']
})
export class ProductItem1Component implements OnInit {
  productname:string="";
  price:string="";
  title:string="";
  title1:string="";
  Category:string="";
  brand:string="";
  id:string="";
  look:string="";
  details:string="";
  property:string="";
  property1:string="";
  property2:string="";
  item:string="";
  ingredient:string="";

  BlogId : number=0;

  constructor(private goodsService: GoodsService,
    private router: Router,private formBuilder: FormBuilder,private route: ActivatedRoute,private uploadService: UploadFilesService) {
      //  this.role1=GlobalConstants.role1;
       this.BlogId=this.route.snapshot.params['id'];
    }

  //this.route.snapshot.params['id'];
  ngOnInit(): void {
    this.setLanq(GlobalConstants.lanq);
  }
  setLanq(goodandservices:string){
    { 
      this.productname="ไอโอดีน วิตามิน Blackmores Pregnancy i folic folate โฟลิก โฟลิค 150เม็ด blackmore แบล็คมอร์ล";
      this.price="฿350";
      this.title="ข้อมูลจำเพาะของสินค้า";
      this.title1="รายละเอียดสินค้า";
      this.Category="อาหารเสริมเพิ่มน้ำหนัก";
      this.brand="Blackmores(แบล็คมอร์)";
      this.id="264926529849";
      this.look="แคปซูล";
      this.details="สารลูทีน (Lutein) ที่พบในดอกดาวเรืองจะมีหน้าที่ในการป้องกัน และชะลอโรคจอประสาทตาเสื่อม โดยเข้าไปดูแลบริเวณจุดรับภาพของดวงตา (Macula) อร์รี่มีคุณสมบัติสารต้านอนุมูลอิสระประสิทธิภาพสูง จึงสามารถปกป้องดวงตา โดยเฉพาะสารสำคัญที่ชื่อว่าแอนโธไซยาโนไซด์ (Anthocyanoside)” ซึ่งจัดเป็นสารประกอบโพลีฟีนอลในกลุ่มของฟลาโวนอยด์ (Flavonoids) และจัดเป็นสารตั้งต้นที่ทำให้เกิดสีน้ำเงินหรือม่วงในพืช โดยคุณสมบัติที่สำคัญที่สุดคือ การเป็นสารต้านอนุมูลอิสระ (Antioxidant) ที่จะช่วยในเรื่องของการปกป้องและดูแลสุขภาพดวงตา";
      this.property="- ป้องกันโรคจอประสาทตาเสื่อม จากแสงสีฟ้า ที่ได้รับจากคอมพิวเตอร์   Tablet และ Smart Phone ต่างๆ";
      this.property1="- ป้องกันดวงจากแสงแดด ทีเข้ามาทำร้ายจอประสาทตา";
      this.property2="- ต่อต้านอนุมูลอิสระ  จะได้ลดการเสื่อมสภาพและถูกทำลาย ของจอประสาทตา ";
      this.item="- รับประทานครั้งละ 1 แคปซูล วันละ 2 ครั้ง พร้อมอาหาร";
      this.ingredient="- สารสกัดจากดอกบิวเบอร์รี่ 150 mg , สารสกัดจากดอกดาวเรือง 150 mg";
    }

    
 }
}
