

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs';
import { c_user } from '../app.class/c_user';
import { map } from 'rxjs/operators';
import { GlobalConstants } from '../common/global-constants';
import { TokenStorageService } from '../_services/token-storage.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})


export class s_user {

  private baseURL = "";
  private baseVisitURL = GlobalConstants.httpPath + "goods_count";
  private person : any;
  tP : String ="user";

  constructor(private httpClient: HttpClient, private tokenStorage: TokenStorageService) { 
    this.baseURL = GlobalConstants.httpPath + this.tP;
    GlobalConstants.username = this.tokenStorage.getUser().username;
  }
  
  getDataList(mt? : c_user): Observable<c_user[]>{
    this.person= this.httpClient.get(this.baseURL).pipe(map((response: any) => response.json()));
    
    this.baseURL = GlobalConstants.httpPath + this.tP +"/allkey?id="+ mt.id + "&companyid=" + mt.companyid ;
// alert( this.baseURL);
    return this.httpClient.get<c_user[]>(`${this.baseURL}`);
  }
  
  login(username: any, password: any): Observable<any> {

    return this.httpClient.post(GlobalConstants.httpPath + this.tP + '/signin', {
      username: username,
      password: password
    }, httpOptions);
  }

  createData(data: c_user): Observable<Object>{
    data.createby=GlobalConstants.username;
    data.modifyby=GlobalConstants.username;

// alert(data.createby + "," + data.modifyby)
    this.baseURL = GlobalConstants.httpPath + this.tP + '/signup'

    return this.httpClient.post(`${this.baseURL}`, data);

  }

  updateData(id: number, data: c_user): Observable<Object>{
    data.modifyby=GlobalConstants.username;
    this.baseURL = GlobalConstants.httpPath + this.tP 
    // alert(this.baseURL + "/" + id);
    return this.httpClient.put(`${this.baseURL}/${id}`, data);
  //   return this.httpClient.put(`${this.baseURL}/${id}`, {data
  //     // username: data.username,
  //     // email: data.email,
  //     // password: data.password,
  //     // companyid: data.companyid,
  //     // usergroupid: data.usergroupid,

  //     // createby : data.createby,
  //     // modifyby: data.modifyby
  // }, httpOptions );
  }

  getDataById(id: number): Observable<c_user>{
    this.baseURL = GlobalConstants.httpPath + this.tP
    return this.httpClient.get<c_user>(`${this.baseURL}/${id}`);
  }

  updateVisits(id: number, data: c_user): Observable<Object>{
    this.baseURL = GlobalConstants.httpPath + this.tP
    return this.httpClient.put(`${this.baseVisitURL}/${id}`, data);
  }

  deleteData(id: number): Observable<Object>{
    this.baseURL = GlobalConstants.httpPath + this.tP
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }
}


