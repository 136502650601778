import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import{ GlobalConstants } from '../../common/global-constants';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { s_user } from 'src/app/app.service/s_user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})
export class MenubarComponent implements OnInit {
  companyName: string ="";
  firstPage:  string="";
  aboutUs:  string="";
  ourBusiness:  string="";
  quality : string="";
  productAndService:  string="";
  newsAndEvent:  string="";
  contactUs:  string="";
  language: string="";
  systems: string ="";
  goodsView: string="";
  workwithus:string="";

  test : string="";
  test2 : string="";
  role1 : boolean= false;
  role2 : boolean= false;
  logInOut : boolean=true;

  logoPath : string ="";
  bgPath : string ="";

  private roles: string[] = [];
  isLoggedIn: boolean = false;
  showAdminBoard: boolean = false;
  showModeratorBoard: boolean = false;
  username: string | undefined;

  constructor(private tokenStorageService: TokenStorageService,private dataService: s_user,private router: Router) { 
    // this.role1 = !!this.tokenStorageService.getToken();
    if (this.tokenStorageService.checkUser())
      {
        this.role1 = !!this.tokenStorageService.getToken();
      }
  }


  // constructor(private tokenStorageService: TokenStorageService) { }

  // ngOnInit(): void {
  //   this.isLoggedIn = !!this.tokenStorageService.getToken();

  //   if (this.isLoggedIn) {
  //     const user = this.tokenStorageService.getUser();
  //     this.roles = user.roles;

  //     this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
  //     this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');

  //     this.username = user.username;
  //   }
  // }
  login(): void {
    // window.location.reload();
  }

  logout(): void {
    this.tokenStorageService.signOut();
    this.onTokenUser();
    // this.router.navigate(['/menubar-component']);
    // alert()
    window.location.reload();
    this.router.navigate(['/menubar-component']);
    
  }



  ngOnInit(): void {
    if (this.tokenStorageService.checkUser())
    {
      this.role1 = !!this.tokenStorageService.getToken();
    }

    this.setLanq(GlobalConstants.lanq);
    this.logoPath=GlobalConstants.httpPath + "files/logo.png";


    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;

      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');

      this.username = user.username;
    }
    
  }

setLanq(ty:string){
   if (ty=="th")
   { 
     this.companyName="มิตรภาพโภคภัณฑ์";
    //  this.firstPage="หน้าหลัก";
     this.aboutUs="รู้จักกับเรา";
     this.ourBusiness="ธุรกิจในเครือ";
     this.quality="ระบบจัดการคุณภาพ";
     this.productAndService="สินค้าและบริการ";
     this.newsAndEvent="ข่าวและกิจกรรม";
     this.contactUs="ติดต่อ";
     this.language="ภาษา";
     this.systems="ระบบ";
     this.goodsView="แสดงข้อมูล";
     this.test ="ทดสอบ";
     this.test2 ="ทดสอบ2";
     if (this.role2===true){
      this.logInOut=false;
     }
     else {
      this.logInOut=true;
     }
     
     this.workwithus="ร่วมงานกับเรา";
   }
   else if (ty=="en"){
     this.companyName="Mittraphap Group";
    // this.firstPage="Main Page";
     this.aboutUs="ABOUT US";
     this.ourBusiness="OUR BUSINESS";
     this.productAndService="PRODUCTS & SERVICES";
     this.newsAndEvent="NEWS & EVENT";
     this.contactUs="CONTACT INFO";
     this.language="L";
     this.systems="Sys";
     this.goodsView="View Data";
     this.test="Test page";
     this.test2="Test2 page";
     this.workwithus="WORKWITHUS"
   }
}

  verifyLang(ty: string){

    GlobalConstants.lanq=ty;
    this.setLanq(ty);
  }


  hitAnchor1(e:any){
    console.log("Events", e);
    alert("You have clicked the anchor-1 tag");
  }
  

  
  // verifyUser(Us : boolean){
  //   GlobalConstants.role1=Us;
  //   this.role1=GlobalConstants.role1;
  //   this.role2=GlobalConstants.role2;
  // }

  // exitSystem(id?:number){

  //   this.onSubmit2();
  // }
  onTokenUser(): void {
    this.dataService.login('user','mtprun20230627').subscribe(
      data => {
        this.tokenStorageService.saveToken(data.accessToken);
        this.tokenStorageService.saveUser('user');


        if (this.tokenStorageService.checkUser())
        {
          this.role1 = !!this.tokenStorageService.getToken();
        }
      },
      err => {
      }
    );
  }
}

function dfd(dfd: any) {
  throw new Error('Function not implemented.');
}

