import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainpageComponent } from './app.components/mainpage/mainpage.component';
import { AboutusComponent } from './app.components/aboutus/aboutus.component';
import { BusinessusComponent } from './app.components/businessus/businessus.component';
import { QualityManagementComponent } from './app.components/quality-management/quality-management.component';
import { ProductAndServicesComponent } from './app.components/product-and-services/product-and-services.component';
import { ProductAndServicesDetailComponent } from './app.components/product-and-services-detail/product-and-services-detail.component';

import { NewsAndEventsComponent } from './app.components/news-and-events/news-and-events.component';
import { WorkWithUsComponent } from './app.components/work-with-us/work-with-us.component';
import { ContactusComponent } from './app.components/contactus/contactus.component';
import { TestareaComponent } from './app.components/testarea/testarea.component';
import { Testarea2Component } from './app.components/testarea2/testarea2.component';
import { EmployeeListComponent } from './app.components/employee-list/employee-list.component';
import { GoodsPopupComponent } from './app.components/goods-popup/goods-popup.component';
import { NewsComponent } from './app.components/news/news.component';
import { ProductItem1Component } from './app.components/product-item1/product-item1.component';
import { UploadFilesComponent } from './app.components/upload-files/upload-files.component';
import { WorkWithUsItemsComponent } from './app.components/work-with-us-items/work-with-us-items.component';
import { ArticleComponent } from './app.components/article/article.component';
import { ArticleItemComponent } from './app.components/article-item/article-item.component';
import { RoleComponent } from './app.components/role/role.component';
import { AddEditComponent } from './app.components/add-edit/add-edit.component';
import { QuestionnaireComponent } from './app.components/questionnaire/questionnaire.component';

import { KingdomFeedmillsComponent } from './app.components/kingdom-feedmills/kingdom-feedmills.component';
import { KingdomAnimalMedicineComponent } from './app.components/kingdom-animal-medicine/kingdom-animal-medicine.component';
import { KingdomFarmComponent } from './app.components/kingdom-farm/kingdom-farm.component';
import { KingdomSupplementaryFoodComponent } from './app.components/kingdom-supplementary-food/kingdom-supplementary-food.component';

import { RegisterComponent } from './app.components/register/register.component';
import { LoginComponent } from './app.components/login/login.component';
import { ProfileComponent } from './profile/profile.component';

import { WorkWithUsFeedmillsComponent } from './app.components/work-with-us-feedmills/work-with-us-feedmills.component';
import { WorkWithUsFarmComponent } from './app.components/work-with-us-farm/work-with-us-farm.component';
import { ContactusFeedmillsComponent } from './app.components/contactus-feedmills/contactus-feedmills.component';
import { ContactusFarmComponent } from './app.components/contactus-farm/contactus-farm.component';
import { IntroPageComponent } from './app.components/intro-page/intro-page.component';
import { MenubarComponent } from './app.components/menubar/menubar.component';


//app-add-edit  questionnaire-component
const routes: Routes = [
  // { path: '',  redirectTo: 'app-mainpage', pathMatch: 'full'},
  { path: '',  redirectTo: 'app-mainpage', pathMatch: 'full'},
  { path: 'app-mainpage', component: MainpageComponent },
  { path: 'administrator', component: RoleComponent },
  { path: 'administrator/:id', component: RoleComponent },
  { path: 'aboutus-component', component: AboutusComponent},
  { path: 'businessus-component', component: BusinessusComponent },
  { path: 'quality-management-component', component: QualityManagementComponent },
  { path: 'productandservices-component', component: ProductAndServicesComponent },
  { path: 'productandservices-component/:id', component: ProductAndServicesComponent },
  { path: 'newsandevents-component', component: NewsAndEventsComponent },
  { path: 'workwithus-component', component: WorkWithUsComponent },
  { path: 'contactus-component', component: ContactusComponent },
  { path: 'testarea-component', component: TestareaComponent },
  { path: 'testarea-component/:id', component: TestareaComponent },
  { path: 'testarea2-component', component: Testarea2Component },
  { path: 'testarea2-component/:id', component: Testarea2Component },
  { path: 'employee-list-component', component: EmployeeListComponent },
  { path: 'app-goods-popup-component', component: GoodsPopupComponent },
  { path: 'news-component',component:NewsComponent},
  { path: 'news-component/:id',component:NewsComponent},
  { path: 'news-component/:id/:name/:desc1/:desc2',component:NewsComponent},
  { path: 'product-and-services-detail-component',component:ProductAndServicesDetailComponent},
  { path: 'product-and-services-detail-component-cerent',component:ProductAndServicesDetailComponent},
  { path: 'product-and-services-detail-component/:id',component:ProductAndServicesDetailComponent},
  { path: 'product-and-services-detail-component/:id/:id2',component:ProductAndServicesDetailComponent},
  { path: 'app-upload-files',component:UploadFilesComponent},
  { path: 'workwithusitems-component',component:WorkWithUsItemsComponent},
  { path: 'workwithusitems-component/:id/:id2',component:WorkWithUsItemsComponent},
  { path: 'article-component',component:ArticleComponent},
  { path: 'article-item-component',component:ArticleItemComponent},
  { path: 'article-item-component/:id',component:ArticleItemComponent},
  { path: 'add-edit-component',component:AddEditComponent},
  { path: 'questionnaire-component',component:QuestionnaireComponent},
  { path: 'kingdom-feedmills-component',component:KingdomFeedmillsComponent},
  { path: 'kingdom-animal-medicine-component',component:KingdomAnimalMedicineComponent},
  { path: 'kingdom-farm-component',component:KingdomFarmComponent},
  { path: 'kingdom-supplementary-food-component',component:KingdomSupplementaryFoodComponent},
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'work-with-us-feedmills-component',component:WorkWithUsFeedmillsComponent},
  { path: 'work-with-us-farm-component',component:WorkWithUsFarmComponent},
  { path: 'contactus-feedmills-component',component:ContactusFeedmillsComponent},
  { path: 'contactus-farm-component',component:ContactusFarmComponent},
  { path: 'menubar-component',component:MenubarComponent},
  // { path: 'app-intro-page/:id',component:IntroPageComponent}, 
  
  
];


@NgModule({
  declarations: [
  ],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }