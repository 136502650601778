import { Component, OnInit } from '@angular/core';
import { Contact } from '../../app.class/contact';
import { ContactService } from '../../app.service/contact.service';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../common/global-constants';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})
export class ContactusComponent implements OnInit {
   contacts: Contact[] | any;
   contact: Contact = new Contact();
   role1 : boolean= false;
   httpPath: string="";
  constructor(private contactService: ContactService,
    private router: Router,private tokenStorageService: TokenStorageService) { 
      if (this.tokenStorageService.checkUser())
      {
        this.role1 = !!this.tokenStorageService.getToken();
      }
      this.httpPath=GlobalConstants.httpPath;
    }

  ngOnInit(): void {
    this.getContacts();
  }

  private getContacts(){
    this.contactService.getContactsList().subscribe(data => {
      this.contacts = data;
    });
  }

  contactDetails(id: number){
    this.router.navigate(['contact-details', id]);
  }

  updateContact(id: number){
    this.router.navigate(['update-contact', id]);
  }

  deleteContact(id: number){
    this.contactService.deleteContact(id).subscribe( data => {
      console.log(data);
      this.getContacts();
    })
  }


  saveContact(){
    this.contactService.createContact(this.contact ).subscribe( data =>{
      console.log(data);
      this.getContacts();//ย้อนกลับมาหน้าตัวเอง
      //this.goToContactList();
    },
    error => console.log(error));
  }

  goToContactList(){
    this.router.navigate(['/contactus-component']);
  }
  
  onSubmit(){
    let isOk : boolean= true;
    if (this.contact.firstName.toString()== "")
    {
      alert("กรุณาป้อนข้อมูล ชื่อ");
      isOk=false;
    }
    if (this.contact.lastName.toString()== "")
    {
      alert("กรุณาป้อนข้อมูล นามสกุล");
      isOk=false;
    }
    if (this.contact.tel.toString()== "")
    {
      alert("กรุณาป้อนข้อมูล เบอร์ติดต่อ");
      isOk=false;
    }
    if (this.contact.emailAddr.toString()== "")
    {
      alert("กรุณาป้อนข้อมูล email ที่ติดต่อกลับ");
      isOk=false;
    }
    if (this.contact.note.toString()== "")
    {
      alert("กรุณาป้อน ความเห็นที่ท่านต้องการติดต่อ");
      isOk=false;
    }

    if (isOk)
    {
      console.log(this.contact );
      this.saveContact();
      alert("เราได้ส่ง เมล์ มาหาคุณเพื่อยืนยันตัวตน กรุณาเปิดเมล์ของคุณ แล้วตอบกลับเรามาอีกครั้งค่ะ ขอบคุณค่ะ")

      document.getElementById("submit").setAttribute('disabled', 'disabled');
    }

  }



  scrollToElement($element : any): void {
    console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
   }

   setVisitCount(id : number=0,isCount : number =0)
   {
 
     this.contact.countVisits=isCount +1;
 
     this.contactService.updateVisits(id, this.contact).subscribe( data =>{
 
           
       console.log(data);

     }
     , error => console.log(error));
   }
}
