


<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>


<body class="ht">
  <div #back></div>
  <div id="bottom" (click)="scrollToElement(back)" >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
   </div>

   <app-menubar></app-menubar>

    <div class="ma">
      <div class="bg">
      <!-- <div>{{getDataForBlog(221)}}</div>
      <div *ngFor="let goods of goodss">
        <section id="sushi-header" style="background: url({{goods.imgPath1}}); background-repeat: no-repeat; background-size: cover;" >
          <div class="btn-animated" >
              <h1 class="F1">{{goods.namePoint}}</h1>
              <h5 class="F2">{{goods.desc1}}</h5>
          </div>
          </section>
          <div *ngIf="role1">
              <div class="container">
                <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
            </div>
      </div>
         -->
        <!-- <section id="sushi-header"> -->
    <!-- <div  class="container-fluid" > -->
      <!-- <section id="sushi-header"> -->


          <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" >
              <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              </ol>
              <div class="carousel-inner ">
                <div>{{getDataForBlog(511)}}</div>
                
                <div class="carousel-item active" *ngFor="let goods of goodss"> 
                  <a  href={{goods.href1}}  style="text-decoration:none;">
                    <section id="sushi-header" style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
                    
                    </section>
                    <br><br>
                    <div class="btn-animated" >
                      <h1 class="F1 fs1">{{goods.namePoint}}</h1><br>
                      <h5 class="F2 fs2">{{goods.desc1}}</h5>
                  </div>

                 </a> 
                  <br>
                  <div *ngIf="role1">
                    <div class="container">
                      <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update (511)</button>
                      <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                    </div>
                  </div>
                </div>
               
                
                <div>{{getDataForBlog(512)}}</div>
                <div class="carousel-item" *ngFor="let goods of goodss">
                 <a  href={{goods.href1}} style="text-decoration:none;">
                  <section id="sushi-header" style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
                  
                  </section>
                  <br><br>
                  <div class="btn-animated" >
                    <h1 class="F1 fs1">{{goods.namePoint}}</h1><br>
                    <h5 class="F2 fs2">{{goods.desc1}}</h5>
                </div>

                </a> 
                  <br>
                  <div *ngIf="role1">
                    <div class="container">
                      <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update (512)</button>
                      <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                    </div>
                  </div>
                </div>
              </div>
              <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
  
          
        <!-- </section> -->
      <!-- </div> -->
        <!-- </section> -->

       <!-- style="background: url({{goods.imgPath1}}); background-repeat: no-repeat; background-size: cover;background-position:center;" -->
        <!-- <div>{{getDataForBlog(501)}}</div>
        <div *ngFor="let goods of goodss">
        <div class="jumbotron jumbotron-fluid bg1" >
          <div class="container text-center " >
            <div class="btn-animated" >
              <h1 class="F3">{{goods.namePoint}}</h1>
              <h2 class="F4">{{goods.desc1}}</h2>
              <h4 class="F4">{{goods.desc2}}</h4>

              <hr width="30%" color="red">
          </div>

          <div *ngIf="role1">
            <div class="container">
              <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
              <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
          </div>

          </div>
        </div>
      </div> -->
      <div *ngIf="role1">
        <div class="container text-center">
          <button type="button" (click)="addGoods(501)" class="btn btn-primary" style="margin-left: 10px">Add new (501)</button>
        </div>
      </div>
      

   

       
 
    
        <!-- <div>{{getDataForBlog(511)}}</div>
        <div *ngFor="let goods of goodss">
          <section id="sushi-header" style="background: url({{goods.imgPath1}}); background-repeat: no-repeat; background-size: cover;" >
            <div class="btn-animated" >
                <h1 style="color: #fff;">{{goods.namePoint}}</h1>
                <h5 style="color: #fff;">{{goods.desc1}}</h5>
            </div>
            </section>
              <div *ngIf="role1">
                <div class="container">
                  <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                  <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
              </div>
        </div> -->
      
      <div id="refFarmGroup"></div>
      <div class="container" id="myHeader-">
      <section id="about" class="about">
        <table class="text-center">
          <div>{{getDataForBlog(521)}}</div>
          <div *ngFor="let goods of goodss">
          <h1 class="F3 fs3">{{goods.namePoint}}</h1>
          <hr class="hr1">
          <h3 class="F4 fs4">{{goods.desc1}}</h3>
          <div *ngIf="role1">
            <div>
              <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
              <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
          </div>
        </div>
        </table>
      </section>


        <div>{{getDataForBlog(51)}}</div>
          <div *ngFor="let goods of goodss">
            <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>    
            <ng-template #content>
              <div class="row bg3" >
                <div id="first" class="col-md-6">
                  <div class="card2 text-center" >
                    <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
                  </div>
                  <br>
                  <!-- <img src={{goods.imgPath1}} class="card-img-1" alt="Title5" id="block1"> -->
                </div>
                <div id="second"  class="col-md-6">
                  <div class="content-text">
                    <b class="F5 fs5" id="block">{{goods.namePoint}}</b>
                    <p class="F6 fs6" id="block2">{{goods.desc1}}</p>
  
                    <button type="button" class="btn btn-info" data-toggle="collapse" [attr.data-target]="'#i' + goods.id">เพิ่มเติม...</button>
                    <div id="i{{goods.id}}" class="collapse">
                      <p class="F6 fs6" id="block2">{{goods.desc2}}</p>
                    </div>
  
  
                  </div>
                </div>
            </div>
            </ng-template>

            <ng-template #other_content>
              <div class="row bg4">
                <div id="second" class="col-md-6"> 
                  <div class="content-text">
                    <b class="F5 fs5" id="block">{{goods.namePoint}}</b>
                    <p class="F6 fs6" id="block2">{{goods.desc1}}</p>
  
                    <button type="button" class="btn btn-info" data-toggle="collapse" [attr.data-target]="'#i' + goods.id">เพิ่มเติม...</button>
                    <div id="i{{goods.id}}" class="collapse">
                      <p class="F6 fs6" id="block2">{{goods.desc2}}</p>
                    </div>
  
  
                  </div>
                </div>
                <div id="first" class="col-md-6">
                  <div class="card2 text-center" >
                    <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
                  </div>
                  <br>
                  <!-- <img src={{goods.imgPath1}} class="card-img-1"id="block1" alt="Title5" height="90%"> -->
                </div>
            </div>
            </ng-template>

            <div *ngIf="role1">
              <div>
                <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
            </div>
            <br>

        </div>

        <div *ngIf="role1">
          <div class="container text-center">
            <button type="button" (click)="addGoods(51)" class="btn btn-primary" style="margin-left: 10px">Add new (51)</button>
          </div>
        </div>
     </div>

     <br>
     

     <!--<div class="container-fluid">
        <div>{{getDataForBlog(512)}}</div>
        <div *ngFor="let goods of goodss">
          <section id="sushi-header" style="background: url({{goods.imgPath1}}); background-repeat: no-repeat; background-size: cover;" >
            <div class="btn-animated" >
                <h1 style="color: #fff;">{{goods.namePoint}}</h1>
                <h5 style="color: #fff;">{{goods.desc1}}</h5>
            </div>
            </section>
            <div *ngIf="role1">
                <div class="container">
                  <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                  <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
              </div>
        </div>
     </div> -->
    <hr>

     <div class="bg2-">
      <div id="refFacGroup"></div>
        <div class="container">
            <section id="about" class="about">
                <table class="text-center">
                  <div>{{getDataForBlog(522)}}</div>
                  <div *ngFor="let goods of goodss">
                    <h1 class="F3 fs3">{{goods.namePoint}}</h1>
                    <hr class="hr1">
                    <h3 class="F4 fs4">{{goods.desc1}}</h3>
                   
        
                  <div *ngIf="role1">
                    <div>
                      <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                      <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                    </div>
                  </div>
                </div>
                </table>
              </section>

          <div>{{getDataForBlog(52)}}</div>
            <div *ngFor="let goods of goodss">
              <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>    
              <ng-template #content>
                <div class="row bg3" >
                  <div id="first" class="col-md-6">
                    <div class="card2 text-center" >
                      <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
                    </div>
                    <br>
                    <!-- <img src={{goods.imgPath1}} class="card-img-1" alt="Title5" id="block1"> -->
                  </div>
                  <div id="second"  class="col-md-6">
                    <div class="content-text">
                      <b class="F5 fs5" id="block">{{goods.namePoint}}</b>
                      <p class="F6 fs6" id="block2">{{goods.desc1}}</p>
                    </div>
                  </div>
              </div>
                <!-- <div class=" row grid-flex">
                  <div class="col col-image col-md-6">
                    <div class="card2 text-center" >
                      <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPath1}} alt="Card image cap">
                    </div>
                  </div>
                  <div class="col col-text col-md-6">
                    <div class="content-text">
                      <b class="F5-2" id="block">{{goods.namePoint}}</b>
                      <p class="F633" id="block2">{{goods.desc1}}</p>
                    </div>
                  </div>
                </div> -->
                
    
                <div *ngIf="role1">
                  <div>
                    <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                    <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                </div>
                <br>
                
              </ng-template>
    
              <ng-template #other_content>
                <div class="row bg4">
                  <div id="second" class="col-md-6"> 
                    <div class="content-text">
                      <b class="F5 fs5" id="block">{{goods.namePoint}}</b>
                      <p class="F6 fs6" id="block2">{{goods.desc1}}</p>
                    </div>
                  </div>
                  <div id="first" class="col-md-6">
                    <div class="card2 text-center" >
                      <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
                    </div><br>
                    <!-- <img src={{goods.imgPath1}} class="card-img-1"id="block1" alt="Title5" height="90%"> -->
                  </div>
              </div>
                <!-- <div class="row grid-flex">
                  <div class="col col-image col-md-6">
                    <div class="card2 text-center" >
                      <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPath1}} alt="Card image cap">
                    </div>
                  </div>
                  <div class="col col-text col-left col-md-6">
                    <div class="content-text">
                      <b class="F5-2" id="block">{{goods.namePoint}}</b>
                      <p class="F633" id="block2">{{goods.desc1}}</p>
                    </div>
                  </div>
                </div> -->
    
                <div *ngIf="role1">
                  <div>
                    <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                    <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                </div>
                <br>
              </ng-template>
    
             
    
          </div>
    
          <div *ngIf="role1">
            <div class="container text-center">
              <button type="button" (click)="addGoods(52)" class="btn btn-primary" style="margin-left: 10px">Add new (52)</button>
            </div>
          </div>
       </div>
       
      </div>


   </div>
  </div>


  
<!-- Creates the bootstrap modal where the image will appear -->
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <form (ngSubmit)="onSubmit(eventsFlag)">
  <div class="modal-dialog" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
          <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
        </div>
        <div class="modal-body">

           <tr>
            <td><label> Groups : {{BlogId}}</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupId"
                name="groupId"></td>
          </tr>
          <tr>
            <td><label> Order No : </label></td>
            <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="goods.orderNo" name="orderNo"></td>
          </tr>
          <tr>
            <td><label> GroupsRef1</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef1"
                name="groupIdRef1"></td>
          </tr>
          <tr>
            <td><label> GroupsRef2</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef2"
                name="groupIdRef2"></td>
          </tr>
          <tr>
            <td><label> GroupsRef3</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef3"
                name="groupIdRef3"></td>
          </tr>
          <tr>
            <td><label> Href1</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href1" name="href1">
            </td>
          </tr>
          <tr>
            <td><label> Href2</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href2" name="href2">
            </td>
          </tr>
          <tr>
            <td><label> Href3</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href3" name="href3">
            </td>
          </tr>
          <tr>
            <td><label> Name</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.namePoint"
                name="namePoint"></td>
          </tr>
          <tr>
            <td><label> Path1</label></td>
            <td><input type="text" class="form-control control-size" id="imgPath1" [(ngModel)]="goods.imgPath1"
                name="imgPath1"></td>
          </tr>
          <tr>
            <td>
              <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                  (click)="upload('imgPath1')">Upload</button></div>
            </td>
            <td>
              <div class="col-8">
                <label class="btn btn-default p-0 control-size">
                  <input type="file" (change)="selectFile($event)" />
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td><label> Path2</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.imgPath2"
                name="imgPath2"></td>
          </tr>
          <tr>
            <td>
              <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                  (click)="upload('imgPath2')">Upload</button></div>
            </td>
            <td>


              <div class="col-8">
                <label class="btn btn-default p-0 control-size">
                  <input type="file" (change)="selectFile($event)" />
                </label>
              </div>


            </td>
          </tr>
          <tr>
            <td><label> Desc1</label></td>
            <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc1"
                name="desc1"></textarea></td>
          </tr>
          <tr>
            <td><label> Desc2</label></td>
            <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc2"
                name="desc2"></textarea></td>
          </tr>
         <tr>
            <td></td>
            <td>
              <div *ngIf="currentFile" class="progress my-3">
                <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                  attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                  [ngStyle]="{ width: progress + '%' }">
                  {{ progress }}%
                </div>
              </div>
            </td>
          </tr>
        </div>
        <div class="modal-footer">
          <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
          <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
        </div>
     
      </div>
    </div>
  </div>
 </form>
</div>
  <!-- end  Creates the bootstrap modal where the image will appear -->

</body>
<app-footerbar></app-footerbar>


</html>