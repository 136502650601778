<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name=viewport content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>


<body class="ht">
  <div #back></div>
  <div id="bottom" (click)="scrollToElement(back)" >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
   </div>

    <div class="bg_2_1">
      <div class="bg_1_2">
        <div class ="bg_0_3">

    <div class="btn-animated margin_head">
        <section id="sushi-header" >
          <div class="Iam">
            <p style="color: #fff;">ร่วมงานกับเรา</p>
            <b style="color: red;">
              <div class="innerIam">
                บริษัท<br/>
                มิตรภาพอาหารสัตว์<br/>
                จำกัด<br/>
              </div>
            </b>
          </div>    
          </section> 
      </div>

      <div class="container margin_head3">
        <div class="card-">
          <div class="row text-center">
            <div  class="col-xl-1 col-md-1 col-sm-1"> </div>
  
            <div  class="col-xl-3 col-md-3 col-sm-1"> 
              <div class="card3- text-center">
                <a class="nav-link " href="#link1">
                  <div class="item-zoom">
                    <img src="{{httpPath}}files/welfare.png" alt="Jim Hawkins" title="สวัสดิการ" class="round2 text-center">
                  </div>
                </a>
              
                <p class="f-white fs9 btn-animated">
                  สวัสดิการ
                </p>
              </div>
            </div>

            <div  class="col-xl-4 col-md-4 col-sm-1"> 
              <div class="card3- text-center">
                <a class="nav-link " href="#link2">
                  <div class="item-zoom">
                    <img src="{{httpPath}}files/position.png" alt="Jim Hawkins" title="ตำแหน่งงาน" class="round2 text-center">
                  </div>
                </a>
              
                <p class="f-white fs9 btn-animated">
                  ตำแหน่งงาน
                </p>
              </div>
            </div>

            <div  class="col-xl-3 col-md-3 col-sm-1"> 
                <div class="card3- text-center">
                 <a class="nav-link " [routerLink]="['/contactus-feedmills-component']" routerLinkActive="router-link" href="#">
                    <div class="item-zoom">
                      <img src="{{httpPath}}files/contact-us.png" alt="Jim Hawkins" title="ติดต่อเรา" class="round2 text-center">
                    </div>
                  </a>
                
                  <p class="f-white fs9 btn-animated">
                    ติดต่อเรา
                  </p>
                </div>
              </div>

            <div  class="col-xl-1 col-md-1 col-sm-1"> </div>
          </div>
        </div>
      </div>
   </div>


      <div class="bg_4_3 margin_head2 btn-animated" >
        <div class="container">
          <div class="row">
              <div class="col-md-8 col-sm-8 btn-animated-">
              <div class="container text-center" id="myHeader-">
                <h2 class="f-white text-center">
                  <i class="fa fa-quote-left" aria-hidden="true" ></i> 
                  บริษัท มิตรภาพอาหารสัตว์ จำกัด
                  <i class="fa fa-quote-right" aria-hidden="true" ></i>
                </h2>
              </div>

              <div class="container">
                <div class="row">
                  <div class="col-md-1"></div>
                  <div class="col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
                  <div class="col-md-1"></div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 btn-animated-"></div>
          </div>
        </div>
      </div>

    </div>
</div>

<div id="link1" class="id-top"></div> 
<div class="bg_2_1">
  <div class="bg_2_2">
    <div class="bg_2_3"><br>
      <div class="container" id="myHeader-">
      <h2 class="f-white">
        สวัสดิการ
      </h2>
      <h4 class="f-white fs2">
        <ul>
          <li>โบนัสประจำปี</li>
          <li>ประกันอุบัติเหตุกลุ่ม</li>
          <li>เงินสวัสดิการช่วยหลือกรณี สามี,ภรรยา,บุตร,บิดา,มารดา เสียชีวิต</li>
          <li>ปรับค่าจ้างประจำปี</li>
          <li>วันหยุดพักผ่อนประจำปีสูงสุด 10 วันทำงาน/ปี</li>
          <li>ตรวจสุขภาพประจำปี</li>
          <li>ค่าใช้จ่ายค่าเดินทางค่าที่พักในกรณีเดินทางไปสาขาต่างจังหวัด</li>
          <li>ฝึกอบรมพัฒนาทักษะฟรีตลอดทั้งปี</li>
          <li>วันหยุดตามประเพณี 17 วัน/ปี</li>
        </ul>
      </h4>
    </div>
    <br><br><br>
  </div>

  
  <div id="link2" class="id-top"></div> 
  <div class="bg_1_3-"><br>
  <div class="container" id="myHeader">

  <div>{{getDataForBlog(11)}}</div>
   <div class="container bg_2_3">
    <div class="row">
      <div class="col-md-8 col-sm-8 btn-animated-">
      <div class="container text-left" id="myHeader-">
        <h2 class="f-white fs3 ">
          ตำแหน่งที่ต้องการ <h2 class="text-right f-white fs5">จำนวน {{countLoop}} ตำแหน่ง</h2>
        </h2>
      </div>
    </div>
    <div class="col-md-4 col-sm-4 btn-animated-"></div>
  </div>
  </div>
    <!-- <div class="box">
      <div>
         <h2 class="text-right F5">{{countLoop}} ตำแหน่ง</h2>
      </div>
    </div> -->


    <section>
      <div>
        <div class="row">
          <div class="col-md-12 ">
            <ul class="job-list" *ngFor="let job of jobs">
              <li class="job-preview" [routerLink]="['/workwithusitems-component',job.id,'fem']" routerLinkActive="router-link" (click)="setVisitCount(job.id,job.countVisits)">
                <div class="content float-left">
                  <h4 class="f-green fs5">
                    {{job.jobName}}
                  </h4>
                  <h5 class="f-gray fs6 text-start">
                    <i class="fa fa-map-marker" style="font-size:25px;color:#fd5f38"></i>
                    {{job.placeOfWorkShort}}
                  </h5>
                  <div class="f-gray fs13">เข้าชม : {{job.countVisits}} ครั้ง</div>
                </div>

                <!-- <a href="" class="btn btn-apply float-sm-right float-xs-right">
                  <h6><i class="fa fa-money" style="font-size:25px;color:#fd5f38"></i> เงินเดือน : {{job.salary}}</h6> 
                </a>
                <a href="" class="btn btn-apply float-sm-right float-xs-left">
                  <h6><i class="fa fa-user" style="font-size:25px;color:#fd5f38"></i> จำนวน : {{job.rate}} อัตรา</h6> 
                </a> -->

              </li>

                <br>
                  <div *ngIf="role1">
                    <div class="container">
                      <button type="button" (click)="updateJob(job.id,job.groupId)" class="btn btn-info" style="margin-left: 10px"> Update (11)</button>
                      <button type="button" (click)="deleteJob(job.id,job.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                    </div>
                  </div>
                <br>

            </ul>

            <div *ngIf="role1">
              <div class="container text-center">
                <button type="button" (click)="addJob(11)" class="btn btn-primary" style="margin-left: 10px">Add new (11)</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
  <br>
</div>

 <div class="bg_5_3 margin_footer"></div>
</div>
</div>

    <!-- Creates the bootstrap modal where the image will appear -->
    <div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <form (ngSubmit)="onSubmit(eventsFlag)">
      <div class="modal-dialog" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable" role="document">
          <div class="modal-content">
            
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
              <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
            </div>
            <div class="modal-body">
    
              <tr>
                <td><label>Groups : {{BlogId}}</label></td>
                <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="job.groupId" name="groupId"></td>
              </tr>
              <tr>
               <td><label>Order No : </label></td>
               <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="job.orderNo" name="orderNo"></td>
             </tr>
             <tr>
               <td><label>Href1</label></td>
               <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="job.href1" name="href1"></td>
             </tr>
             <tr>
               <td><label>ชื่องาน</label></td>
               <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="job.jobName" name="jobName"></td>
             </tr>
             <tr>
               <td><label>รายละเอียด</label></td>
               <td><textarea class ="form-control control-size" rows="1"  id = "note" [(ngModel)] = "job.jobDesc" name = "jobDesc"></textarea></td>
             </tr>
             <tr>
               <td><label>คุณสมบัติ</label></td>
               <td><textarea class ="form-control control-size" rows="1"  id = "note" [(ngModel)] = "job.qualification" name = "qualification"></textarea></td>
             </tr>
             <tr>
               <td><label>วิธีการสมัคร</label></td>
               <td><textarea class ="form-control control-size" rows="1"  id = "note" [(ngModel)] = "job.howToApply" name = "howToApply"></textarea></td>
             </tr>
             <tr>
               <td><label>ติดต่อ</label></td>
               <td><textarea class ="form-control control-size" rows="1"  id = "note" [(ngModel)] = "job.contact" name = "contact"></textarea></td>
             </tr>
             <tr>
               <td><label>อัตรา</label></td>
               <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="job.rate" name="rate"></td>
             </tr>
             <tr>
               <td><label>เงินเดือน</label></td>
               <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="job.salary" name="salary"></td>
             </tr>
             <tr>
               <td><label>ที่ทำงานย่อ</label></td>
               <td><textarea class ="form-control control-size" rows="1"  id = "note" [(ngModel)] = "job.placeOfWorkShort" name = "placeOfWorkShort"></textarea></td>
             </tr>
             <tr>
               <td><label>ที่ทำงานเต็ม</label></td>
               <td><textarea class ="form-control control-size" rows="2"  id = "note" [(ngModel)] = "job.placeOfWorkFull" name = "placeOfWorkFull"></textarea></td>
             </tr>
             <tr>
               <td><label>ตำแหน่งที่อยู่</label></td>
               <td><textarea class ="form-control control-size" rows="1"  id = "note" [(ngModel)] = "job.location" name = "location"></textarea></td>
             </tr>
             <tr>
              <td><label>วิธีการเดินทาง</label></td>
              <td><textarea class ="form-control control-size" rows="1"  id = "note" [(ngModel)] = "job.imgPath2" name = "imgPath2"></textarea></td>
            </tr>
  
             
             <tr>
               <td><label>รูป 1</label></td>
               <td><input type="text" class="form-control control-size" id="imgPath1"  [(ngModel)]="job.imgPath1" name="imgPath1" ></td>
             </tr>
             <tr>
               <td><div class="col-4"><button  class="btn btn-success btn-sm" [disabled]="!selectedFiles" (click)="upload('imgPath1')">Upload</button></div></td>
               <td>
                   <div class="col-8">
                     <label class="btn btn-default p-0 control-size">
                       <input type="file" (change)="selectFile($event)" />
                     </label>
                   </div>
             </td>
             </tr>
             <!-- <tr>
               <td><label>รูป 2</label></td>
               <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="job.imgPath2" name="imgPath2"></td>
             </tr>
             <tr>
               <td><div class="col-4"><button  class="btn btn-success btn-sm" [disabled]="!selectedFiles" (click)="upload('imgPath2')">Upload</button></div></td>
               <td> 
                   <div class="col-8">
                     <label class="btn btn-default p-0 control-size">
                       <input type="file" (change)="selectFile($event)" />
                     </label>
                   </div>  
             </td>
             </tr> -->
             <tr>
               <td><label>ใช้งาน</label></td>
               <td><textarea class ="form-control control-size" rows="2"  id = "note" [(ngModel)] = "job.isEnable" name = "isEnable"></textarea></td>
             </tr>
             <tr>
             <td></td>
              <td>
               <div *ngIf="currentFile" class="progress my-3">
                 <div
                   class="progress-bar progress-bar-info progress-bar-striped"
                   role="progressbar"
                   attr.aria-valuenow="{{ progress }}"
                   aria-valuemin="0"
                   aria-valuemax="100"
                   [ngStyle]="{ width: progress + '%' }"
                 >
                   {{ progress }}%
                 </div>
               </div>
              </td>
             </tr>
            </div>
            <div class="modal-footer">
              <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
              <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
            </div>
         
          </div>
        </div>
      </div>
    </form>
      </div>
      <!-- end  Creates the bootstrap modal where the image will appear -->

</body>
</html>