

<br><br><br><br><br><br><br><br>

<!-- <button [routerLink]="['/app-intro-page',0]" routerLinkActive="router-link"   class = "btn btn-success text-center">intro</button>

<button [routerLink]="['/app-intro-page',1]" routerLinkActive="router-link"   class = "btn btn-success text-center">to main</button> -->

efdsfsssssssssssssssssssssssssssssssssssss
sdfffffffffffffffffffffff

<br><br><br><br><br><br><br><br>

