import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { Contact } from '../app.class/contact';
import { map } from 'rxjs/operators';
import { GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
   
  private baseURL = GlobalConstants.httpPath + "contacts";
  private baseVisitURL = GlobalConstants.httpPath + "contacts_count";
  private person : any;

  constructor(private httpClient: HttpClient) { }
  
  getContactsList(): Observable<Contact[]>{
    this.person= this.httpClient.get(this.baseURL)
    .pipe(map((response: any) => response.json()));

    return this.httpClient.get<Contact[]>(`${this.baseURL}`);
  }

  createContact(contact: Contact): Observable<Object>{
    return this.httpClient.post(`${this.baseURL}`, contact);
  }

  getContactById(id: number): Observable<Contact>{
    return this.httpClient.get<Contact>(`${this.baseURL}/${id}`);
  }

  updateContact(id: number, contact: Contact): Observable<Object>{
    return this.httpClient.put(`${this.baseURL}/${id}`, contact);
  }

  updateVisits(id: number, contact: Contact): Observable<Object>{
    return this.httpClient.put(`${this.baseVisitURL}/${id}`, contact);
  }

  deleteContact(id: number): Observable<Object>{
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }
}
