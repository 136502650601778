<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name=viewport content="width=device-width, initial-scale=1">
    <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
    <title>Document</title>
</head>


<body class="ht">
    <div #back></div>
    <div id="bottom" (click)="scrollToElement(back)" >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
   </div>

    <div class="bg_1_1">   
        <div class="bg_2_2">
            <div class="bg_2_3" >
                <div class="container">
                  <div class="row">
                    <div class="col-md-9 col-sm-9 btn-animated">
                    <br>
                    <h1 class="f-white fs3 text-center">ร่วมงานกับเรา</h1>
                    <!-- <h5 class="f-white fs2 text-center">eeeeee</h5> -->
                    <div class="container">
                      <div class="row">
                        <div class="col-xl-1 col-md-1"></div>
                        <div class="col-xl-10 col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
                        <div class="col-xl-1 col-md-1"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 btn-animated-"></div>
                  <div class="col-md-1 col-sm-1 btn-animated">
                    <div class="container text-center">
                       <a (click)="go()"><h6 class="fs3 f-red">X</h6></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        <!-- <section id="sushi-header">
            <div class="Iam">
            <p style="color: #fff;">ร่วมงานกับเรา</p>
            </div>      
        </section> -->

        <!-- <div>{{getDataForBlog(101)}}</div>
        <div *ngFor="let job of jobs">
          <section id="sushi-header" style="background: url({{job.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
            <div class="Iam">
              <p style="color: #fff;">{{job.jobName}}</p>
              <b style="color: red;">
                <div class="innerIam">
                  เครือบริษัท<br />
                  มิตรภาพโภคภัณฑ์<br />
                  จำกัด (มหาชน)<br />
                </div>
              </b>
            </div>    
            </section>
            <div *ngIf="role1">
                <div class="container">
                  <button type="button" (click)="updateJob(job.id,job.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                  <button type="button" (click)="deleteJob(job.id,job.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
              </div>
        </div>
   -->
   
      <!-- <div>{{getDataForBlog(611)}}</div>
      <div *ngFor="let job of jobs">
        <section id="sushi-header" style="background: url({{goods.imgPath1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
          <div class="btn-animated" >
              <h1 class="F1">{{goods.namePoint}}</h1>
              <h5 class="F2">{{goods.desc1}}</h5>
          </div>
          </section>
          <div *ngIf="role1">
            <br>
             <div class="container">
               <button type="button" (click)="updateJob(job.id,job.groupId)" class="btn btn-info" style="margin-left: 10px"> Update ({{job.id}})</button>
               <button type="button" (click)="deleteJob(job.id,job.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
             </div>
             <br>
           </div>
      </div> -->

      

    <div>{{getDataFormId(GId)}}</div>
    <div class="container" *ngFor="let job of jobs">
       
        <div id="myHeader" class="card2">

            <div class="container bg_2_3">
                <div class="text-start">
                    <h1 class="f-white fs3">{{job.jobName}}</h1><br>
                </div>
            </div>
            <br>


            <div class="row">
                <div class="col-md-12 F4 fs6">
                    <h4><i class="fa fa-map-marker" style="font-size:25px;color:#ff0000"></i>&nbsp;&nbsp;&nbsp;สถานที่ปฎิบัติงาน : {{job.placeOfWorkFull}}</h4>
                    <h4><i class="fa fa-money" style="font-size:25px;color:#ff0000"></i>&nbsp;เงินเดือน &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {{job.salary}}</h4>
                    <h4><i class="fa fa-user" style="font-size:25px;color:#ff0000"></i>&nbsp;&nbsp;อัตรา &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {{job.rate}} อัตรา</h4>
                </div>
                <!-- <div class="col-md-8">
                    <h3> {{job.placeOfWorkFull}}</h3>
                    <h3> {{job.salary}}</h3>
                    <h3> {{job.rate}} อัตรา</h3>
                </div> -->
            </div>
            <br>
            <br>
        

        <!-- <div class="row">
            <div class="col-md-3 ">
                <a data-toggle="modal" data-target="#Modal">
                    <h3 class="ct"><i class="fa fa-heart-o" style="font-size:25px"></i>
                        บันทึกงานที่สนใจ</h3>
                </a>
            </div>
            
            <div class="modal fade" id="Modal" role="dialog">
                <div class="modal-dialog modal-sm">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h6 class="modal-title" style="text-align: center;">กรุณาเข้าสู่ระบบ
                                เพื่อเพิ่มตำแหน่งงานที่สนใจ</h6>
                        </div>
                        <div class="btns2">
                            <button class="btn2" id="cancel-btn">ยกเลิก</button>
                            &nbsp; &nbsp; &nbsp;
                            <button class="btn2" id="accept-btn">เข้าสู่ระบบ</button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-md-3">
                <h3 class="ct"><i class="fa fa-print" style="font-size:25px"></i>
                    พิมพ์</h3>
            </div>


            <div class="col-md-3">
                <a data-toggle="modal" data-target="#exampleModal2">
                    <h3 class="ct"><i class="fa fa-share" style="font-size:25px"></i>
                        แชร์</h3>
                </a>
            </div>
            
            <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">แชร์</h5>
                        </div>
                        <form>
                            <div class="modal-body">
                                <a href="https://www.facebook.com/" class="social-button" id="facebook-connect">
                                    <span>Connect with
                                        Facebook</span></a>
                                <a href="https://www.instagram.com/" class="social-button" id="google-connect">
                                    <span>Connect with
                                        instagram</span></a>
                                <a href="https://www.twitter.com/" class="social-button" id="twitter-connect">
                                    <span>Connect with
                                        Twitter</span></a>
                                <a href="https://www.linkedin.com/" class="social-button" id="linkedin-connect">
                                    <span>Connect with
                                        LinkedIn</span></a>
                                <a href="https://accounts.google.com/ServiceLogin/webreauth?passive=1209600&continue=https%3A%2F%2Faccounts.google.com%2Fb%2F0%2FAddMailService&followup=https%3A%2F%2Faccounts.google.com%2Fb%2F0%2FAddMailService&authuser=0&flowName=GlifWebSignIn&flowEntry=ServiceLogin"
                                    class="social-button" id="email-connect"> <span>Connect with Email</span></a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <div class="col-md-3">
                <button class="btn1 btn1-1" >
                    <h3 style="text-align: center; ">
                        <a href="#home" style="color: #fff;"><i>สมัครงาน</i> </a>
                    </h3>
                </button>
            </div>
        </div>
        <br>
        <br>
        <br> -->
        <div><h2 class="f-green fs5">รายละเอียดงาน</h2>
            <h4 class="f-gray">
                <div>{{splitStringFormat(job.jobDesc)}}</div>
                <ul>
                    <li *ngFor="let detailList of detailLists; let i = index" class="li1">{{detailList}}</li>
                </ul>
     
            </h4>
        </div>
        <br>
        <br>


        <div><h2 class="f-green fs5">คุณสมบัติผู้สมัคร</h2>
            <h4 class="f-gray text-left" >
                <div>{{splitStringFormat(job.qualification)}}</div>
                <ul>
                    <li *ngFor="let detailList of detailLists; let i = index" class="li1">{{detailList}}</li>
                </ul>
            </h4>
        </div>
        <br>
        <br>

        <div><h2 class="f-green fs5">สวัสดิการ</h2>
            <h4 class="f-gray text-left">
                <a class="nav-link f-red" [routerLink]="['/workwithus-component']" routerLinkActive="router-link" href="#">
                    ดูข้อมูลสวัสดิการทั้งหมด
                </a>
            </h4>
        </div>
        <br>
        <br>

        <div><h2 class="f-green fs5">วิธีการสมัคร</h2>
            <h4 class="f-gray text-left">
                <div>{{splitStringFormat(job.howToApply)}}</div>
                <ul>
                    <li *ngFor="let detailList of detailLists; let i = index" class="li1">{{detailList}}</li>
                </ul>
                <!-- - สมัครผ่านทาง Email<br>
                - ส่งเอกสารสมัครงานทางไปรษณีย์<br>
                - สมัครด้วยตนเอง<br>
                - สอบถามข้อมูลเพิ่มเติมทางโทรศัพท์<br> -->
            </h4><br>
            <h4 class="f-gray fs11">หรือ คลิ๊ก : <a class="f-red fs11" href="https://www.jobthai.com/th/company/133826" target="_blank" rel="noopener noreferrer">www.jobthai.com</a> </h4>
        </div>
        <br>
        <br>
    

        <div><h2 class="f-green fs5">ติดต่อ</h2>
            <h4 class="f-gray text-left">
                <div>{{splitStringFormat(job.contact)}}</div>
                <ul >
                    <li *ngFor="let detailList of detailLists; let i = index" class="li2">{{detailList}}</li>
                </ul>
                <!-- ฝ่ายทรัพยากรบุคคล<br>
                MITTRAPHAP PRODUCT COMPANY LIMITED (บริษัท มิตรภาพโภคภัณฑ์ จำกัด)<br>
                สำนักงานใหญ่ : บริษัท มิตรภาพโภคภัณฑ์ จำกัด (มหาชน) <br>
                11 ซ.รามคำแหง 81/1 ถ.รามคำแหง แขวงหัวหมาก เขตบางกะปิ กทม. 10240<br>
                โทรศัพท์ :02-7322448-57<br>
                อีเมล : MITTRAPHAP@gmail.com (อีเมลนี้ใช้สำหรับการติดต่อและสมัครงาน)<br>
                เว็บไซต์ : http://www..co.th -->
            </h4>
        </div>
        <br>
        <br>

        <div><h2 class="f-green fs5">สถานที่ปฏิบัติงาน</h2>
            <h4 class="f-gray text-left">
                <div>{{splitStringFormat(job.placeOfWorkFull)}}</div>
                <ul >
                    <li *ngFor="let detailList of detailLists; let i = index" class="li2">{{detailList}}</li>
                </ul>
            </h4>
        </div>


      <!-- <div><h2 class="F5 fs7">วิธีการเดินทาง</h2>
            <h5 class="FS5 text-left">
                <div>{{splitStringFormat(job.imgPath2)}}</div>
                <ul >
                    <li *ngFor="let detailList of detailLists; let i = index" class="li2">{{detailList}}</li>
                </ul>
            </h5>
        </div>
        <br>
        <br> -->

        <!-- <div><h2>แผนที่ออนไลน์</h2>
            <h5><a href={{job.location}} ref="_blank">{{job.location}}</a></h5>
        </div>
        <br>
        <br> -->


        <!-- <div>
            <div class="mapouter"><div class="gmap_canvas">
                
              <iframe class="gmap_iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" 
                src={{job.location}}>
              </iframe>  src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=University of Oxford&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
            </div>
            <style>.mapouter{position:relative;text-align:right;width:600px;height:400px;}.gmap_canvas {overflow:hidden;background:none!important;width:600px;height:400px;}.gmap_iframe {width:600px!important;height:400px!important;}</style></div>
        </div> -->

        <div>  
            <!-- <h4>แผนที่ออนไลน์</h4>
            <div id="home"></div>

            <iframe
            src={{job.location}}
            class="map" width="100%" height="300px" style="border:0;" allowfullscreen="" loading="lazy">
         </iframe> -->
         
          <!--  <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d856.912671705351!2d100.633540220785!3d13.761503988441612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d61ee4f9afccd%3A0x1b3320aa1af36828!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4oeC4tOC4leC4o-C4oOC4suC4nuC4reC4suC4q-C4suC4o-C4quC4seC4leC4p-C5jCDguIjguLPguIHguLHguJQ!5e0!3m2!1sth!2sth!4v1617855504510!5m2!1sth!2sth"
                class="map" width="100%" height="300px" style="border:0;" allowfullscreen="" loading="lazy">
            </iframe> 
         <iframe src={{job.location}} class="map" width="100%" height="300px" style="border:0;" allowfullscreen="" loading="lazy">
            </iframe> -->
        </div> 
        <br>
        
        <div class="container text-center">
            <a (click)="go()"><h6 class="fs3 f-red">X</h6></a>
        </div>
        
    </div>
   
    <!-- <div class="container"><img class="mtp_underline" src="assets\img\underline\underline.png"> </div> -->


        <!-- <div>
            <h2>
                เลือกวิธีการสมัครงาน
                <i class="fa fa-hand-o-down" style="font-size:20px;color:#fd5f38"></i>
            </h2>
            <br>

            <div class="row">
                <div class="col-md-4">
                    <div class="buttons">
                        <button class="btn btn-1" data-toggle="modal" data-target="#myModal">
                            <h4><i class="fa fa-file" style="font-size:25px"></i>
                                สมัครด่วน
                            </h4>
                        </button>
                        
                        <div class="modal fade" id="myModal" role="dialog">
                            <div class="modal-dialog modal-sm">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">กรุณาเข้าสู่ระบบเพื่อสมัครงาน</h5>
                                    </div>
                                    <div class="btns2">
                                        <button class="btn2" id="cancel-btn">ยกเลิก</button>
                                        &nbsp; &nbsp; &nbsp;
                                        <button class="btn2" id="accept-btn">เข้าสู่ระบบ</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>


                        <button class="btn btn-1" data-toggle="modal" data-target="#exampleModal">
                            <h4><i class="fa fa-folder-open" style="font-size:25px"></i>
                                ส่งไฟล์ประวัติ
                            </h4>
                        </button>
                        
                        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">ส่งไฟล์ประวัติ</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <form>
                                        <div class="modal-body">
                                            <div class="form-group">
                                                <h6>อีเมลของคุณ (สำหรับให้บริษัทติดต่อ)</h6>
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="ระบุอีเมลของคุณ">

                                            </div>
                                           
                                            <div class="btns2">
                                                <button class="btn3 btn3-1">
                                                    <h6 style="text-align: center;">
                                                        เลือกไฟล์ (ทั้งหมดไม่เกิน 5 MB)
                                                    </h6>
                                                </button>
                                            </div>
                                            <div>
                                                <small>
                                                    <h6>เฉพาะ PNG, JPG, JPEG, หรือ PDF(ไม่จำกัดจำนวนไฟล์)</h6>
                                                </small>
                                            </div>

                                            <div class="boxes " style="margin-top: 5vh;">
                                                <input type="checkbox" id="box-1">
                                                <label for="box-1">บันทึกข้อมูลนี้สำหรับสมัครงานครั้งต่อไป
                                                    <i class="fa fa-exclamation"
                                                        style="font-size:20px;color:#fd5f38"></i>
                                                </label>
                                                <input type="checkbox" id="box-2">
                                                <label for="box-2">ยอมรับ เงื่อนไขข้อตกลงการใช้บริการ และ
                                                    นโยบายความเป็นส่วนตัว</label>
                                            </div>


                                            <div class="btns2">
                                                <button class="btn4 btn4-1">
                                                    <h6 style="text-align: center;">
                                                        สมัครงาน
                                                    </h6>
                                                </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                        <br>


                        <button class="btn btn-1" data-toggle="modal" data-target="#exampleModal1">
                            <h4><i class="fa fa-envelope" style="font-size:25px"></i>
                                ส่งอีเมล
                            </h4>
                        </button>
                     
                        <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">สมัครงานผ่านอีเมล</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <form>
                                        <div class="modal-body">

                                            <a href="https://accounts.google.com/ServiceLogin/webreauth?passive=1209600&continue=https%3A%2F%2Faccounts.google.com%2Fb%2F0%2FAddMailService&followup=https%3A%2F%2Faccounts.google.com%2Fb%2F0%2FAddMailService&authuser=0&flowName=GlifWebSignIn&flowEntry=ServiceLogin"
                                                class="social-button" id="email-connect"> <span>Connect with
                                                    Email</span></a>
                                            <br>
                                            <br>

                                            <h4 style="text-align: center;">
                                                <i class="fa fa-long-arrow-left"
                                                    style="font-size:20px;color:#fd5f38"></i> OR
                                                <i class="fa fa-long-arrow-right"
                                                    style="font-size:20px;color:#fd5f38"></i>
                                            </h4>

                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Email address</label>
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter email">
                                                <small id="emailHelp" class="form-text text-muted">We'll never share
                                                    your email with anyone
                                                    else.</small>
                                            </div>
                                            <div class="form-group">
                                                <label for="exampleInputPassword1">Password</label>
                                                <input type="password" class="form-control" id="exampleInputPassword1"
                                                    placeholder="Password">
                                            </div>
                                            <div class="form-group form-check">
                                                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                                <label class="form-check-label" for="exampleCheck1">Remember
                                                    Password</label>
                                            </div>
                                        </div>
                                        <div class="btns2">
                                            <button class="btn2" id="cancel-btn">Close</button>
                                            &nbsp; &nbsp; &nbsp;
                                            <button class="btn2" id="accept-btn">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <br>

                        
                    </div>
                </div>

                <div class="col-md-8 text-line">
                    <h4>สมัครด้วยประวัติที่ฝากกับ JobThai</h4>
                    <h4>สมัครด้วยการส่งไฟล์เรซูเม่ ผลงาน หรืออื่นๆ </h4>
                    <h4>สมัครงานนี้ผ่านอีเมล</h4>
                </div>
            </div>

        </div> -->


        <!-- <br>
        <div>
            <h2>งานอื่นๆ ของบริษัทนี้</h2>
            <br>

            <div class="row">
                <div class="col-md-3">
                    <div class="card">
                        <h4>หัวหน้าคลังสินค้า</h4>
                        <h6><i class="fa fa-map-marker" style="font-size:25px;color:#fd5f38"></i>
                            เขตบางกะปิ กรุงเทพมหานคร
                        </h6>
                        <h6><i class="fa fa-money" style="font-size:25px;color:#fd5f38"></i>
                            ตามประสบการณ์
                        </h6>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card">
                        <h4>หัวหน้าคลังสินค้า</h4>
                        <h6><i class="fa fa-map-marker" style="font-size:25px;color:#fd5f38"></i>
                            เขตบางกะปิ กรุงเทพมหานคร
                        </h6>
                        <h6><i class="fa fa-money" style="font-size:25px;color:#fd5f38"></i>
                            ตามประสบการณ์
                        </h6>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card">
                        <h4>Programmer</h4>
                        <h6><i class="fa fa-map-marker" style="font-size:25px;color:#fd5f38"></i>
                            เขตบางกะปิ กรุงเทพมหานคร
                        </h6>
                        <h6><i class="fa fa-money" style="font-size:25px;color:#fd5f38"></i>
                            ไม่ระบุ
                        </h6>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card">
                        <h4>Programmer</h4>
                        <h6><i class="fa fa-map-marker" style="font-size:25px;color:#fd5f38"></i>
                            เขตบางกะปิ กรุงเทพมหานคร
                        </h6>
                        <h6><i class="fa fa-money" style="font-size:25px;color:#fd5f38"></i>
                            ไม่ระบุ
                        </h6>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <br> -->



        
        <div *ngIf="role1">
         <br>
          <div class="container">
            <button type="button" (click)="updateJob(job.id,job.groupId)" class="btn btn-info" style="margin-left: 10px"> Update ({{job.id}})</button>
            <button type="button" (click)="deleteJob(job.id,job.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
          </div>
          <br>
        </div>

        
    </div>
    <!-- <div class="bg_5_3 margin_footer"></div> -->
</div>
</div>
    <!-- Creates the bootstrap modal where the image will appear -->
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <form (ngSubmit)="onSubmit(eventsFlag)">
    <div class="modal-dialog" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h4>
            <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
          </div>
          <div class="modal-body">
  
            <tr>
              <td><label>Groups : {{BlogId}}</label></td>
              <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="job.groupId" name="groupId"></td>
            </tr>
            <tr>
             <td><label>Order No : </label></td>
             <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="job.orderNo" name="orderNo"></td>
           </tr>
           <tr>
             <td><label>Href1</label></td>
             <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="job.href1" name="href1"></td>
           </tr>
           <tr>
             <td><label>ชื่องาน</label></td>
             <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="job.jobName" name="jobName"></td>
           </tr>
           <tr>
             <td><label>รายละเอียด</label></td>
             <td><textarea class ="form-control control-size" rows="1"  id = "note" [(ngModel)] = "job.jobDesc" name = "jobDesc"></textarea></td>
           </tr>
           <tr>
             <td><label>คุณสมบัติ</label></td>
             <td><textarea class ="form-control control-size" rows="1"  id = "note" [(ngModel)] = "job.qualification" name = "qualification"></textarea></td>
           </tr>
           <tr>
             <td><label>วิธีการสมัคร</label></td>
             <td><textarea class ="form-control control-size" rows="1"  id = "note" [(ngModel)] = "job.howToApply" name = "howToApply"></textarea></td>
           </tr>
           <tr>
             <td><label>ติดต่อ</label></td>
             <td><textarea class ="form-control control-size" rows="1"  id = "note" [(ngModel)] = "job.contact" name = "contact"></textarea></td>
           </tr>
           <tr>
             <td><label>อัตรา</label></td>
             <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="job.rate" name="rate"></td>
           </tr>
           <tr>
             <td><label>เงินเดือน</label></td>
             <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="job.salary" name="salary"></td>
           </tr>
           <tr>
             <td><label>ที่ทำงานย่อ</label></td>
             <td><textarea class ="form-control control-size" rows="1"  id = "note" [(ngModel)] = "job.placeOfWorkShort" name = "placeOfWorkShort"></textarea></td>
           </tr>
           <tr>
             <td><label>ที่ทำงานเต็ม</label></td>
             <td><textarea class ="form-control control-size" rows="2"  id = "note" [(ngModel)] = "job.placeOfWorkFull" name = "placeOfWorkFull"></textarea></td>
           </tr>
           <tr>
             <td><label>ตำแหน่งที่อยู่</label></td>
             <td><textarea class ="form-control control-size" rows="1"  id = "note" [(ngModel)] = "job.location" name = "location"></textarea></td>
           </tr>
           <tr>
            <td><label>วิธีการเดินทาง</label></td>
            <td><textarea class ="form-control control-size" rows="1"  id = "note" [(ngModel)] = "job.imgPath2" name = "imgPath2"></textarea></td>
          </tr>

           
           <tr>
             <td><label>รูป 1</label></td>
             <td><input type="text" class="form-control control-size" id="imgPath1"  [(ngModel)]="job.imgPath1" name="imgPath1" ></td>
           </tr>
           <tr>
             <td><div class="col-4"><button  class="btn btn-success btn-sm" [disabled]="!selectedFiles" (click)="upload('imgPath1')">Upload</button></div></td>
             <td>
                 <div class="col-8">
                   <label class="btn btn-default p-0 control-size">
                     <input type="file" (change)="selectFile($event)" />
                   </label>
                 </div>
           </td>
           </tr>
           <!-- <tr>
             <td><label>รูป 2</label></td>
             <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="job.imgPath2" name="imgPath2"></td>
           </tr>
           <tr>
             <td><div class="col-4"><button  class="btn btn-success btn-sm" [disabled]="!selectedFiles" (click)="upload('imgPath2')">Upload</button></div></td>
             <td> 
                 <div class="col-8">
                   <label class="btn btn-default p-0 control-size">
                     <input type="file" (change)="selectFile($event)" />
                   </label>
                 </div>  
           </td>
           </tr> -->
           <tr>
             <td><label>ใช้งาน</label></td>
             <td><textarea class ="form-control control-size" rows="2"  id = "note" [(ngModel)] = "job.isEnable" name = "isEnable"></textarea></td>
           </tr>
           <tr>
           <td></td>
            <td>
             <div *ngIf="currentFile" class="progress my-3">
               <div
                 class="progress-bar progress-bar-info progress-bar-striped"
                 role="progressbar"
                 attr.aria-valuenow="{{ progress }}"
                 aria-valuemin="0"
                 aria-valuemax="100"
                 [ngStyle]="{ width: progress + '%' }"
               >
                 {{ progress }}%
               </div>
             </div>
            </td>
           </tr>
          </div>
          <div class="modal-footer">
            <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
            <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
          </div>
       
        </div>
      </div>
    </div>
  </form>
    </div>
    <!-- end  Creates the bootstrap modal where the image will appear -->
</body>


</html>