<div class="col-md-6 offset-md-3">
    <h3> Create Employee </h3>
    <!-- <form (ngSubmit) = "onSubmit()">
    
        <div class="form-group">
            <label> First Name</label>
            <input type="text" class ="form-control" id = "firstName"
                [(ngModel)] = "employee.firstName" name = "firstName">
        </div>
    
        <div class="form-group">
            <label> Last Name</label>
            <input type="text" class ="form-control" id = "lastName"
                [(ngModel)] = "employee.lastName" name = "lastName">
        </div>
    
        <div class="form-group">
            <label> Tel</label>
            <input type="text" class ="form-control" id = "tel"
                [(ngModel)] = "employee.tel" name = "tel">
        </div>

        <div class="form-group">
            <label> Email Id</label>
            <input type="text" class ="form-control" id = "emailAddr"
                [(ngModel)] = "employee.emailAddr" name = "emailAddr">
        </div>

        
        <div class="form-group">
            <label> Note </label>
            <input type="text" class ="form-control" id = "note"
                [(ngModel)] = "employee.note" name = "note">
        </div>
    
        <button class = "btn btn-success" type ="submit">Submit</button>
    
    </form> -->
    </div> 