
import { Component, OnInit } from '@angular/core';
import { Goods } from '../../app.class/goods';
import { GoodsService } from '../../app.service/goods.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThisReceiver } from '@angular/compiler';
import { GlobalConstants } from '../../common/global-constants';
import { goods_controller } from '../../app.controller/goods.controller';

declare var $:any;

@Component({
  selector: 'app-testarea2',
  templateUrl: './testarea2.component.html',
  styleUrls: ['./testarea2.component.css']
})
export class Testarea2Component implements OnInit {

  
  constructor() {
    }
    
ngOnInit(): void {
   }
 
  

  
 }