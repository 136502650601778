
 

import { Component, OnInit } from '@angular/core';
import { Goods } from '../../app.class/goods';
import { GoodsService } from '../../app.service/goods.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThisReceiver } from '@angular/compiler';
import { GlobalConstants } from '../../common/global-constants';
import { goods_controller } from '../../app.controller/goods.controller';

declare var $:any;
@Component({
  selector: 'app-testarea',
  templateUrl: './testarea.component.html',
  styleUrls: ['./testarea.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})

export class TestareaComponent implements OnInit {
  t_goodss: Goods[]=new Array();//ไว้สำหรับนำมาแสดง
  goodss: Goods[]=new Array();//ไว้สำหรับนำมาแสดง
  goods: Goods = new Goods();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  id: number=0 ;
  isLoggedIn: boolean=true;
  eventsFlag : string ="A";
  role1 : boolean= false;
  BlogId : number=0;

  email : string="";

  title = 'app works!';

  jQueryExampleModal() { // to show a modal with dummyId
    $('#dummyId').modal('show');
 }
  
  constructor(private goodsService: GoodsService,
    private router: Router,private formBuilder: FormBuilder,private route: ActivatedRoute) {
       this.role1=GlobalConstants.role1;
       this.email="thawat.ttc@gmail.com"
    }

   ngOnInit(): void {
      this.getGoods();

      this.BlogId = this.route.snapshot.params['id'];
      // alert (this.BlogId);
       this.scrollToElement(this.BlogId);
   }
 
   private getGoods(){
     this.goodsService.getGoodsList().subscribe(data => {
         this.t_goodss=data;
     });
   }

   getDataForBlog(ind: number){
    let check : boolean |true;

    this.goodss.splice(0,this.goodss.length);//clear all
    this.t_goodss.forEach( (element : any) =>  {
      if (element.groupId==ind){//ถ้าเป็นกลุ่มสินค้าและบริการ
        if (check){
          check=false;
        }else{
          check=true;
        }
         element.isSwap=check;

         this.goodss.push(element);
      }
    });
  }


  addGoods(blogId: number){
    this.goods = new Goods();
    this.goods.groupId=blogId;

    this.showModal = true; // Show-Hide Modal Check
    this.BlogId=blogId;
    this.eventsFlag="A";
  }

   updateGoods(id: number,blogId: number){
     this.id=id;
     this.goodsService.getGoodsById(this.id).subscribe(data => {
       this.goods = data;
     }, error => console.log(error));
    this.showModal = true; // Show-Hide Modal Check
    this.BlogId=blogId;
    this.eventsFlag="E";
   }
 
    deleteGoods(id: number,blogId: number){
      this.goodsService.deleteGoods(id).subscribe( data => {
        console.log(data);
        this.getGoods();
      })
      this.BlogId=blogId;
      this.eventsFlag="D";
    }
 
 //for Submit form when action
   onSubmit(AddEditMode : string){
   
     if (AddEditMode=="A") {
       this.goodsService.createGoods(this.goods).subscribe( data =>{
         console.log(data);
          this.getGoods();//ย้อนกลับมาหน้าตัวเอง
       },
       error => console.log(error));
     }else if (AddEditMode=="E"){
       this.goodsService.updateGoods(this.id, this.goods).subscribe( data =>{
         
         console.log(data);
          this.getGoods();//ย้อนกลับมาหน้าตัวเอง
       }
       , error => console.log(error));
     }
      this.hide();
   }
 
 //for popup form
   showModal: boolean | any;
   registerForm: FormGroup | any;
   submitted = false;
 
  show()
    { 
      this.showModal = true;
    }

   hide()
   {
     this.showModal = false;
   }

   verifyUser(Us : boolean){
     GlobalConstants.role1=Us;
     this.role1=GlobalConstants.role1;
   }

   scrollToElement($element : any): void {
    console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
   }
 
 }





