<!-- <div *ngIf="isIntroPage==0">
    <router-outlet></router-outlet>
</div>

<div *ngIf="isIntroPage==1">
    <app-menubar></app-menubar> 
    <router-outlet></router-outlet>
    <app-footerbar></app-footerbar> 
</div> -->

    <app-menubar></app-menubar> 
    <router-outlet></router-outlet>
    <app-footerbar></app-footerbar> 


<!-- <app-mainpage></app-mainpage> -->