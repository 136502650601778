<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.1.0/jquery.min.js"></script> 

<div class="wrapper">
  <div class="parent" onclick="">
    <div class="child bg-one">
      <a href="#">Los Angeles</a>
    </div>
  </div>

  <div class="parent right" onclick="">
    <div class="child bg-two">
      <a href="#">London</a>
    </div>
  </div>

  <div class="parent" onclick="">
    <div class="child bg-three">
      <a href="#">New York</a>
    </div>
  </div>

  <div class="parent right" onclick="">
    <div class="child bg-four">
      <a href="#">Hollywood</a>
    </div>
  </div>

  <div class="parent" onclick="">
    <div class="child bg-five">
      <a href="#">Dubai</a>
    </div>
  </div>

  <div class="parent right" onclick="">
    <div class="child bg-six">
      <a href="#">San Francisco</a>
    </div>
  </div>
</div>