<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>

<body class="ht">
  <div #back></div>
  <div id="bottom" (click)="scrollToElement(back)" >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
  </div>

 

  <div class="bg_2_1"> 
    <div class="bg_1_2">
     <div class="bg_0_3">
        <div>{{getDataForBlog(411)}}</div>
        <div *ngFor="let goods of goodss">
          <!-- <section id="sushi-header" style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
          </section> -->
          
          <div class="btn-animated margin_head">
            <h1 class="f-white fs1" >{{goods.namePoint}}</h1><br>
            <h5 class="f-white fs2" >{{goods.desc1}}</h5>
          </div>

            <div *ngIf="role1">
                <div class="container">
                  <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                  <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
            </div>
        </div>
     </div>

  <!-- <div class="container">
      <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-8 col-centered">
              <div class="maintxt">
                  My great text.
              </div>
          </div>
      </div>
  </div> -->


  <div class="bg_4_3 margin_head2 btn-animated" >
    <div class="container">
      <div class="row">
          <div class="col-md-8 col-sm-8 btn-animated-">
          <div>{{getDataForBlog(411)}}</div>
           <div *ngFor="let goods of goodss">
             
            <h1 class="F3 fs3">{{goods.namePoint}}</h1>
            <h6 class="f-white fs2">
              <i class="fa fa-quote-left f-white fs2" aria-hidden="true" ></i>
                {{goods.desc2}}
              <i class="fa fa-quote-right f-white fs2" aria-hidden="true" ></i>
            </h6>

            <!-- <div><img class="mtp_underline" src="assets\img\underline\underline.png"> </div> -->

            <div *ngIf="role1">
              <div>
                <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 btn-animated-"></div>
      </div>
    
    </div>
  </div>

<!-- <div class="bg_4_3" >
<div class="container">
  <div class="row">
      <div class="col-md-12 btn-animated margin_head">
      <div class="container" id="myHeader">
        <h1 class="f-white fs3">วิสัยทัศน์(Vision)</h1><br>
        <h5 class="F2 fs2 text-left">
          <i class="fa fa-quote-left" aria-hidden="true" ></i> 
          เครือมิตรภาพเติบโตต่อเนื่องอย่างมั่นคง จากฐานธุรกิจสุกร สู่ธุรกิจเชื่อมโยงเพื่อประโยชน์ร่วมกันของทุกฝ่ายด้วยคุณภาพและคุณธรรม
          <i class="fa fa-quote-right" aria-hidden="true" ></i>
        </h5>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
      <div class="col-md-1"></div>
    </div>
  </div>
   
</div>
</div> -->


</div>
</div>


<!-- 
    <div>{{getDataForBlog(411)}}</div>
    <div *ngFor="let goods of goodss">
      <section id="sushi-header" style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
        <div class="btn-animated" >
            <h1 class="F1 fs1">{{goods.namePoint}}</h1><br>
            <h1 class="F2 fs2">{{goods.desc1}}</h1>
        </div>
        </section>
        <div *ngIf="role1">
            <div class="container">
              <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
              <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
          </div>
    </div> -->




<!-- ปิดข่าวก่อน
  <div class="bg_2_1"> 
  <div class="bg_2_2"> 
  <div class="bg_2_3"> 
    <div class="container"><br>
      <section id="about" class="about">
        <table class="container text-center">
          <div>{{getDataForBlog(413)}}</div>
          <div *ngFor="let goods of goodss">
          <h1 class="F3 fs3">{{goods.namePoint}}</h1>
          <h3 class="F2 fs11">{{goods.desc1}}</h3>
          <div *ngIf="role1">
            <div>
              <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
              <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
          </div>
        </div>
        </table>
      </section><br><br><br>
    </div>
  </div>

  
       

        <div>{{getDataForBlog(412,1)}}</div>
        
        <div class="bg_3_3-" *ngFor="let goods of goodss"> 
        <div class="container card" >
          <div class="row" >
            <div class="col-md-7 px-3">
              <div class="card-block px-6">
                <h3 class="card-title- F1-2 fs5">
                  <svg xmlns="" width="18" height="18" fill="currentColor"
                    class="bi bi-megaphone-fill" viewBox="0 1 16 16" color="red">
                    <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z" />
                  </svg> 
                  {{goods.namePoint}} 
                </h3>
                <h4 class="F5 fs4 text-left">{{goods.desc1}}</h4>
                <h5 class="F6 fs2 text-left">{{goods.desc2}}</h5>
                <br><br>

                <h5 class="card-text FS2 text-left">
                 <p>ประกาศเมื่อ : {{goods.createDate}} </p>
                 <p>ปรับปรุงล่าสุด : {{goods.modifyDate}} </p> 
                </h5>
                <br>

                
              </div>
            </div>

            <div class="col-md-5">
              <div id="CarouselTest-{{goods.id}}" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                  <li data-target="#CarouselTest" data-slide-to="0" class="active"></li>
                  <li data-target="#CarouselTest" data-slide-to="1"></li>
               
    
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <a  [routerLink]="['/news-component',goods.groupIdRef1]" routerLinkActive="router-link">
                      <img class="d-block" src={{goods.imgPathTemp1}} alt="" width="100%" height="100%">
                     </a>
                  </div>
                  <div class="carousel-item"> 
                    <a  [routerLink]="['/news-component',goods.groupIdRef1]" routerLinkActive="router-link">
                      <img class="d-block" src={{goods.imgPathTemp2}} alt="" width="100%" height="100%">
                     </a>
                  </div>
                  <a class="carousel-control-prev" href="#CarouselTest-{{goods.id}}" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#CarouselTest-{{goods.id}}" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
 
          </div>
          <div *ngIf="role1">
            <br>
            <div>
              <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
              <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
            <br>
          </div>
        </div><br>
      </div>
      
   

      <div class="container">
        <marquee  direction="left" Scrolldelay=0  >
          <h2 class="F5 fs3 text-center">ข่าวอื่นๆ</h2>
          <div><img class="mtp_underline" src="assets\img\underline\underline.png"> </div>
        </marquee>
      </div>


      <div class="container card " >
        <br><br>
        <div class="container-">
          <div id="carouselExample1" class="carousel slide" data-ride="carousel" data-interval="9000">
            <div class="carousel-inner row w-10 mxauto" role="listbox">
              <div>{{getDataForBlog(412,2,1,1)}}</div>
              <div class="carousel-item active " *ngFor="let goods of goodss">
                
                  <div class="row" >
                    <div id="second" class="col-md-7 px-3">
                      <div class="card-block px-6 ">
                        <h3 class="card-title- F1-2 fs5"><svg xmlns="" width="18" height="18" fill="currentColor"
                            class="bi bi-megaphone-fill" viewBox="0 1 16 16" color="red">
                            <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z" />
                          </svg> {{goods.namePoint}} </h3>
                          <h4 class="F5 fs4 text-left">{{goods.desc1}}</h4>
                          <h5 class="F6 fs2 text-left">{{goods.desc2}}</h5>
                          
                          <br><br>
          
                          <h5 class="card-text FS2 text-left">
                           <p>ประกาศเมื่อ : {{goods.createDate}} </p>
                           <p>ปรับปรุงล่าสุด : {{goods.modifyDate}} </p> 
                          </h5>
                          <br>
                       
                       
                      </div>
                    </div>
                    <div id="first" class="col-md-5">
                      <a  [routerLink]="['/news-component',goods.groupIdRef1]" routerLinkActive="router-link">
                        <img class="d-block" src={{goods.imgPathTemp1}} alt="" width="18" height="18">
                       </a>
                    </div>
      
                  </div>
      
                <div *ngIf="role1">
                  <br>
                  <div class="container">
                    <div class="col-md-12 text-center">
                      <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>&nbsp;
                      <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger"> Delete</button>
                    </div>
                  </div>
                  <br>
                </div>
            </div>
            <div>{{getDataForBlog(412,2,2,999)}}</div>
            <div class="carousel-item" *ngFor="let goods of goodss"> 
             
                <div class="row">
                 
                  <div id="second" class="col-md-7 px-3">
                    <div class="card-block px-6">
                      <h3 class="card-title- F1-2 fs5"><svg xmlns="" width="18" height="18" fill="currentColor"
                          class="bi bi-megaphone-fill" viewBox="0 1 16 16" color="red">
                          <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z" />
                        </svg> {{goods.namePoint}} 
                      </h3>
                      <h4 class="F5 fs4 text-left">{{goods.desc1}}</h4>
                      <h5 class="F6 fs2 text-left">{{goods.desc2}}</h5>
                      
                      <br><br>
      
                      <h5 class="card-text FS2 text-left">
                       <p>ประกาศเมื่อ : {{goods.createDate}} </p>
                       <p>ปรับปรุงล่าสุด : {{goods.modifyDate}} </p> 
                      </h5>
                      <br>
                      
                    </div>
                  </div>
                  <div id="first" class="col-md-5">
                    <a  [routerLink]="['/news-component',goods.groupIdRef1]" routerLinkActive="router-link">
                      <img class="d-block" src={{goods.imgPathTemp1}} alt="" width="18" height="18">
                     </a>
                  </div>
      
                </div>
             
              <div *ngIf="role1">
                <br>
                <div class="container">
                  <div class="col-md-12 text-center">
                    <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>&nbsp;
                    <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger"> Delete</button>
                  </div>
                </div>
                <br>
              </div>
              
            </div>


      
            </div>
            <a class="carousel-control-prev" id="carousel-control-prev" href="#carouselExample1" role="button"
              data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" id="carousel-control-next" href="#carouselExample1" role="button"
              data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>

          <div *ngIf="role1">
            <div class="content">
              <button type="button" class="btn btn-primary" (click)="addGoods(412)">Add new (412)</button>
            </div>
          </div>

          </div>
        </div>
        <div class="bg_1_3 padding-footer"></div>
      </div>
    </div> -->
    
      

  <div class="bg_1_1"> 
    <div class="bg_2_2"> 
      <div class="bg_2_3" >
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-sm-8 btn-animated">
              
            <div>{{getDataForBlog(414)}}</div>
            <div *ngFor="let goods of goodss">
                <h1 class="f-white fs3 text-center">{{goods.namePoint}}</h1>
                <h5 class="f-white fs2 text-center">{{goods.desc1}}</h5>
                <div class="container">
                  <div class="row">
                    <div class="col-xl-1 col-md-1"></div>
                    <div class="col-xl-10 col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
                    <div class="col-xl-1 col-md-1"></div>
                  </div>
                </div>
    
            <div *ngIf="role1">
              <div>
                <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 btn-animated-"></div>
        </div>
      </div>
    </div>

    

    <div>{{getDataForBlog(43,1)}}</div>
      <div class="bg_3_3-" *ngFor="let goods of goodss"> 
        <div class="container card" >
          <div class="row" >
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="card-block">
                <h3 class="card-title- F1-2 fs5"><svg xmlns="" width="18" height="18" fill="currentColor"
                    class="bi bi-megaphone-fill" viewBox="0 1 16 16" color="red">
                    <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z" />
                  </svg> {{goods.namePoint}} </h3>
                  <h4 class="f-grren fs6 text-left">{{goods.desc1}}</h4>
                  <h5 class="f-gray fs11 text-left">{{goods.desc2}}</h5>
                  <!-- <a class="mt-auto btn btn-primary" [routerLink]="['/news-component',goods.groupIdRef1]" routerLinkActive="router-link">อ่านต่อ</a> -->
                  <br><br>
              </div>

              <div id="CarouselTest-{{goods.id}}" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                  <li data-target="#CarouselTest" data-slide-to="0" class="active"></li>
                  <li data-target="#CarouselTest" data-slide-to="1"></li>
                  <!-- <li data-target="#CarouselTest" data-slide-to="2"></li> -->
    
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <a  [routerLink]="['/news-component',goods.groupIdRef1,goods.namePoint,goods.desc1,goods.desc2]" routerLinkActive="router-link" (click)="setVisitCount(goods.id,goods.countVisits)">
                      <img class="d-block" src={{goods.imgPathTemp1}} alt="" width="100%" height="100%">
                     </a>
                  </div>
                  <div class="carousel-item"> 
                    <a  [routerLink]="['/news-component',goods.groupIdRef1,goods.groupId]" routerLinkActive="router-link" (click)="setVisitCount(goods.id,goods.countVisits)">
                      <img class="d-block" src={{goods.imgPathTemp2}} alt="" width="100%" height="100%">
                     </a>
                  </div>
                  <a class="carousel-control-prev" href="#CarouselTest-{{goods.id}}" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#CarouselTest-{{goods.id}}" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>

                  
                </div>
                <h5 class="card-text FS2 text-left">
                  <p>โพสต์เมื่อ : {{goods.modifyDate}} </p> 

                  <div class="f-gray fs13">เข้าชม : {{goods.countVisits}} ครั้ง</div>
                 </h5>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>
          <div *ngIf="role1">
            <br>
            <div>
              <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
              <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
            <br>
          </div>
        </div><br>
      </div>
    <br>

    <div class="bg_2_3">
       <div class="container">
          <div class="row">
            <div class="col-md-8 col-sm-8 btn-animated">
              <h2 class="f-white fs3 text-center">กิจกรรมอื่นๆ</h2>
              <div class="container">
                <div class="row">
                  <div class="col-xl-1 col-md-1"></div>
                  <div class="col-xl-10 col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
                  <div class="col-xl-1 col-md-1"></div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 btn-animated-"></div>
      </div>
    </div>
  </div>

  <div class="container card" >
    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
      <!-- <div class="carousel-indicators">
         <div>{{getDataForBlog(43,2,1,1)}}</div>
          <div  *ngFor="let goods of goodss">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          </div>
          <div>{{getDataForBlog(43,2,2,999)}}</div>
          <div  *ngFor="let goods of goodss">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to= "{{goods.count}}" aria-label="Slide " ></button>
          </div>

        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div> -->
      <div class="carousel-inner">
        <div>{{getDataForBlog(43,2,1,1)}}</div>
            <div class="carousel-item active" *ngFor="let goods of goodss">
                <div class="row" >
                  <div id="" class="col-md-1 px-3"></div>
                  <div id="fitst" class="col-md-10 px-3">
                    <div class="card-block px-6 ">
                      <h3 class="card-title- f-green fs5"><svg xmlns="" width="18" height="18" fill="currentColor"
                          class="bi bi-megaphone-fill" viewBox="0 1 16 16" color="f-green">
                          <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z" />
                        </svg> {{goods.namePoint}} 
                      </h3>
                        <h4 class="f-green f4 text-left">{{goods.desc1}}</h4>
                        <h5 class="f-gray f2 text-left">{{goods.desc2}}</h5>
                        <br><br>
                    </div>
                    <div>
                      <a  [routerLink]="['/news-component',goods.groupIdRef1]" routerLinkActive="router-link" (click)="setVisitCount(goods.id,goods.countVisits)">
                       <img class="d-block" src={{goods.imgPathTemp1}} alt="" width="18" height="18">
                      </a>
                    </div>
                    <h5 class="card-text- FS2 text-left">
                      <p>โพสต์เมื่อ : {{goods.modifyDate}} </p> 

                      <div class="f-gray fs13">เข้าชม : {{goods.countVisits}} ครั้ง</div>
                    </h5>
                  </div>
                  <div id="" class="col-md-1 px-3"></div>
                </div>
    
              <div *ngIf="role1">
                <br>
                <div class="container">
                  <div class="col-md-12 text-center">
                    <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>&nbsp;
                    <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger"> Delete</button>
                  </div>
                </div>
                <br>
              </div>
          </div>

        <div>{{getDataForBlog(43,2,2,999)}}</div>
          <div class="carousel-item" *ngFor="let goods of goodss"> 
           
              <div class="row">
               
                <div id="" class="col-md-1 px-3"></div>
                <div id="fitst" class="col-md-10 px-3">
                  <div class="card-block px-6 ">
                    <h3 class="card-title- f-green fs5"><svg xmlns="" width="18" height="18" fill="currentColor"
                        class="bi bi-megaphone-fill" viewBox="0 1 16 16" color="f-green">
                        <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z" />
                      </svg> {{goods.namePoint}} 
                    </h3>
                      <h4 class="f-green f4 text-left">{{goods.desc1}}</h4>
                      <h5 class="f-gray f2 text-left">{{goods.desc2}}</h5>
                      <br><br>
                  </div>
                  <div>
                    <a  [routerLink]="['/news-component',goods.groupIdRef1]" routerLinkActive="router-link" (click)="setVisitCount(goods.id,goods.countVisits)">
                      <img class="d-block" src={{goods.imgPathTemp1}} alt="" width="18" height="18">
                     </a>
                  </div>
                  <h5 class="card-text- FS2 text-left">
                    <p>โพสต์ล่าสุด : {{goods.modifyDate}} </p> 

                    <div class="f-gray fs13">เข้าชม : {{goods.countVisits}} ครั้ง</div>
                   </h5>
                </div>
                <div id="" class="col-md-1 px-3"></div>
    
              </div>
           
            <div *ngIf="role1">
              <br>
              <div class="container">
                <div class="col-md-12 text-center">
                  <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>&nbsp;
                  <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger"> Delete</button>
                </div>
              </div>
              <br>
            </div>
            
          </div>
    

      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <div *ngIf="role1">
      <div class="content">
        <button type="button" class="btn btn-primary" (click)="addGoods(43)">Add new (43)</button>
      </div>
    </div>



      </div>
    
     <div class="bg_5_3 margin_footer"></div>
    </div>
  </div>


    <!-- Creates the bootstrap modal where the image will appear -->
    <div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <form (ngSubmit)="onSubmit(eventsFlag)">
      <div class="modal-dialog" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable" role="document">
          <div class="modal-content">
            
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
              <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
            </div>
            <div class="modal-body">
    
              <tr>
                <td><label> Groups : {{BlogId}}</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupId"
                    name="groupId"></td>
              </tr>
              <tr>
                <td><label> Order No : </label></td>
                <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="goods.orderNo" name="orderNo"></td>
              </tr>
              <tr>
                <td><label> GroupsRef1</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef1"
                    name="groupIdRef1"></td>
              </tr>
              <tr>
                <td><label> GroupsRef2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef2"
                    name="groupIdRef2"></td>
              </tr>
              <tr>
                <td><label> GroupsRef3</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef3"
                    name="groupIdRef3"></td>
              </tr>
              <tr>
                <td><label> Href1</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href1" name="href1">
                </td>
              </tr>
              <tr>
                <td><label> Href2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href2" name="href2">
                </td>
              </tr>
              <tr>
                <td><label> Href3</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href3" name="href3">
                </td>
              </tr>
              <tr>
                <td><label> Name</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.namePoint"
                    name="namePoint"></td>
              </tr>
              <tr>
                <td><label> Path1</label></td>
                <td><input type="text" class="form-control control-size" id="imgPath1" [(ngModel)]="goods.imgPath1"
                    name="imgPath1"></td>
              </tr>
              <tr>
                <td>
                  <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                      (click)="upload('imgPath1')">Upload</button></div>
                </td>
                <td>
                  <div class="col-8">
                    <label class="btn btn-default p-0 control-size">
                      <input type="file" (change)="selectFile($event)" />
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td><label> Path2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.imgPath2"
                    name="imgPath2"></td>
              </tr>
              <tr>
                <td>
                  <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                      (click)="upload('imgPath2')">Upload</button></div>
                </td>
                <td>
    
    
                  <div class="col-8">
                    <label class="btn btn-default p-0 control-size">
                      <input type="file" (change)="selectFile($event)" />
                    </label>
                  </div>
    
    
                </td>
              </tr>
              <tr>
                <td><label> Desc1</label></td>
                <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc1"
                    name="desc1"></textarea></td>
              </tr>
              <tr>
                <td><label> Desc2</label></td>
                <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc2"
                    name="desc2"></textarea></td>
              </tr>
              <tr>
                <td><label> isEnable</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.isEnable" name="isEnable"></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div *ngIf="currentFile" class="progress my-3">
                    <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                      attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                      [ngStyle]="{ width: progress + '%' }">
                      {{ progress }}%
                    </div>
                  </div>
                </td>
              </tr>
            </div>
            <div class="modal-footer">
              <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
              <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
            </div>
         
          </div>
        </div>
      </div>
     </form>
    </div>
      <!-- end  Creates the bootstrap modal where the image will appear -->

</body>


</html>