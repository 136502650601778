// import { Component, OnInit } from '@angular/core';
// import { FormBuilder } from '@angular/forms';
// import { ActivatedRoute, Router } from '@angular/router';
// import { GoodsService } from 'src/app/app.service/goods.service';
// import { UploadFilesService } from 'src/app/app.service/upload-files.service';
// import { GlobalConstants } from '../../common/global-constants';

// @Component({
//   selector: 'app-product-and-services-detail',
//   templateUrl: './product-and-services-detail.component.html',
//   styleUrls: ['./product-and-services-detail.component.css']
// })
// export class ProductAndServicesDetailComponent implements OnInit {
//   productname:string="";
//   price:string="";
//   title:string="";
//   title1:string="";
//   Category:string="";
//   brand:string="";
//   id:string="";
//   look:string="";
//   details:string="";
//   property:string="";
//   property1:string="";
//   property2:string="";
//   item:string="";
//   ingredient:string="";

//   BlogId : number=0;

//   constructor(private goodsService: GoodsService,
//     private router: Router,private formBuilder: FormBuilder,private route: ActivatedRoute,private uploadService: UploadFilesService) {
//       //  this.role1=GlobalConstants.role1;
//        this.BlogId=this.route.snapshot.params['id'];
//     }

//   //this.route.snapshot.params['id'];
//   ngOnInit(): void {
//     this.setLanq(GlobalConstants.lanq);
//   }
//   setLanq(goodandservices:string){
//     { 
//       this.productname="ไอโอดีน วิตามิน Blackmores Pregnancy i folic folate โฟลิก โฟลิค 150เม็ด blackmore แบล็คมอร์ล";
//       this.price="฿350";
//       this.title="ข้อมูลจำเพาะของสินค้า";
//       this.title1="รายละเอียดสินค้า";
//       this.Category="อาหารเสริมเพิ่มน้ำหนัก";
//       this.brand="Blackmores(แบล็คมอร์)";
//       this.id="264926529849";
//       this.look="แคปซูล";
//       this.details="สารลูทีน (Lutein) ที่พบในดอกดาวเรืองจะมีหน้าที่ในการป้องกัน และชะลอโรคจอประสาทตาเสื่อม โดยเข้าไปดูแลบริเวณจุดรับภาพของดวงตา (Macula) อร์รี่มีคุณสมบัติสารต้านอนุมูลอิสระประสิทธิภาพสูง จึงสามารถปกป้องดวงตา โดยเฉพาะสารสำคัญที่ชื่อว่าแอนโธไซยาโนไซด์ (Anthocyanoside)” ซึ่งจัดเป็นสารประกอบโพลีฟีนอลในกลุ่มของฟลาโวนอยด์ (Flavonoids) และจัดเป็นสารตั้งต้นที่ทำให้เกิดสีน้ำเงินหรือม่วงในพืช โดยคุณสมบัติที่สำคัญที่สุดคือ การเป็นสารต้านอนุมูลอิสระ (Antioxidant) ที่จะช่วยในเรื่องของการปกป้องและดูแลสุขภาพดวงตา";
//       this.property="- ป้องกันโรคจอประสาทตาเสื่อม จากแสงสีฟ้า ที่ได้รับจากคอมพิวเตอร์   Tablet และ Smart Phone ต่างๆ";
//       this.property1="- ป้องกันดวงจากแสงแดด ทีเข้ามาทำร้ายจอประสาทตา";
//       this.property2="- ต่อต้านอนุมูลอิสระ  จะได้ลดการเสื่อมสภาพและถูกทำลาย ของจอประสาทตา ";
//       this.item="- รับประทานครั้งละ 1 แคปซูล วันละ 2 ครั้ง พร้อมอาหาร";
//       this.ingredient="- สารสกัดจากดอกบิวเบอร์รี่ 150 mg , สารสกัดจากดอกดาวเรือง 150 mg";
//     }

    
//  }
// }

import { Component, OnInit } from '@angular/core';
import { Goods } from '../../app.class/goods';
import { GoodsService } from '../../app.service/goods.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThisReceiver } from '@angular/compiler';
import { GlobalConstants } from '../../common/global-constants';
import { Observable } from 'rxjs';
import { filedata } from 'src/app/app.class/filedata';
import { UploadFilesService } from 'src/app/app.service/upload-files.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-product-and-services-detail',
  templateUrl: './product-and-services-detail.component.html',
  styleUrls: ['./product-and-services-detail.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})
export class ProductAndServicesDetailComponent implements OnInit {
  t_goodss: Goods[]=new Array();//ไว้สำหรับนำมาแสดง
  goodss: Goods[]=new Array();//ไว้สำหรับนำมาแสดง
  goods: Goods = new Goods();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข

  goodssL2: Goods[]=new Array();//ไว้สำหรับนำมาแสดง
  goodsL2: Goods = new Goods();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข

  detailLists: any[]=new Array();
  detailList: String="";

  id: number=0 ;
  isLoggedIn: boolean=true;
  eventsFlag : string ="A";
  eventsText : string="";
  role1 : boolean= false;
  BlogId : number=0;
  MainBlogId : number=0;
  goodandservices:string="";


  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  fileInfos: Observable<any> | undefined;
  t_fileInfos : filedata[] =new Array();
  file: filedata = new filedata();

  safeSrc: SafeResourceUrl;

  constructor(private goodsService: GoodsService,
    private router: Router,private formBuilder: FormBuilder,private route: ActivatedRoute,
    private uploadService: UploadFilesService,
    private tokenStorageService: TokenStorageService,private sanitizer: DomSanitizer) {
      if (this.tokenStorageService.checkUser())
      {
        this.role1 = !!this.tokenStorageService.getToken();
      }
      
      this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("");
    }
    ngOnInit(): void {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;//สั่ง reload ตัวเอง

      this.getGoods();
      this.setLanq(GlobalConstants.lanq);
      this.BlogId = this.route.snapshot.params['id'];
      this.MainBlogId = this.route.snapshot.params['id2'];

      
   }
   setLanq(goodandservices:string){
    { 
      this.goodandservices="สินค้าและบริการ";
    }
    
 }
 private getGoods(){
  this.goodsService.getGoodsList().subscribe(data => {
      this.t_goodss=data;
  });
}

getSrc(Src : string="") {
  this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(Src);
}

getDataForBlog(ind: number){
  let check : boolean =true;

  this.goodss.splice(0,this.goodss.length);//clear all
  this.t_goodss.forEach( (element : any) =>  {
    if (element.groupId==ind){//ถ้าเป็นกลุ่มสินค้าและบริการ
      if (check){
        check=false;
      }else{
        check=true;
      }
       element.isSwap=check;

       element.imgPathTemp1= GlobalConstants.httpPath + element.imgPath1;
       element.imgPathTemp2= GlobalConstants.httpPath + element.imgPath2;

       this.goodss.push(element);
    }
  });
}

getDataForBlogL2(ind: number){
  let check : boolean =true;

  this.goodssL2.splice(0,this.goodssL2.length);//clear all
  this.t_goodss.forEach( (element : any) =>  {
    if (element.groupId==ind){//ถ้าเป็นกลุ่มสินค้าและบริการ
      if (check){
        check=false;
      }else{
        check=true;
      }
       element.isSwap=check;

       element.imgPathTemp1= GlobalConstants.httpPath + element.imgPath1;
       element.imgPathTemp2= GlobalConstants.httpPath + element.imgPath2;

       this.goodssL2.push(element);
    }
  });
}


addGoods(blogId: number){
 this.goods = new Goods();
 this.goods.groupId=blogId;

 this.showModal = true; // Show-Hide Modal Check
 this.BlogId=blogId;
 this.eventsFlag="A";
 this.eventsText ="เพิ่มข้อมูล";
}

updateGoods(id: number,blogId: number){
  this.id=id;
  this.goodsService.getGoodsById(this.id).subscribe(data => {
    this.goods = data;
  }, error => console.log(error));
 this.showModal = true; // Show-Hide Modal Check
 this.BlogId=blogId;
 this.eventsFlag="E";
 this.eventsText ="แก้ไขข้อมูล";
}

deleteGoods(id: number,blogId: number,fileId1? : string,fileId2? : string ){
  let isConfirm : Boolean | any ;
  isConfirm=confirm("Are you sure to delete "+ id)

  if(isConfirm===false) {
    return;
  }

    this.goodsService.deleteGoods(id).subscribe( data => {
      console.log(data);
      this.getGoods();
  
      // this.deleteFiles(fileId1);
      // this.deleteFiles(fileId2);
    })
    this.BlogId=blogId;
    this.eventsFlag="D";
  
}

deleteFiles(id : string | any){
  this.uploadService.deleteFileById(id).subscribe( data => {
    console.log(data);
  })
}

//for Submit form when action
onSubmit(AddEditMode : string){

  if (AddEditMode=="A") {
    this.goodsService.createGoods(this.goods).subscribe( data =>{
      console.log(data);
       this.getGoods();//ย้อนกลับมาหน้าตัวเอง
    },
    error => console.log(error));
  }else if (AddEditMode=="E"){
    this.goodsService.updateGoods(this.id, this.goods).subscribe( data =>{
      
      console.log(data);
       this.getGoods();//ย้อนกลับมาหน้าตัวเอง
    }
    , error => console.log(error));
  }
   this.hide();
}

//for popup form
showModal: boolean | any;
registerForm: FormGroup | any;
submitted = false;

show()
 { 
   this.showModal = true;
 }

hide()
{
  this.showModal = false;
}

verifyUser(Us : boolean){
  GlobalConstants.role1=Us;
  this.role1=GlobalConstants.role1;
}

scrollToElement($element : any): void {
 console.log($element);
 $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
}



//for upload file
selectFile(event: any): void 
{

    this.selectedFiles = event.target.files;

    const files = event.target.files;
    for (let file of files) {
      GlobalConstants.partDirName=`${file.name}`;
    }

    }

    upload(ind : string): void {
    this.fileInfos = this.uploadService.getFiles();

    this.progress = 0;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      
      if (file) {
        this.currentFile = file;
        //  alert(this.currentFile);
        this.uploadService.upload(this.currentFile).subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              this.message = event.body.responseMessage;
              this.fileInfos = this.uploadService.getFiles(); 
              //this.fileInfos = this.uploadService.getFilesById(this.currentFile?.name);
            }
            this.fileInfos = this.uploadService.getFiles(); 
          // this.fileInfos = this.uploadService.getFilesById(this.currentFile?.name);
          },
          (err: any) => {
            console.log(err);
            this.progress = 0;

            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'Could not upload the file!';
            }
            this.currentFile = undefined;
          });
      }
      this.selectedFiles = undefined;
    }
     
    if(ind=="imgPath1"){
      this.goods.imgPath1 ="files/" +  GlobalConstants.partDirName;
     }else if(ind=="imgPath2"){
      this.goods.imgPath2 ="files/" +  GlobalConstants.partDirName;
     }
    
    // if(ind=="imgPath1"){
    //   this.goods.imgPath1 = GlobalConstants.httpPath + "files/" +  GlobalConstants.partDirName;
    //  }else if(ind=="imgPath2"){
    //   this.goods.imgPath2 = GlobalConstants.httpPath + "files/" +  GlobalConstants.partDirName;
    //  }

    }

    splitStringFormat(str: String){
      this.detailLists = str.split("^"); 
        if (this.detailLists.length > 1){
          this.detailLists.splice(0,1);
        }
      }

setVisitCount(id : number=0,isCount : number =0)
  {
    this.goods.countVisits=isCount +1;

    this.goodsService.updateVisits(id, this.goods).subscribe( data =>{

      // alert(this.goods.countVisits);
         
      console.log(data);
      //  this.getGoods();//ย้อนกลับมาหน้าตัวเอง
    }
    , error => console.log(error));
  }

}









