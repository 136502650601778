import { Component, OnInit } from '@angular/core';
import { Goods } from '../../app.class/goods';
import { GoodsService } from '../../app.service/goods.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalConstants } from '../../common/global-constants';
import { Observable } from 'rxjs';
import { filedata } from 'src/app/app.class/filedata';
import { UploadFilesService } from 'src/app/app.service/upload-files.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-quality-management',
  templateUrl: './quality-management.component.html',
  styleUrls: ['./quality-management.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})

export class QualityManagementComponent implements OnInit {
  t_goodss: Goods[]=new Array();//ไว้สำหรับนำมาแสดง
  goodss: Goods[]=new Array();//ไว้สำหรับนำมาแสดง
  goods: Goods = new Goods();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  id: number=0 ;
  isLoggedIn: boolean=true;
  eventsFlag : string ="A";
  eventsText : string="";
  role1 : boolean= false;
  BlogId : number=0;
  goodandservices:string="";
  material:string="";
  items:string="";
  items1:string="";
  items2:string="";
  items3:string="";
  items4:string="";
  items5:string="";
  title:string="";
  title1:string="";
  title2:string="";
  title3:string="";
  title4:string="";


  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  fileInfos: Observable<any> | undefined;
  t_fileInfos : filedata[] =new Array();
  file: filedata = new filedata();

  constructor(private goodsService: GoodsService,
    private router: Router,private formBuilder: FormBuilder,
    private route: ActivatedRoute,private uploadService: UploadFilesService,
    private tokenStorageService: TokenStorageService) {
      if (this.tokenStorageService.checkUser())
      {
        this.role1 = !!this.tokenStorageService.getToken();
      }
    }
    ngOnInit(): void {
      this.getGoods();
      this.setLanq(GlobalConstants.lanq);
   }
   setLanq(goodandservices:string){
    { 
      this.goodandservices="สินค้าและบริการ";
      this.material="ผลิตภัณฑ์และบริการของเครือเบทาโกรครอบคลุมตั้งแต่ต้นน้ำจนถึงปลายน้ำ เริ่มจากการผลิตอาหารสัตว์ และเวชภัณฑ์สำหรับสัตว์ ขยายไปสู่การผลิตและพัฒนาสายพันธุ์สัตว์ ฟาร์มเลี้ยงสัตว์ แปรรูปเนื้อสัตว์ การผลิตอาหาร จนถึงการบริการช่องทางการจัดจำหน่ายและการจัดจำหน่ายทั้งในประเทศและต่างประเทศ ผลิตภัณฑ์สำหรับสัตว์เลี้ยง และร้านอาหาร";
      this.items="ผลิตภัณฑ์สุกรมีชีวิต";
      this.items1="ผลิตภัณฑ์สุกรแปรรูป";
      this.items2="ผลิตภัณฑ์น้ำเชื้อสุกร";
      this.items3="ผลิตภัณฑ์อาหารสัตว์";
      this.items4="บริการรถขนส่งสินค้า";
      this.items5="ผลิตภัณฑ์ร้านอาหาร";
      this.title="ผลิตภัณฑ์และบริการอื่นๆ";
      this.title1="ผลิตภัณฑ์สำหรับสัตว์เลี้ยง";
      this.title2="ผลิตภัณฑ์อาหารเสริม";
      this.title3="ผลิตภัณฑ์สุขภาพสัตว์ ";
      this.title4="ผลิตภัณฑ์อาหารสัตว์";
    }
    
 }
 private getGoods(){
  this.goodsService.getGoodsList().subscribe(data => {
      this.t_goodss=data;
  });
}

getDataForBlog(ind: number){
  let check : boolean =true;

  this.goodss.splice(0,this.goodss.length);//clear all
  this.t_goodss.forEach( (element : any) =>  {
    if (element.groupId==ind){//ถ้าเป็นกลุ่มสินค้าและบริการ
      if (check){
        check=false;
      }else{
        check=true;
      }
       element.isSwap=check;

       element.imgPathTemp1= GlobalConstants.httpPath + element.imgPath1;
       element.imgPathTemp2= GlobalConstants.httpPath + element.imgPath2;

       this.goodss.push(element);
    }
  });
}


addGoods(blogId: number){
 this.goods = new Goods();
 this.goods.groupId=blogId;

 this.showModal = true; // Show-Hide Modal Check
 this.BlogId=blogId;
 this.eventsFlag="A";
 this.eventsText ="เพิ่มข้อมูล";
}

updateGoods(id: number,blogId: number){
  this.id=id;
  this.goodsService.getGoodsById(this.id).subscribe(data => {
    this.goods = data;
  }, error => console.log(error));
 this.showModal = true; // Show-Hide Modal Check
 this.BlogId=blogId;
 this.eventsFlag="E";
 this.eventsText ="แก้ไขข้อมูล";
}

deleteGoods(id: number,blogId: number,fileId1? : string,fileId2? : string ){
  let isConfirm : Boolean | any ;
  isConfirm=confirm("Are you sure to delete "+ id)

  if(isConfirm===false) {
    return;
  }

    this.goodsService.deleteGoods(id).subscribe( data => {
      console.log(data);
      this.getGoods();
  
      // this.deleteFiles(fileId1);
      // this.deleteFiles(fileId2);
    })
    this.BlogId=blogId;
    this.eventsFlag="D";
  
}

deleteFiles(id : string | any){
  this.uploadService.deleteFileById(id).subscribe( data => {
    console.log(data);
  })
}

//for Submit form when action
onSubmit(AddEditMode : string){

  if (AddEditMode=="A") {
    this.goodsService.createGoods(this.goods).subscribe( data =>{
      console.log(data);
       this.getGoods();//ย้อนกลับมาหน้าตัวเอง
    },
    error => console.log(error));
  }else if (AddEditMode=="E"){
    this.goodsService.updateGoods(this.id, this.goods).subscribe( data =>{
      
      console.log(data);
       this.getGoods();//ย้อนกลับมาหน้าตัวเอง
    }
    , error => console.log(error));
  }
   this.hide();
}

//for popup form
showModal: boolean | any;
registerForm: FormGroup | any;
submitted = false;

show()
 { 
   this.showModal = true;
 }

hide()
{
  this.showModal = false;
}

verifyUser(Us : boolean){
  GlobalConstants.role1=Us;
  this.role1=GlobalConstants.role1;
}
scrollToElement($element : any): void {
 console.log($element);
 $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
}


//for upload file
selectFile(event: any): void 
{

    this.selectedFiles = event.target.files;

    const files = event.target.files;
    for (let file of files) {
      GlobalConstants.partDirName=`${file.name}`;
    }

    }

    upload(ind : string): void {
    this.fileInfos = this.uploadService.getFiles();

    this.progress = 0;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      
      if (file) {
        this.currentFile = file;
        //  alert(this.currentFile);
        this.uploadService.upload(this.currentFile).subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              this.message = event.body.responseMessage;
              this.fileInfos = this.uploadService.getFiles(); 
              //this.fileInfos = this.uploadService.getFilesById(this.currentFile?.name);
            }
            this.fileInfos = this.uploadService.getFiles(); 
          // this.fileInfos = this.uploadService.getFilesById(this.currentFile?.name);
          },
          (err: any) => {
            console.log(err);
            this.progress = 0;

            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'Could not upload the file!';
            }
            this.currentFile = undefined;
          });
      }
      this.selectedFiles = undefined;
    }

    if(ind=="imgPath1"){
      this.goods.imgPath1 = "files/" +  GlobalConstants.partDirName.trim();
     }else if(ind=="imgPath2"){
      this.goods.imgPath2 =  "files/" +  GlobalConstants.partDirName.trim();
     }
    
    // if(ind=="imgPath1"){
    //   this.goods.imgPath1 = GlobalConstants.httpPath + "files/" +  GlobalConstants.partDirName;
    //  }else if(ind=="imgPath2"){
    //   this.goods.imgPath2 = GlobalConstants.httpPath + "files/" +  GlobalConstants.partDirName;
    //  }

    }
}







