<!DOCTYPE html>
<html lang="en">


<head>
    <meta charset="UTF-8">
    <meta name=viewport content="width=device-width, initial-scale=1">
    <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
    <title>Document</title>
</head>


<body class="ht">
  <div #back></div>
  <div id="bottom" (click)="scrollToElement(back)" >
    <a href="#1"><i class="fa fa-chevron-circle-up" style="font-size:50px;color:#3b9122"></i></a>
  </div>

<!-- <nav class="bg-dark navbar-dark">
</nav> -->
    <!-- <div class="container">
      <a href="" class="navbar-brand"><i class="fas fa-tree mr-2"></i>Forest</a>
    </div>
    </nav>
     <section id="header" class="jumbotron text-center">
       <h1 class="display-3">FOREST</h1>
       <p class="lead">Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
       <a href="" class="btn btn-primary">Click</a>
       <a href="" class="btn btn-secondary">Click</a>
     </section> -->
  <div class="ma2">

     <div>{{getDataForBlog(611)}}</div>
     <div *ngFor="let goods of goodss">
       <section id="sushi-header" style="background: url({{goods.imgPath1}}); background-repeat: no-repeat; background-size: cover;" >
         <div class="btn-animated" >
             <h1 style="color: #fff;">{{goods.namePoint}}</h1>
             <h5 style="color: #fff;">{{goods.desc1}}</h5>
         </div>
         </section>
         <div [style.display]="role1 ? 'block' : 'none'">
             <div class="container">
               <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
               <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
             </div>
           </div>
     </div>

    
   <div class="bg1">  
    <div class="container-fluid">
     <div class="row text-right">
       <div class="col-md-8 ">
         
         <br><br>
         <div>{{getDataFormId(GId)}}</div>
         <div class="columns1s" *ngFor="let goods of goodss">
           <div class="container">
              <div class="row">
               <div class="col-md-12 col-image text-left">
                 <b class="F5" id="block">{{goods.namePoint}}</b>

                 <div class="card">
                  <img src={{goods.imgPath1}} alt="" class="card-img-top">
                 
                </div>
               </div>
               <div class="col-md-2 "></div>
             </div>

             <div class="row" >
               <div class="col-md-12 text-left">
                 <div class="">
                   <br>
                    <p class="F6" id="block2">{{goods.desc1}}</p>
                    
                    <h5 class="card-text FS2">
                      <p>วันที่สร้าง : {{goods.createDate}} </p>
                      <p>ปรับปรุงล่าสุด : {{goods.modifyDate}} </p> 
                     </h5>
                 </div>
               </div>
               <div class="col-md-2 "></div>
             </div>

             <div class="row">
              <div class="col-md-12 col-image text-left">
                <div class="card">
                 <img src={{goods.imgPath2}} alt="" class="card-img-top">
               </div>
              </div>
              <div class="col-md-2 "></div>
            </div>
      
            <div class="row" >
              <div class="col-md-12 text-left">
                <div class="">
                  <br>
                   <p class="F6" id="block2">{{goods.desc2}}</p>
                  
                  <div [style.display]="role1 ? 'block' : 'none'">
                    <br>
                    <div>
                      <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                      <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                    </div>
                    <br>
                  </div>
                </div>
              </div>
              <div class="col-md-2 "></div>
            </div>


           </div>
     
          </div>
     
          <!-- <div class="container-fluid text-center">
           <a [routerLink]="['/article-component']" routerLinkActive="router-link"><h6 class="FS1">กลับสู่หน้าหลัก</h6></a>
          </div> -->
          
          <div [style.display]="role1 ? 'block' : 'none'">
           <br>
           <div class="content">
             <button type="button" class="btn btn-primary" (click)="addGoods(BlogId)">Add new ({{BlogId}})</button>
           </div>
           <br>
         </div>
       <br><br>


       </div>
       <div class="col-md-4 ">
        <br><br><br>
        <h3 class="text-left F4">บทความล่าสุด</h3>
        <section id="gallery">
          <div class="container">
            <div>{{getDataForBlog(62)}}</div>
            <div class="row" *ngFor="let goods of goodss">
              <div class="col-lg-3 mb-3" >
              <div class="card">
                <a class="" (click)="toBlog(goods.id)" [routerLink]="" routerLinkActive="router-link">
                <img src={{goods.imgPath1}} alt="" class="card-img-top zoom2 ">
              </a>
              </div>
              
        
              </div>
              <div class="col-lg-6 mb-6" >
                <a class="" (click)="toBlog(goods.id)" [routerLink]="" routerLinkActive="router-link">
                <div class="card-body text-left">
                  <h5 class="card-title F6">{{goods.namePoint}}</h5>
                <a class="btn btn-outline-success btn-sm" [routerLink]="['/article-item-component',goods.groupIdRef1]" routerLinkActive="router-link">อ่านเพิ่ม</a>
                <!-- <button type="button" (click)="toBlog(goods.groupIdRef1)" class="btn btn-info" style="margin-left: 10px"> อ่านเพิ่ม</button> -->
                </div>
                </a>
              </div>
           
            <div [style.display]="role1 ? 'block' : 'none'">
              <br>
                <div class="container">
                  <h5 class="F6"><p>order : {{goods.orderNo}} 
                    <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                    <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </p></h5>
                
                </div>
                <br>
            </div>
          </div>
          <div [style.display]="role1 ? 'block' : 'none'">
            <div class="container text-center">
              <button type="button" (click)="addGoods(62)" class="btn btn-primary" style="margin-left: 10px">Add new (62)</button>
            </div>
          </div>
        </div>
        </section>
         
       </div>
     </div>
    </div>
  </div>
</div>


   
  <!-- <section id="gallery">
    <div class="container">
      <div class="row">
      <div>{{getDataForBlog(62)}}</div>
      <div class="col-lg-4 mb-4" *ngFor="let goods of goodss">
      <div class="card">
        <img src={{goods.imgPath1}} alt="" class="card-img-top zoom2 ">
        <div class="card-body">
          <h5 class="card-title">{{goods.namePoint}}</h5>
          <p class="card-text">{{goods.desc1}}</p>
         
         <a class="btn btn-outline-success btn-sm" [routerLink]="['/article-item-component',goods.groupIdRef1]" routerLinkActive="router-link">อ่านเพิ่ม</a>
        </div>
       </div>
       <br>
        <div [style.display]="role1 ? 'block' : 'none'">
            <div class="container">
            <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
            <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
        </div>
       <br>
      </div>
      <div [style.display]="role1 ? 'block' : 'none'">
        <div class="container text-center">
          <button type="button" (click)="addGoods(62)" class="btn btn-primary" style="margin-left: 10px">Add new (62)</button>
        </div>
      </div>
    </div>
  </div>
  </section> -->
  

    <!-- Creates the bootstrap modal where the image will appear -->
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <form (ngSubmit)="onSubmit(eventsFlag)">
  <div class="modal-dialog" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
          <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
        </div>
        <div class="modal-body">

          <tr>
            <td><label> Groups : {{BlogId}}</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupId"
                name="groupId"></td>
          </tr>
          <tr>
            <td><label> Order No : </label></td>
            <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="goods.orderNo" name="orderNo"></td>
          </tr>
          <tr>
            <td><label> GroupsRef1</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef1"
                name="groupIdRef1"></td>
          </tr>
          <tr>
            <td><label> GroupsRef2</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef2"
                name="groupIdRef2"></td>
          </tr>
          <tr>
            <td><label> GroupsRef3</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef3"
                name="groupIdRef3"></td>
          </tr>
          <tr>
            <td><label> Href1</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href1" name="href1">
            </td>
          </tr>
          <tr>
            <td><label> Href2</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href2" name="href2">
            </td>
          </tr>
          <tr>
            <td><label> Href3</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href3" name="href3">
            </td>
          </tr>
          <tr>
            <td><label> Name</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.namePoint"
                name="namePoint"></td>
          </tr>
          <tr>
            <td><label> Path1</label></td>
            <td><input type="text" class="form-control control-size" id="imgPath1" [(ngModel)]="goods.imgPath1"
                name="imgPath1"></td>
          </tr>
          <tr>
            <td>
              <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                  (click)="upload('imgPath1')">Upload</button></div>
            </td>
            <td>
              <div class="col-8">
                <label class="btn btn-default p-0 control-size">
                  <input type="file" (change)="selectFile($event)" />
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td><label> Path2</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.imgPath2"
                name="imgPath2"></td>
          </tr>
          <tr>
            <td>
              <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                  (click)="upload('imgPath2')">Upload</button></div>
            </td>
            <td>


              <div class="col-8">
                <label class="btn btn-default p-0 control-size">
                  <input type="file" (change)="selectFile($event)" />
                </label>
              </div>


            </td>
          </tr>
          <tr>
            <td><label> Desc1</label></td>
            <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc1"
                name="desc1"></textarea></td>
          </tr>
          <tr>
            <td><label> Desc2</label></td>
            <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc2"
                name="desc2"></textarea></td>
          </tr>
          <tr>
            <td></td>
            <td>
              <div *ngIf="currentFile" class="progress my-3">
                <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                  attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                  [ngStyle]="{ width: progress + '%' }">
                  {{ progress }}%
                </div>
              </div>
            </td>
          </tr>
        </div>
        <div class="modal-footer">
          <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
          <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
        </div>
     
      </div>
    </div>
  </div>
 </form>
</div>
  <!-- end  Creates the bootstrap modal where the image will appear -->
</body>
</html>