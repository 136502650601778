<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>

<body>
  <div class="container-fluid">
      <table class = "table table-striped  table-bordered" >
        <thead>
            <tr class="d-flex text-center">
                <th class="col-1" style="text-align: center;"> Id</th>
                <th class="col-1" style="text-align: center;"> กลุ่ม</th>
                <th class="col-1" style="text-align: center;"> ลำดับ</th>
                <th class="col-1" style="text-align: center;"> กลุ่มย่อย1</th>
                <th class="col-1" style="text-align: center;"> กลุ่มย่อย2</th>
                <th class="col-1" style="text-align: center;"> ชื่อ</th>
                <th class="col-1" style="text-align: center;"> รูปภาพ 1 </th>
                <th class="col-1" style="text-align: center;"> รูปภาพ 1 </th>
                <th class="col-1" style="text-align: center;"> รูปภาพ 2</th>
                <th class="col-1" style="text-align: center;"> รูปภาพ 2</th>
                <th class="col-1" style="text-align: center;"> รายละเอียด 1</th>
                <th class="col-1" style="text-align: center;"> รายละเอียด 2</th>
                <th class="col-1" style="text-align: center;"> สถานะ</th>
                <th class="col-1" style="text-align: center;"> Actions </th>
            </tr>
        </thead>
        <tbody>
            <tr class="d-flex" *ngFor = "let goods of goodss" >
                <td class="col-1"> <textarea disabled=false class ="form-control text-center" rows="2" style="width: 100%" id = "note" [(ngModel)] = "goods.id" name = "id"></textarea></td>
                <td class="col-1"> <textarea disabled=false class ="form-control" rows="2" style="width: 100%" id = "note" [(ngModel)] = "goods.groupId" name = "groupId"></textarea></td>
                <td class="col-1"> <textarea disabled=false class ="form-control" rows="2" style="width: 100%" id = "note" [(ngModel)] = "goods.orderNo" name = "orderNo"></textarea></td>
                <td class="col-1"> <textarea disabled=false class ="form-control" rows="2" style="width: 100%" id = "note" [(ngModel)] = "goods.groupIdRef1" name = "groupIdRef1"></textarea></td>
                <td class="col-1"> <textarea disabled=false class ="form-control" rows="2" style="width: 100%" id = "note" [(ngModel)] = "goods.groupIdRef2" name = "groupIdRef2"></textarea></td>
                <td class="col-1"> <textarea disabled=false class ="form-control" rows="2" style="width: 100%" id = "note" [(ngModel)] = "goods.namePoint" name = "namePoint"></textarea></td>
                <td class="col-1"> <textarea disabled=false class ="form-control" rows="2" style="width: 100%" id = "note" [(ngModel)] = "goods.imgPath1" name = "imgPath1"></textarea></td>
                <td class="col-1"> <img  class="a" src={{httpPart}}{{goods.imgPath1}} style="width:100%;height:100%;"></td>
                <td class="col-1"> <textarea disabled=false class ="form-control" rows="2" style="width: 100%" id = "note" [(ngModel)] = "goods.imgPath2" name = "imgPath2"></textarea></td>
                <td class="col-1"> <img  class="a" src={{httpPart}}{{goods.imgPath2}} style="width:100%;height:100%;"></td>
                <td class="col-1"> <textarea disabled=false class ="form-control" rows="2" style="width: 100%" id = "note" [(ngModel)] = "goods.desc1" name = "desc1"></textarea></td>
                <td class="col-1"> <textarea disabled=false class ="form-control" rows="2" style="width: 100%" id = "note" [(ngModel)] = "goods.desc2" name = "desc2"></textarea></td>
                <td class="col-1"> <textarea disabled=false class ="form-control" rows="2" style="width: 100%" id = "note" [(ngModel)] = "goods.isEnable" name = "isEnable"></textarea></td>
                <td class="col-2">
                    <button (click) = "updateGoods(goods.id,goods.groupId)" class = "btn btn-info text-center"> Update</button> &nbsp;
                    <button (click) = "deleteGoods(goods.id,goods.groupId)" class = "btn btn-danger text-center"> Delete</button>
                </td>
            </tr>
            <tr class="d-flex">
              <td class="col-1"></td>
              <td class="col-1"></td>
              <td class="col-1"></td>
              <td class="col-1"></td>
              <td class="col-1"></td>
              <td class="col-1"></td>
              <td class="col-1"></td>
              <td class="col-1"></td>
              <td class="col-1"></td>
              <td class="col-1"></td>
              <td class="col-2">
                <button type="button" (click)="addGoods(0)"class="btn btn-primary text-center">Add new</button>
              </td>

              
            </tr>
        </tbody>
    </table>
  </div>
  
<!-- Creates the bootstrap modal where the image will appear -->
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <form (ngSubmit)="onSubmit(eventsFlag)">
  <div class="modal-dialog" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
          <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
        </div>
        <div class="modal-body">
          <tr>
            <td><label> Id :</label></td>
            <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="goods.id" name="id" disabled></td>
          </tr>

          <tr>
            <td><label> Groups : {{BlogId}}</label></td>
            <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="goods.groupId" name="groupId"></td>
          </tr>
          <tr>
           <td><label> Order No : </label></td>
           <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="goods.orderNo" name="orderNo"></td>
         </tr>
          <tr>
           <td><label> GroupsRef1</label></td>
           <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="goods.groupIdRef1" name="groupIdRef1"></td>
         </tr>
         <tr>
           <td><label> GroupsRef2</label></td>
           <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef2" name="groupIdRef2"></td>
         </tr>
         <tr>
           <td><label> GroupsRef3</label></td>
           <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef3" name="groupIdRef3"></td>
         </tr>
         <tr>
           <td><label> Href1</label></td>
           <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href1" name="href1"></td>
         </tr>
         <tr>
           <td><label> Href2</label></td>
           <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href2" name="href2"></td>
         </tr>
         <tr>
           <td><label> Href3</label></td>
           <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href3" name="href3"></td>
         </tr>
         <tr>
           <td><label> Name</label></td>
           <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.namePoint" name="namePoint"></td>
         </tr>
         <tr>
           <td><label> Path1</label></td>
           <td><input type="text" class="form-control control-size" id="imgPath1"  [(ngModel)]="goods.imgPath1" name="imgPath1" ></td>
         </tr>
         <tr>
           <td><div class="col-4"><button  class="btn btn-success btn-sm" [disabled]="!selectedFiles" (click)="upload('imgPath1')">Upload</button></div></td>
           <td>
               <div class="col-8">
                 <label class="btn btn-default p-0 control-size">
                   <input type="file" (change)="selectFile($event)" />
                 </label>
               </div>
         </td>
         </tr>
         <tr>
           <td><label> Path2</label></td>
           <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.imgPath2" name="imgPath2"></td>
         </tr>
         <tr>
           <td><div class="col-4"><button  class="btn btn-success btn-sm" [disabled]="!selectedFiles" (click)="upload('imgPath2')">Upload</button></div></td>
           <td>
             
   
               <div class="col-8">
                 <label class="btn btn-default p-0 control-size">
                   <input type="file" (change)="selectFile($event)" />
                 </label>
               </div>
   
               
         </td>
         </tr>
         <tr>
           <td><label> Desc1</label></td>
           <td><textarea class ="form-control control-size" rows="2"  id = "note" [(ngModel)] = "goods.desc1" name = "desc1"></textarea></td>
         </tr>
         <tr>
           <td><label> Desc2</label></td>
           <td><textarea class ="form-control control-size" rows="2"  id = "note" [(ngModel)] = "goods.desc2" name = "desc2"></textarea></td>
         </tr>
         <tr>
          <td><label> isEnable</label></td>
          <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.isEnable" name="isEnable"></td>
        </tr>
         <tr>
         <td></td>
          <td>
           <div *ngIf="currentFile" class="progress my-3">
             <div
               class="progress-bar progress-bar-info progress-bar-striped"
               role="progressbar"
               attr.aria-valuenow="{{ progress }}"
               aria-valuemin="0"
               aria-valuemax="100"
               [ngStyle]="{ width: progress + '%' }"
             >
               {{ progress }}%
             </div>
           </div>
          </td>
         </tr>
        </div>
        <div class="modal-footer">
          <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
          <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
        </div>
     
      </div>
    </div>
  </div>
 </form>
</div>
  <!-- end  Creates the bootstrap modal where the image will appear -->
</body>
</html>