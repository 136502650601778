import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from '../../common/global-constants';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  Username : string | any;
  Password : string | any;
  Logout : string | any;

  logoPath : string ="";

  constructor(private route: ActivatedRoute,private router: Router) { 
    this.logout();
  }

  ngOnInit(): void {
    this.logoPath=GlobalConstants.httpPath + "files/logo.png";
  }

  login() {
    if (this.Username=="admin" && this.Password =="53455345"){
      GlobalConstants.role1 = true;
      GlobalConstants.role2 = true;
      alert ("เข้าระบบสำเร็จ");
      this.router.navigate(['app-mainpage']);
    }else if (this.Username=="user" && this.Password =="321123"){
      GlobalConstants.role2 = true;
      alert ("เข้าระบบสำเร็จ");
      this.router.navigate(['app-mainpage']);
    }else{
      alert ("เข้าระบบไม่สำเร็จ");
    }

  }
  logout(){
    this.Logout=this.route.snapshot.params['id'];
    if (this.Logout=="Logout"){
      GlobalConstants.role1 = false;
      alert ("Logout success");
      this.router.navigate(['app-mainpage']);
    }
  }

}
