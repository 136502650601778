<!DOCTYPE html>
<html lang="en">


<head>
    <meta charset="UTF-8">
    <meta name=viewport content="width=device-width, initial-scale=1">
    <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
    <title>Document</title>
</head>

<body class="ht">
  <div #back></div>
  <div id="bottom" (click)="scrollToElement(back)" >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
  </div>


  <!--<div class="bg_2_1">
    <div class="bg_1_2">
    <div>{{getDataForBlog(411)}}</div>
     <div *ngFor="let goods of goodss">
      <br><br><br><br>
      <div class="btn-animated">
        <h1 class="F1 fs1 text-center" >{{goods.namePoint}}</h1>
      </div>

        <div *ngIf="role1">
            <div class="container">
              <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
              <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
        </div>
    </div>


    <br><br> 
    <div class="bg_1_3">
    <br><br><br><br><br><br><br>
    
    <div class="container content-text">
        <div>{{getDataForBlog(411)}}</div>
        <div *ngFor="let goods of goodss">
        
        <h5 class="F2 fs11">{{goods.desc1}}</h5>
        <div><img class="mtp_underline" src="assets\img\underline\underline.png"> </div>

        <div *ngIf="role1">
          <div>
            <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
            <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
          </div>
        </div>
      </div>
    </div>

    <br>
  </div>
</div>
</div>-->


    <!-- <div>{{getDataForBlog(411)}}</div>
    <div *ngFor="let goods of goodss">
      <section id="sushi-header" style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
        <div class="btn-animated" >
            <h1 class="F1">{{goods.namePoint}}</h1>
            <h5 class="F2">{{goods.desc1}}</h5>
        </div>
        </section>
        <div *ngIf="role1">
            <div class="container">
              <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
              <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
          </div>
    </div> -->

  <!-- <div class="container">
    <div class="row">
        <div class="col-md-12">
            <div id="custCarousel" class="carousel slide" data-ride="carousel" align="center">
            
                <div class="carousel-inner">
                    <div class="carousel-item active"> <img src="http://localhost:8081/api/v1/files/IMG_5830.JPG" alt="Hills"> </div>
                    <div class="carousel-item"> <img src="http://localhost:8081/api/v1/files/IMG_5764.JPG" alt="Hills"> </div>
                    <div class="carousel-item"> <img src="https://i.imgur.com/83fandJ.jpg" alt="Hills"> </div>
                    <div class="carousel-item"> <img src="https://i.imgur.com/JiQ9Ppv.jpg" alt="Hills"> </div>
                </div>  <a class="carousel-control-prev" href="#custCarousel" data-slide="prev"> <span class="carousel-control-prev-icon"></span> </a> <a class="carousel-control-next" href="#custCarousel" data-slide="next"> <span class="carousel-control-next-icon"></span> </a> 
                <ol class="carousel-indicators list-inline">
                    <li class="list-inline-item active"> <a id="carousel-selector-0" class="selected" data-slide-to="0" data-target="#custCarousel"> <img src="http://localhost:8081/api/v1/files/IMG_5830.JPG" class="img-fluid"> </a> </li>
                    <li class="list-inline-item"> <a id="carousel-selector-1" data-slide-to="1" data-target="#custCarousel"> <img src="http://localhost:8081/api/v1/files/IMG_5764.JPG" class="img-fluid"> </a> </li>
                    <li class="list-inline-item"> <a id="carousel-selector-2" data-slide-to="2" data-target="#custCarousel"> <img src="https://i.imgur.com/83fandJ.jpg" class="img-fluid"> </a> </li>
                    <li class="list-inline-item"> <a id="carousel-selector-2" data-slide-to="3" data-target="#custCarousel"> <img src="https://i.imgur.com/JiQ9Ppv.jpg" class="img-fluid"> </a> </li>
                </ol>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="container">
  <div class="row">
      <div class="col-md-12">
          <div id="custCarousel" class="carousel slide" data-ride="carousel" align="center">
           
            
              <div class="carousel-inner">
                <div>{{getDataForBlog2(BlogId)}}</div>
                <div *ngFor="let goods of goodss">
                <div *ngIf="goods.isFirst;then content else other_content">here is ignored</div>   
                <ng-template #content>
                  <div class="carousel-item active"> <img src={{goods.imgPath1}} alt="Hills"> </div>
                </ng-template>
                <ng-template #other_content>
                  <div class="carousel-item"> <img src={{goods.imgPath1}} alt="Hills"> </div>
                </ng-template>
                </div>
              </div> 

               

              <ol class="carousel-indicators list-inline">
                <div>{{getDataForBlog(BlogId)}}</div>
                <div *ngFor="let goods of goodss">
                  <div *ngIf="goods.isFirst;then content else other_content">here is ignored</div>   
                  <ng-template #content>
                    <li class="list-inline-item active"> <a id={{goods.txtSelector}} class="selected" data-slide-to="0" data-target="#custCarousel"> <img src={{goods.imgPath1}} class="img-fluid"> </a> </li>
                  </ng-template>
                  <ng-template #other_content>
                    <li class="list-inline-item"> <a id={{goods.txtSelector}} data-slide-to="1" data-target="#custCarousel"> <img src={{goods.imgPath1}} class="img-fluid"> </a> </li>
                  </ng-template>
                </div>
              </ol>

              <a class="carousel-control-prev" href="#custCarousel" data-slide="prev"> <span class="carousel-control-prev-icon"></span> </a>
               <a class="carousel-control-next" href="#custCarousel" data-slide="next"> <span class="carousel-control-next-icon"></span> </a> 
           </div>
        </div>
      
  </div>
</div> -->


<!-- <div>{{getDataForBlog(BlogId)}}</div>
<div *ngFor="let goods of goodss">
<div *ngIf="goods.isFirst;then content else other_content">here is ignored</div>    
<ng-template #content>
  <div class="carousel-item col-md-4 active"  >
      <div class="cards-wrapper">
          <div class="card">
          <img src={{goods.imgPath1}} class="card-img-top"
            alt="Title">
          <div class="card-body">
            <h5 class="card-title text-center"><a href={{goods.href1}}><i>{{goods.desc1}}</i> </a></h5>
            <hr width="50%" color="red">
          </div>
        </div>
      </div> 
      <div *ngIf="role1">
        <div class="container">
          <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
          <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
        </div>
      </div>
  </div>
</ng-template>

<ng-template #other_content>
  <div class="carousel-item col-md-4"  >
      <div class="cards-wrapper">
          <div class="card">
          <img src={{goods.imgPath1}} class="card-img-top"
            alt="Title">
          <div class="card-body">
            <h5 class="card-title text-center"><a href={{goods.href1}}><i>{{goods.desc1}}</i> </a></h5>
            <hr width="50%" color="red">
          </div>
        </div>
      </div> 
      <div *ngIf="role1">
        <div class="container">
          <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
          <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
        </div>
      </div>
  </div>
</ng-template>
</div> -->

<!-- <div class="bg_1_1">   
  <br><br>
    <div>{{getDataForBlog(BlogId2)}}</div>
    <div class="columns1a" *ngFor="let goods of goodss">
      <div class="bg_3_3">
      <div class="container">

        <div class="row bg3" >
          <div class="col-md-12">
            <p class="F6 fs11 text-left" id="block2">{{goods.desc1}}</p>
            <div class="card text-center" >
              <img class="card-img-full" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
            </div>

            <br>
          </div>
          
        </div>
         
        <div *ngIf="role1">
          <br>
          <div>
            <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
            <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
          </div>
          <br>
        </div>
  
      </div>

    </div>
   <br>
  </div>

  <div class="container text-center">
    <a [routerLink]="['/newsandevents-component']" routerLinkActive="router-link"><h6 class="FS1">กลับสู่หน้าหลัก</h6></a>
  </div>

  <div *ngIf="role1">
    <br>
    <div class="content">
      <button type="button" class="btn btn-primary" (click)="addGoods(BlogId)">Add new ({{BlogId2}})</button>
    </div>
    <br>
  </div>
</div> -->


<div class="bg_1_1">   
  <div class="bg_3_2">
    <div class="bg_2_3" >
      <div class="container">
        <div class="row">
          <div class="col-md-9 col-sm-9 btn-animated">
          <br>
          <h1 class="f-white fs3 text-center">{{name}}</h1>
          <h5 class="f-white fs2 text-center">{{desc2}}</h5>
          <div class="container">
            <div class="row">
              <div class="col-xl-1 col-md-1"></div>
              <div class="col-xl-10 col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
              <div class="col-xl-1 col-md-1"></div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-sm-2 btn-animated-"></div>
        <div class="col-md-1 col-sm-1 btn-animated">
          <div class="container text-center">
            <a [routerLink]="['/newsandevents-component']" routerLinkActive="router-link"><h6 class="fs3 f-red">X</h6></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
   
      <div>{{getDataForBlog(BlogId)}}</div>
      <div class="columns1a" *ngFor="let goods of goodss">
        <div class="bg_3_3-">
        <div class="container">

          
          <!-- <div class="row bg3" >
            <div id="first" class="col-md-6">
              <div class="card2 text-center" >
                <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
              </div>
              <br>
            </div>
            <div id="second"  class="col-md-6">
              <div class="content-text"><br>
                <b class="F5 fs5" id="block">{{goods.namePoint}}</b>
                <p class="F6 fs6" id="block2">{{goods.desc1}}</p>
              </div>
            </div>
          </div> -->

          <div class="row bg3" >
            <div class="col-md-12">
              <p class="F6 fs11 text-left" id="block2">{{goods.desc1}}</p>
              <div class="card text-center" >
                <img class="card-img-full" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
              </div>

              <!-- <button class="container btn btn-block btn-primary" style="width: 20%"><i class="fa fa-thumbs-up"> ถูกใจ</i> </button> -->
              <br>
            </div>
            
          </div>
           
          <div *ngIf="role1">
            <br>
            <div>
              <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
              <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
            <br>
          </div>
    

            <!-- <div class="row bg3">
            <div class="col-md-1 "></div>
            
            <div class="card2 col-md-10 col-image">
              <p class="F2 fs11 text-center" id="block2">{{goods.desc1}}</p>
              <img class="img2" src={{goods.imgPathTemp1}} >
            </div>
            <div class="col-md-1 "></div>
          </div> -->
        </div>

        <!-- <div class="container">
          <div class="row" >
            <div class="col-md-1 "></div>
            <div class="col-md-10 ">
              <div class="Aligner-item text-center">
                <br><br><br>
                <b class="F5" id="block">{{goods.namePoint}}</b>
                <p class="F6" id="block2">{{goods.desc2}}</p>
                <p class="F6" id="block2">{{goods.desc2}}</p>

                
                <div *ngIf="role1">
                  <br>
                  <div>
                    <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                    <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                  <br>
                </div>

              </div>
            </div>
            <div class="col-md-1 "></div>
          </div>
        </div> -->
        

      </div>
     <br>
    </div>

   

    <div *ngIf="role1">
      <br>
      <div class="content">
        <button type="button" class="btn btn-primary" (click)="addGoods(BlogId)">Add new ({{BlogId}})</button>
      </div>
      <br>
    </div>
    <div class="bg_4_3-">
      <div class="container text-center">
        <a [routerLink]="['/newsandevents-component']" routerLinkActive="router-link"><h6 class="fs3 f-red">X</h6></a>
      </div>
    </div>

    

  </div>
</div>

    <!-- Creates the bootstrap modal where the image will appear -->
    <div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <form (ngSubmit)="onSubmit(eventsFlag)">
      <div class="modal-dialog" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable" role="document">
          <div class="modal-content">
            
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
              <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
            </div>
            <div class="modal-body">
    
              <tr>
                <td><label> Groups : {{BlogId}}</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupId"
                    name="groupId"></td>
              </tr>
              <tr>
                <td><label> Order No : </label></td>
                <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="goods.orderNo" name="orderNo"></td>
              </tr>
              <tr>
                <td><label> GroupsRef1</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef1"
                    name="groupIdRef1"></td>
              </tr>
              <tr>
                <td><label> GroupsRef2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef2"
                    name="groupIdRef2"></td>
              </tr>
              <tr>
                <td><label> GroupsRef3</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef3"
                    name="groupIdRef3"></td>
              </tr>
              <tr>
                <td><label> Href1</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href1" name="href1">
                </td>
              </tr>
              <tr>
                <td><label> Href2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href2" name="href2">
                </td>
              </tr>
              <tr>
                <td><label> Href3</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href3" name="href3">
                </td>
              </tr>
              <tr>
                <td><label> Name</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.namePoint"
                    name="namePoint"></td>
              </tr>
              <tr>
                <td><label> Path1</label></td>
                <td><input type="text" class="form-control control-size" id="imgPath1" [(ngModel)]="goods.imgPath1"
                    name="imgPath1"></td>
              </tr>
              <tr>
                <td>
                  <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                      (click)="upload('imgPath1')">Upload</button></div>
                </td>
                <td>
                  <div class="col-8">
                    <label class="btn btn-default p-0 control-size">
                      <input type="file" (change)="selectFile($event)" />
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td><label> Path2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.imgPath2"
                    name="imgPath2"></td>
              </tr>
              <tr>
                <td>
                  <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                      (click)="upload('imgPath2')">Upload</button></div>
                </td>
                <td>
    
    
                  <div class="col-8">
                    <label class="btn btn-default p-0 control-size">
                      <input type="file" (change)="selectFile($event)" />
                    </label>
                  </div>
    
    
                </td>
              </tr>
              <tr>
                <td><label> Desc1</label></td>
                <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc1"
                    name="desc1"></textarea></td>
              </tr>
              <tr>
                <td><label> Desc2</label></td>
                <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc2"
                    name="desc2"></textarea></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div *ngIf="currentFile" class="progress my-3">
                    <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                      attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                      [ngStyle]="{ width: progress + '%' }">
                      {{ progress }}%
                    </div>
                  </div>
                </td>
              </tr>
            </div>
            <div class="modal-footer">
              <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
              <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
            </div>
         
          </div>
        </div>
      </div>
     </form>
    </div>
      <!-- end  Creates the bootstrap modal where the image will appear -->

</body>
</html>