export class Contact {
    id: number =0;
    groupId: number=0;
    firstName: string ="";
    lastName: string ="";
    emailAddr: string ="";
    tel: string ="";
    addr: string ="";
    groupPeple: number =0;
    groupPepleDetail: string ="";
    agency: string ="";
    note: string ="";
    createDate: string="";
    
    q1yn:string="";
    q2yn:string="";
    q3yn:string="";
    q4yn:string="";
    q5yn:string="";

    q61yn:boolean=false;
    q62yn:boolean=false;
    q63yn:boolean=false;
    q64yn:boolean=false;
    q65yn:boolean=false;
    q66yn:boolean=false;

    q61ynB:boolean=false;
    q62ynB:boolean=false;
    q63ynB:boolean=false;
    q64ynB:boolean=false;
    q65ynB:boolean=false;
    q66ynB:boolean=false;

    countVisits: number=0;
}