<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
  <script src="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"></script>
  <script src="//code.jquery.com/jquery-1.11.1.min.js"></script>
  <!------ Include the above in your HEAD tag ---------->

  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />

  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">



</head>

<body class="ht">

  <div #back></div>
  <div id="bottom" (click)="scrollToElement(back)" >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
  </div>


  <div class="bg_1_1">
    <div class="bg_1_2">
    <div>{{getDataForBlog(311)}}</div>
    <div *ngFor="let goods of goodss">
      <!-- <section id="sushi-header" style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
      </section> -->
      
      <br><br><br><br>
      <div class="btn-animated">
        <h1 class="F1 fs1" >{{goods.namePoint}}</h1><br><br>
        <h5 class="F2 fs2" >{{goods.desc1}}</h5>
      </div>

        <div *ngIf="role1">
            <div class="container">
              <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
              <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
          </div>
    </div>


    <!-- <br><br><br><br><br><br><br> -->
    <div class="bg_1_3">
    <!-- <br><br><br><br><br><br><br><br><br> -->
    <!-- <section id="about" class="about-"> -->
    
    <div class="container content-text">
        <div>{{getDataForBlog(215)}}</div>
        <div *ngFor="let goods of goodss">
        <h1 class="F3 fs3">{{goods.namePoint}}</h1>
        <div><img class="mtp_underline" src="assets\img\underline\underline.png"> </div>
        <h5 class="F2 fs11">{{goods.desc1}}</h5>

        <div *ngIf="role1">
          <div>
            <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
            <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
          </div>
        </div>
      </div>
    </div>

    <br>
  </div>
</div>
</div>



    
    <!-- <div #target>Your target</div> -->

    <!-- <div>{{getDataForBlog(311)}}</div>
    <div *ngFor="let goods of goodss">
      <section id="sushi-header" style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
        
      </section>
        <br><br>
        <div class="btn-animated" >
          <h1 class="F1 fs1">{{goods.namePoint}}</h1><br>
          <h5 class="F2 fs2">{{goods.desc1}}</h5>
      </div>
        <div *ngIf="role1">
            <div class="container">
              <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
              <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
          </div>
    </div> -->
    <br><br>
    <div class="container">
      <div id="carouselExample" class="carousel slide" data-ride="carousel" data-interval="9000">
          <div class="carousel-inner row w-10 mxauto" role="listbox">
              <div class="carousel-item col-md-4 active" >
                  <div class="cards-wrapper">
                      <div class="card">
                      <img src="http://mittraphapservice.dyndns.info:8081/api/v1/files/pig2.jpg" class="card-img-top"
                        alt="Title">
                      <div class="card-body">
                        <h5 class="card-title"><a  href="#ref1" ><i>{{items}}</i> 
                          <hr width="50%" color="red">
                        </a></h5>
                      </div>
                    </div>
                  </div> 
                  
              </div>
              <!-- <div class="carousel-item col-md-4">
                <div class="cards-wrapper">
                  <div class="card">
                  <img src="https://www.thaihealth.or.th/data/content/2020/04/51900/cms/newscms_thaihealth_c_dejkmqstw347.jpg" class="card-img-top"
                    alt="Title">
                  <div class="card-body">
                    <h5 class="card-title"><a  href="#ref2" ><i>{{items1}}</i> 
                      <hr width="50%" color="red">
                    </a></h5>
                  </div>
                  </div>
                </div> 
              </div> -->
              <div class="carousel-item col-md-4">
                <div class="cards-wrapper">
                  <div class="card">
                  <img src="http://mittraphapservice.dyndns.info:8081/api/v1/files/semen3.jpg" class="card-img-top"
                    alt="Title">
                  <div class="card-body">
                    <h5 class="card-title"><a  href="#ref3" ><i>{{items2}}</i> 
                      <hr width="50%" color="red">
                    </a></h5>
                  </div>
                  </div>
                </div> 
              </div>
              <div class="carousel-item col-md-4">
                <div class="cards-wrapper">
                  <div class="card">
                  <img src="http://mittraphapservice.dyndns.info:8081/api/v1/files/food.jpg" class="card-img-top"
                    alt="Title">
                  <div class="card-body">
                    <h5 class="card-title"><a  href="#ref4" ><i>{{items3}}</i> 
                      <hr width="50%" color="red">
                    </a></h5>
                  </div>
                  </div>
                </div> 
              </div>
              <div class="carousel-item col-md-4">
                <div class="cards-wrapper">
                  <div class="card">
                  <img src="http://mittraphapservice.dyndns.info:8081/api/v1/files/truck2.jpg" class="card-img-top"
                    alt="Title">
                  <div class="card-body">
                    <h5 class="card-title"><a  href="#ref5" ><i>{{items4}}</i> 
                      <hr width="50%" color="red">
                    </a></h5>
                  </div>
                  </div>
                </div> 
              </div>
             
          </div>
          <a class="carousel-control-prev" id="carousel-control-prev" href="#carouselExample" role="button"
            data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" id="carousel-control-next" href="#carouselExample" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
    
      </div>
    </div>
    <br><br>

    <br><hr width="30%" color="yellow">
    <!-- <button (click)="scrollToElement(target)"></button> -->

  <!-- <div class="container">
    <div id="carouselExample" class="carousel slide" data-ride="carousel" data-interval="9000">
        <div class="carousel-inner row w-10 mxauto" role="listbox">
          <div>{{getDataForBlog(321)}}</div>
            <div class="carousel-item col-md-4 active" *ngFor="let goods of goodss" >
                <div class="cards-wrapper">
                    <div class="card">
                    <img src={{goods.imgPath1}} class="card-img-top"
                      alt="Title">
                    <div class="card-body">
                      <h5 class="card-title text-center"><a href={{goods.href1}}><i>{{goods.desc1}}</i> </a></h5>
                      <hr width="50%" color="red">
                    </div>
                  </div>
                </div> 
                <div *ngIf="role1">
                  <div class="container">
                    <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                    <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                </div>

            </div>

            <div>{{getDataForBlog(322)}}</div>
            <div class="carousel-item col-md-4" *ngFor="let goods of goodss">
              <div class="cards-wrapper">
                <div class="card">
                  <img src={{goods.imgPath1}} class="card-img-top"
                    alt="Title">
                  <div class="card-body">
                    <h5 class="card-title text-center"><a href={{goods.href1}}><i>{{goods.desc1}}</i> </a></h5>
                    <hr width="50%" color="red">
                  </div>
                </div>
              </div> 
              <div *ngIf="role1">
                <div class="container">
                  <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                  <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
              </div>

            </div>
            <div>{{getDataForBlog(323)}}</div>
            <div class="carousel-item col-md-4" *ngFor="let goods of goodss">
              <div class="cards-wrapper">
                <div class="card">
                  <img src={{goods.imgPath1}} class="card-img-top"
                    alt="Title">
                  <div class="card-body">
                    <h5 class="card-title text-center"><a href={{goods.href1}}><i>{{goods.desc1}}</i> </a></h5>
                    <hr width="50%" color="red">
                  </div>
                </div>
              </div> 
              <div *ngIf="role1">
                <div class="container">
                  <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                  <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
              </div>

            </div>
            <div>{{getDataForBlog(324)}}</div>
            <div class="carousel-item col-md-4" *ngFor="let goods of goodss">
              <div class="cards-wrapper">
                <div class="card">
                  <img src={{goods.imgPath1}} class="card-img-top"
                    alt="Title">
                  <div class="card-body">
                    <h5 class="card-title text-center"><a href={{goods.href1}}><i>{{goods.desc1}}</i> </a></h5>
                    <hr width="50%" color="red">
                  </div>
                </div>
              </div> 
              <div *ngIf="role1">
                <div class="container">
                  <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                  <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
              </div>

            </div>
           
           
        </div>
        <a class="carousel-control-prev" id="carousel-control-prev" href="#carouselExample" role="button"
        data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" id="carousel-control-next" href="#carouselExample" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>

    <div *ngIf="role1">
      <div class="content">
        <button type="button" class="btn btn-primary" (click)="addGoods(32)">Add new (32..)</button>
      </div>
    </div>
  
    </div>
  </div> -->
  <div id="ref1" class="id-top"></div>
 
  
    <div class="container" id="myHeader-">
      <div class="bg1--">
      <br>
      <div>{{getDataForBlog(321)}}</div>
      <div *ngFor="let goods of goodss">
        <h1 class="text-center F3 fs3">{{goods.namePoint}}</h1>
        <h5 class="text-center F4 fs4">{{goods.desc1}}</h5>
        <br><hr width="70%" color="red"><br>
        <div *ngIf="role1">
          <div>
            <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
            <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
          </div>
        </div>
      </div>

      <div>{{getDataForBlog(33)}}</div>
      <div *ngFor="let goods of goodss">
        <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>    
        <ng-template #content>
          <div class="row" >
            <div id="first" class="col-md-6" >
              <a [routerLink]="['/product-and-services-detail-component',goods.groupIdRef1,goods.groupId]" routerLinkActive="router-link">
                <div class="card2 text-center" >
                  <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
                </div>
              <!-- <img src={{goods.imgPath1}} class="card-img-1 " alt="Title5" id="block1" width="200" height="210" > -->
              </a>
            </div>
            <div id="second"  class="col-md-6">
              <div class="content-text">
                <b id="block"  class="F3 fs3">{{goods.namePoint}}</b>
                <p id="block2" class="F4 fs4">{{goods.desc1}}</p>
              </div>
            </div>
        </div>
        </ng-template>
  
        <ng-template #other_content>
          <div class="row">
            <div id="second" class="col-md-6"> 
              <div class="content-text">
                <b id="block" class="F3 fs3">{{goods.namePoint}}</b>
              <p id="block2"  class="F4 fs4">{{goods.desc1}}</p>
            </div>
              
            </div>
            <div id="first" class="col-md-6">
              <a [routerLink]="['/product-and-services-detail-component',goods.groupIdRef1]" routerLinkActive="router-link">
                <div class="card2 text-center" >
                  <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
                </div>
              <!-- <img src={{goods.imgPath1}} class="card-img-1"id="block1" alt="Title5" height="90%"> -->
            </a>
            </div>
        </div>
        </ng-template>
  
        <div *ngIf="role1">
          <div>
            <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
            <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
          </div>
        </div>
        <br>
    </div>
  
    <div *ngIf="role1">
      <div class="content">
        <button type="button" class="btn btn-primary" (click)="addGoods(33)">Add new (33)</button>
      </div>
    </div>
  </div>
</div>


  <!-- <div id="ref2"></div>
  <br><br><br><br><br><br><br><br><br>

    <div class="container">
      <div>{{getDataForBlog(322)}}</div>
      <div *ngFor="let goods of goodss">
        <b>{{goods.namePoint}}</b><hr><br> 
        <div id="Title3"></div>
        <h5>{{goods.desc1}}</h5>
        <br>
        <div *ngIf="role1">
          <div>
            <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
            <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPath1,goods.imgPath2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
          </div>
        </div>
      </div>
      
        <div class="container d-flex justify-content-center mt-50 mb-50">
          <div class="row ">
            <div>{{getDataForBlog(34)}}</div>
            <div class="col-lg-3 mt-2 hover-fx" *ngFor="let goods of goodss">
              <div id="card">
                <a routerLink="/product-item1-component" routerLinkActive="router-link">
                  <div class="card-body ">
                    <div class="card-img-actions"> <img
                        src={{goods.imgPath1}}
                        class="card-img img-fluid" width="96" height="350" alt=""> </div>
                  </div>
                  <div class="card-body bg-light text-center">
                    <div class="mb-2">
                      <h3 class="font-weight-semibold mb-2"> <a href="#" class="text-default mb-2"
                          data-abc="true">{{goods.namePoint}}</a> </h3>
                    </div>
                    <h3 class="mb-0 ">{{goods.desc1}}</h3>
                  </div>
                </a>
              </div>

              <div *ngIf="role1">
                <div>
                  <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                  <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPath1,goods.imgPath2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
              </div>
              <br>

            </div>

            <div *ngIf="role1">
              <div class="content">
                <button type="button" class="btn btn-primary" (click)="addGoods(34)">Add new (34)</button>
              </div>
            </div>
          
          </div>
        </div>
        <div class="text-center">
          <a href="#back"><i class="fa fa-home" style="font-size:50px;color:#3b9122"></i></a>
        </div>
    </div> -->


    <div id="ref3" class="id-top"></div>
  
      <div class="container">
        <br>
        <div>{{getDataForBlog(323)}}</div>
        <div *ngFor="let goods of goodss">
          <h1 class="text-center F3 fs3">{{goods.namePoint}}</h1>
          <h5 class="text-center F4 fs4">{{goods.desc1}}</h5>
          <br><hr width="70%" color="red"><br>
          <div *ngIf="role1">
            <div>
              <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
              <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
          </div>
        </div>
        
          <div class="container-fluid d-flex justify-content-center mt-50 mb-50">
            <div class="row ">
              <div>{{getDataForBlog(35)}}</div>
              <div class="col-lg-6 mt-2 hover-fx" *ngFor="let goods of goodss">
                <div id="card">
                  <a [routerLink]="['/product-and-services-detail-component',goods.groupIdRef1]" routerLinkActive="router-link">
                    <div class="card-body bg-light">
                      <div class="card-img-actions ">
                        <img src={{goods.imgPathTemp1}} class="card-img-top" alt=""> 
                      </div>
                    </div>
                    <div class="card-body bg-light text-center">
                      <h3 class="F11">{{goods.namePoint}} </h3>
                      <h3 class="F12">{{goods.desc1}}</h3>
                    </div>
                  </a>
                </div>
  
                <br>
                <div *ngIf="role1">
                  <div>
                    <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                    <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                </div>
                <br>
  
              </div>
  
              <div *ngIf="role1">
                <div class="content">
                  <button type="button" class="btn btn-primary" (click)="addGoods(35)">Add new (35)</button>
                </div>
              </div>
            
            </div>
          </div>
      </div>


<br><br>
<!-- <div class="container-fluid"> -->
  <div>{{getDataForBlog(312)}}</div>
  <div *ngFor="let goods of goodss">
    <section id="sushi-header" style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
      
      </section>
      <br><br>
      <div class="btn-animated" >
        <h1 class="F1 fs1">{{goods.namePoint}}</h1><br>
        <h5 class="F2 fs2">{{goods.desc1}}</h5>
    </div>
      <div *ngIf="role1">
          <div class="container">
            <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
            <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
          </div>
        </div>
  </div>
<!-- </div> -->
<br>
<div id="ref4" class="id-top"></div>
  
    <div class="container" id="myHeader-">
      <div class="bg3---">
      <br>
      <div>{{getDataForBlog(324)}}</div>
      <div *ngFor="let goods of goodss">
        <h1 class="text-center F3 fs3">{{goods.namePoint}}</h1>
        <h5 class="text-center F4 fs4">{{goods.desc1}}</h5>
        <br><hr width="70%" color="red"><br>
        <div *ngIf="role1">
          <div>
            <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
            <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
          </div>
        </div>
      </div>

      <br>
      
        <div>{{getDataForBlog(361)}}</div>
        <div *ngFor="let goods of goodss">
          <b class="F3 fs3">{{goods.namePoint}}</b><br> 
          <h5 class="F4 fs4">{{goods.desc1}}</h5><hr color="#fff"><br>
          <div *ngIf="role1">
            <div>
              <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
              <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
          </div>
      
          edit-translation
      
            <div class="row">
              <div>{{getDataForBlogL2(goods.groupIdRef1)}}</div>
              <div class="col-md-3 col-sm-6" *ngFor="let goodsL2 of goodssL2">
                <div class="product-grid4" >
                  <a [routerLink]="['/product-and-services-detail-component',goodsL2.groupIdRef1,'/product-and-services-detail-component2',goodsL2.groupId]" routerLinkActive="router-link">
                  
                   onent
                    <!-- </a> -->
                    <div class="product-image4 zoom">
                    
                      <img class="pic-1" src={{goodsL2.imgPathTemp1}}>
                      <img class="pic-2" src={{goodsL2.imgPathTemp2}}>
                    
                    <!-- <ul class="social">
                      <li><a href="#" data-tip="Quick View"><i class="fa fa-eye"></i></a></li>
                      <li><a href="#" data-tip="Add to Wishlist"><i class="fa fa-shopping-bag"></i></a></li>
                      <li><a href="#" data-tip="Add to Cart"><i class="fa fa-shopping-cart"></i></a></li>
                    </ul> -->

                    <!-- <span class="product-discount-label">-10%</span> -->
                  </div>
                  <div class="product-content">
                    <div class="F5 fs6">{{goodsL2.namePoint}}</div>
                    <div class="F6 fs6">{{goodsL2.desc1}}</div>
                    <!-- <a class="add-to-cart" href="">ADD TO CART</a> -->
                  </div>
                </a>
                </div>
                <br>
                <div *ngIf="role1">
                  <div>
                    <button type="button" (click)="updateGoods(goodsL2.id,goodsL2.groupId)" class="btn btn-info"> Update</button>
                    <button type="button" (click)="deleteGoods(goodsL2.id,goodsL2.groupId,goodsL2.imgPathTemp1,goodsL2.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                </div>
                <br>
              </div>

              <div *ngIf="role1">
                <div class="content">
                  <button type="button" class="btn btn-primary" (click)="addGoods(goods.groupIdRef1)">Add new ({{goods.groupIdRef1}})</button>
                </div>
              </div>

            </div>
      </div>

    <div *ngIf="role1">
      <div class="content">
        <button type="button" class="btn btn-primary" (click)="addGoods(361)">Add new (361)</button>
      </div>
    </div>

    <!-- <br>
    <h3>สินค้าแนะนำ</h3><hr class="style2"><br>
    <div class="container">
      <div>{{getDataForBlog(399)}}</div>
      <div class="row">
         <div class="col-md-3 col-sm-6" *ngFor="let goods of goodss">
          <a  [routerLink]="['/product-and-services-detail-component-cerent',goods.groupIdRef1]" routerLinkActive="router-link">
          <div class="card">
              <img class="card-img-top" src={{goods.imgPath1}} alt="Card image cap" >
              <div class="card-body"><i>
                <p class="card-text">{{goods.desc1}}</p>
                <b>{{goods.desc2}}</b></i> 
              </div>
            </div>
           </a>
            <div *ngIf="role1">
              <br>
              <div>
                <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPath1,goods.imgPath2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
            </div>
            <br>
         </div>
      </div>
      <div *ngIf="role1">
        <div class="content">
          <button type="button" class="btn btn-primary" (click)="addGoods(399)">Add new (399)</button>
        </div>
      </div>
  </div>  -->

  </div>
</div>
  
  
<div id="ref5" class="id-top"></div>

    <div class="container">
      <br>
      <div>{{getDataForBlog(325)}}</div>
      <div *ngFor="let goods of goodss">
        <h1 class="text-center F3 fs3">{{goods.namePoint}}</h1>
        <h5 class="text-center F4 fs4">{{goods.desc1}}</h5>
        <br><hr width="70%" color="red"><br>
        <div *ngIf="role1">
          <div>
            <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
            <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
          </div>
        </div>
      </div>

      <div>{{getDataForBlog(37)}}</div>
      <div *ngFor="let goods of goodss">
        <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>    
        <ng-template #content>
          <div class="row" >
            <div id="first" class="col-md-6 zoom">
              <a routerLink="/product-and-services-detail-component" routerLinkActive="router-link">
              <img src={{goods.imgPathTemp1}} class="card-img-1 " alt="Title5" id="block1">
              </a>
            </div>
            <div id="second"  class="col-md-6">
              <div class="content-text">
                <b id="block"  class="F3 fs3">{{goods.namePoint}}</b>
                <p id="block2" class="F4 fs4">{{goods.desc1}}</p>
              </div>
            
            </div>
        </div>
        </ng-template>
  
        <ng-template #other_content>
          <div class="row">
            <div id="second" class="col-md-6"> 
              <div class="content-text">
                <b id="block"  class="F3 fs3">{{goods.namePoint}}</b>
                <p id="block2" class="F4 fs4">{{goods.desc1}}</p>
              </div>
              
            </div>
            <div id="first" class="col-md-6 zoom">
              <a [routerLink]="['/product-and-services-detail-component',goods.groupIdRef1]" routerLinkActive="router-link">
              <img src={{goods.imgPathTemp1}} class="card-img-1"id="block1" alt="Title5" height="90%">
              </a>
            </div>
        </div>
        </ng-template>
  
        <div *ngIf="role1">
          <div>
            <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
            <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
          </div>
        </div>
        <br>
  
    </div>
  
    <div *ngIf="role1">
      <div class="content">
        <button type="button" class="btn btn-primary" (click)="addGoods(37)">Add new (37)</button>
      </div>
    </div>
  </div>



<!-- Creates the bootstrap modal where the image will appear -->
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <form (ngSubmit)="onSubmit(eventsFlag)">
  <div class="modal-dialog" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
          <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
        </div>
        <div class="modal-body">

          <tr>
            <td><label> Groups : {{BlogId}}</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupId"
                name="groupId"></td>
          </tr>
          <tr>
            <td><label> Order No : </label></td>
            <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="goods.orderNo" name="orderNo"></td>
          </tr>
          <tr>
            <td><label> GroupsRef1</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef1"
                name="groupIdRef1"></td>
          </tr>
          <tr>
            <td><label> GroupsRef2</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef2"
                name="groupIdRef2"></td>
          </tr>
          <tr>
            <td><label> GroupsRef3</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef3"
                name="groupIdRef3"></td>
          </tr>
          <tr>
            <td><label> Href1</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href1" name="href1">
            </td>
          </tr>
          <tr>
            <td><label> Href2</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href2" name="href2">
            </td>
          </tr>
          <tr>
            <td><label> Href3</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href3" name="href3">
            </td>
          </tr>
          <tr>
            <td><label> Name</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.namePoint"
                name="namePoint"></td>
          </tr>
          <tr>
            <td><label> Path1</label></td>
            <td><input type="text" class="form-control control-size" id="imgPath1" [(ngModel)]="goods.imgPath1"
                name="imgPath1"></td>
          </tr>
          <tr>
            <td>
              <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                  (click)="upload('imgPath1')">Upload</button></div>
            </td>
            <td>
              <div class="col-8">
                <label class="btn btn-default p-0 control-size">
                  <input type="file" (change)="selectFile($event)" />
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td><label> Path2</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.imgPath2"
                name="imgPath2"></td>
          </tr>
          <tr>
            <td>
              <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                  (click)="upload('imgPath2')">Upload</button></div>
            </td>
            <td>


              <div class="col-8">
                <label class="btn btn-default p-0 control-size">
                  <input type="file" (change)="selectFile($event)" />
                </label>
              </div>


            </td>
          </tr>
          <tr>
            <td><label> Desc1</label></td>
            <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc1"
                name="desc1"></textarea></td>
          </tr>
          <tr>
            <td><label> Desc2</label></td>
            <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc2"
                name="desc2"></textarea></td>
          </tr>
          <tr>
            <td></td>
            <td>
              <div *ngIf="currentFile" class="progress my-3">
                <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                  attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                  [ngStyle]="{ width: progress + '%' }">
                  {{ progress }}%
                </div>
              </div>
            </td>
          </tr>
        </div>
        <div class="modal-footer">
          <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
          <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
        </div>
     
      </div>
    </div>
  </div>
 </form>
</div>
  <!-- end  Creates the bootstrap modal where the image will appear -->


  
</body>

</html>