<!DOCTYPE html>
<html lang="en" >

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>
<!-- <app-menubar></app-menubar> -->

<body>

  <!-- <div id="bottom" >
    <h6 style="color: transparent; font-size: 0px; ">มิตรภาพ เครือมิตรภาพ มิตรภาพอาหารสัตว์ มิตรภาพโภคภัณฑ์ อาหารสัตว์ อาหารหมู อาหารวัว อาหารไก่ อาหารแพะ อาหารเป็ด MIITRAPHAP เลี้ยงหมู เลี้ยงเป็ด เลี้ยงไก่ เลี้ยงวัว</h6>
  </div> -->

  <!-- hitwebcounter Code START -->
               


  <div class="blackLayer">
    <div class="heading-primary-Left">
      <div class="container-fluid">
        <!-- <div class="row">
          <div class="col-md-12 text-center">
            <div class="btn-animated">
              <br>
              <a [routerLink]="['/businessus-component']" routerLinkActive="router-link">
               <img src={{logoPath}} alt="Trulli">
              </a>
            </div>
          </div>
        </div> -->
        <div class="row" style="padding-top: 180px;">
          <div class="col-md-12 text-center">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <span class="t1">กว่า </span><span class="t2">40 ปี </span><span class="t3">มิตรภาพเคียงคู่เกษตรกรไทย</span>
          </div>   
        </div>

                      
        <!-- <div class="row">
          <div class="col-md-2 text-center">
            <div class="section-title">
              <h2 class="title-underline "><span class="t-orange2">Mittraphap Group</span></h2>
              <h2 class=""><span class="t-orange">เครือมิตรภาพ โภคภัณฑ์</span></h2>
            </div>
          </div>
          <div class="col-md-10 text-center">
            
            <span class="t1">มากว่า </span><span class="t2">30 ปี </span><span class="t3">ที่มิตรภาพเคียงคู่เกษตรกรไทย</span>
          </div>
        </div> -->
      </div>


    </div>
    <br>
    <!-- <div class="heading-primary-Left">
      <div class="section-title">
        <h2 class="title-underline "><span class="t-orange2">Mittraphap Group</span></h2>
        <h2 class=""><span class="t-orange">เครือมิตรภาพ โภคภัณฑ์</span></h2>
        <h1 class=""><span class="t-orange2">มากว่า 30 ปี ที่มิตรภาพเคียงคู่เกษตรกรไทย</span></h1>
      </div>
    </div> -->
 


  <div *ngIf="role1">
    <div class="row">
      <div class="col-md-2">
        <div>{{getDataForBlog(1)}}</div>
        <div class="container" *ngFor="let goods of goodss">
          <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update ({{goods.groupId}})</button>
          <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete ({{goods.groupId}})</button>
        </div>
      </div>
      <br>
      <!-- <div class="col-md-2">
        <div>{{getDataForBlog(2)}}</div>
        <div class="container" *ngFor="let goods of goodss">
          <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update ({{goods.groupId}})</button>
          <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete ({{goods.groupId}})</button>
        </div>
      </div>
      <br> -->

      <div class="col-md-2">
        <div>{{getDataForBlog(3)}}</div>
        <div class="container" *ngFor="let goods of goodss">
          <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update ({{goods.groupId}})</button>
          <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete ({{goods.groupId}})</button>
        </div>
      </div>
      <br>

      <div class="col-md-2">
        <div>{{getDataForBlog(4)}}</div>
        <div class="container" *ngFor="let goods of goodss">
          <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update ({{goods.groupId}})</button>
          <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete ({{goods.groupId}})</button>
        </div>
      </div>
       <br>
       <!-- <div class="col-md-2">
        <div>{{getDataForBlog(5)}}</div>
        <div class="container" *ngFor="let goods of goodss">
          <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update ({{goods.groupId}})</button>
          <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete ({{goods.groupId}})</button>
        </div>
      </div>
       <br> -->
  </div>

  </div>
  <br><br><br><br><br>
<div class="container-fluid">
  <div class="bg1">
    <div class="contai">
      
      <div>{{getDataForBlog(3)}}</div>
      <div *ngFor="let goods of goodss" class="section" style="background-image: url({{goods.imgPathTemp1}}); background-position:top;"  [routerLink]="['/aboutus-component']" routerLinkActive="router-link" (click)="setVisitCount(goods.id,goods.countVisits)"> 
        <div class="content">
          <a [routerLink]="['/aboutus-component']" routerLinkActive="router-link-active" (click)="setVisitCount(goods.id,goods.countVisits)">
            <h3 class="rotate text-center t5">
              <P>{{goods.desc1}}</P>
              <P>{{goods.desc2}}</P>
            </h3>
          </a>
        </div>
        <!-- <div class="f-red fs13">เข้าชม : {{goods.countVisits}} ครั้ง</div> -->
        <!-- <div class="overlay"></div> -->
      </div>

      <div>{{getDataForBlog(1)}}</div>
      <div *ngFor="let goods of goodss" style="background-image: url({{goods.imgPathTemp1}}); background-position:top;" class="section" [routerLink]="['/kingdom-farm-component']" routerLinkActive="router-link" (click)="setVisitCount(goods.id,goods.countVisits)"> 
        <div class="content">
          <a [routerLink]="['/kingdom-farm-component']" routerLinkActive="router-link-active" (click)="setVisitCount(goods.id,goods.countVisits)">
            <h3 class="rotate text-center t5">
              <P>{{goods.desc1}}</P>
              <P>{{goods.desc2}}</P>
            </h3>
          </a>
        </div>
        <!-- <div class="f-red fs13">เข้าชม : {{goods.countVisits}} ครั้ง</div> -->
        <!-- <div class="overlay"></div> -->
      </div>

      <!-- <div>{{getDataForBlog(2)}}</div>
      <div *ngFor="let goods of goodss" style="background-image: url({{goods.imgPathTemp1}}); background-position:top;" class="section" [routerLink]="['/kingdom-animal-medicine-component']" routerLinkActive="router-link"> 
        <div class="content">
          <a [routerLink]="['/kingdom-animal-medicine-component']" routerLinkActive="router-link-active">
            <h3 class="rotate text-center t5">
              <P>{{goods.desc1}}</P>
              <P>{{goods.desc2}}</P>
            </h3>
          </a>
        </div>
      </div> -->

     

      <!-- <div>{{getDataForBlog(4)}}</div>
      <div *ngFor="let goods of goodss" style="background-image: url({{goods.imgPathTemp1}}); background-position:top;" class="section" [routerLink]="['/kingdom-feedmills-component']" routerLinkActive="router-link" (click)="setVisitCount(goods.id,goods.countVisits)"> 
        <div class="content">
          <a [routerLink]="['/kingdom-feedmills-component']" routerLinkActive="router-link-active" (click)="setVisitCount(goods.id,goods.countVisits)">
            <h3 class="rotate text-center t5">
              <P>{{goods.desc1}}</P>
              <P>{{goods.desc2}}</P>
            </h3>
          </a>
        </div>
       
        <div class="overlay"></div>
      </div> -->

      <!-- <div>{{getDataForBlog(5)}}</div>
      <div *ngFor="let goods of goodss" style="background-image: url({{goods.imgPathTemp1}}); background-position:top;" class="section" [routerLink]="['/kingdom-supplementary-food-component']" routerLinkActive="router-link"> 
        <div class="content">
          <a [routerLink]="['/kingdom-supplementary-food-component']" routerLinkActive="router-link-active">
            <h3 class="rotate text-center t5">
              <P>{{goods.desc1}}</P>
              <P>{{goods.desc2}}</P>
            </h3>
          </a>
        </div>
      </div> -->

      <br>
    </div>
  </div>

  <div class="overlay"></div>
</div>
  
  <br>
  <div class="text-center">
      <P class="t4">COPYRIGHT © 2021 MITTRAPHAP GROUP PUBLIC COMPANY LIMITED ALL RIGHTS RESERVED.</P>
  </div>
  <br>

</div>

    <!-- Creates the bootstrap modal where the image will appear -->
    <div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <form (ngSubmit)="onSubmit(eventsFlag)">
      <div class="modal-dialog" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable" role="document">
          <div class="modal-content">
            
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
              <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
            </div>
            <div class="modal-body">
    
              <tr>
                <td><label> Groups : {{BlogId}}</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupId"
                    name="groupId"></td>
              </tr>
              <tr>
                <td><label> Order No : </label></td>
                <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="goods.orderNo" name="orderNo"></td>
              </tr>
              <tr>
                <td><label> GroupsRef1</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef1"
                    name="groupIdRef1"></td>
              </tr>
              <tr>
                <td><label> GroupsRef2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef2"
                    name="groupIdRef2"></td>
              </tr>
              <tr>
                <td><label> GroupsRef3</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef3"
                    name="groupIdRef3"></td>
              </tr>
              <tr>
                <td><label> Href1</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href1" name="href1">
                </td>
              </tr>
              <tr>
                <td><label> Href2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href2" name="href2">
                </td>
              </tr>
              <tr>
                <td><label> Href3</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href3" name="href3">
                </td>
              </tr>
              <tr>
                <td><label> Name</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.namePoint"
                    name="namePoint"></td>
              </tr>
              <tr>
                <td><label> Path1</label></td>
                <td><input type="text" class="form-control control-size" id="imgPath1" [(ngModel)]="goods.imgPath1"
                    name="imgPath1"></td>
              </tr>
              <tr>
                <td>
                  <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                      (click)="upload('imgPath1')">Upload</button></div>
                </td>
                <td>
                  <div class="col-8">
                    <label class="btn btn-default p-0 control-size">
                      <input type="file" (change)="selectFile($event)" />
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td><label> Path2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.imgPath2"
                    name="imgPath2"></td>
              </tr>
              <tr>
                <td>
                  <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                      (click)="upload('imgPath2')">Upload</button></div>
                </td>
                <td>
    
    
                  <div class="col-8">
                    <label class="btn btn-default p-0 control-size">
                      <input type="file" (change)="selectFile($event)" />
                    </label>
                  </div>
    
    
                </td>
              </tr>
              <tr>
                <td><label> Desc1</label></td>
                <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc1"
                    name="desc1"></textarea></td>
              </tr>
              <tr>
                <td><label> Desc2</label></td>
                <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc2"
                    name="desc2"></textarea></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div *ngIf="currentFile" class="progress my-3">
                    <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                      attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                      [ngStyle]="{ width: progress + '%' }">
                      {{ progress }}%
                    </div>
                  </div>
                </td>
              </tr>
            </div>
            <div class="modal-footer">
              <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
              <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
            </div>
         
          </div>
        </div>
      </div>
     </form>
    </div>
      <!-- end  Creates the bootstrap modal where the image will appear -->

  
</body>
</html>