import { Component, OnInit } from '@angular/core';
import { Goods } from '../../app.class/goods';
import { GoodsService } from '../../app.service/goods.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThisReceiver } from '@angular/compiler';
import { GlobalConstants } from '../../common/global-constants';
import { UploadFilesService } from 'src/app/app.service/upload-files.service';
import { filedata } from 'src/app/app.class/filedata';
import { Observable } from 'rxjs';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-news-and-events',
  templateUrl: './news-and-events.component.html',
  styleUrls: ['./news-and-events.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})
export class NewsAndEventsComponent implements OnInit {
  t_goodss: Goods[]=new Array();//ไว้สำหรับนำมาแสดง
  goodss: Goods[]=new Array();//ไว้สำหรับนำมาแสดง
  goods: Goods = new Goods();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  id: number=0 ;
  // isLoggedIn: boolean=false;
  eventsFlag : string ="A";
  eventsText : string="";
  role1 : boolean= false;
  BlogId : number=0;

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  fileInfos: Observable<any> | undefined;
  t_fileInfos : filedata[] =new Array();
  file: filedata = new filedata();

  constructor(private goodsService: GoodsService,
    private router: Router,private formBuilder: FormBuilder,private route: ActivatedRoute,
    private uploadService: UploadFilesService,private tokenStorageService: TokenStorageService) {

      if (this.tokenStorageService.checkUser())
      {
        this.role1 = !!this.tokenStorageService.getToken();
      }
       
    }

   ngOnInit(): void {
      this.getGoods();
   }
 
   private getGoods(){
     this.goodsService.getGoodsList().subscribe(data => {
         this.t_goodss=data;
     });
   }

  //  getDataForBlog(ind: number,isEnable?:number){
  //    let isFirst : boolean=true;
  //   this.goodss.splice(0,this.goodss.length);//clear all
  //   this.t_goodss.forEach( (element : any) =>  {
  //     if (element.groupId==ind ){//ถ้าเป็นกลุ่มสินค้าและบริการ
  //        if (isEnable==1){//ถ้าสั่งให้ตรวจสอบสถานะด้วย
  //         if (element.isEnable==1){
  //           this.goodss.push(element);
  //         }
  //        }else if (isEnable==2){ 
  //         if (element.isEnable==0){//เลือกเฉพาะตัวที่ยกเลิก
  //           if (isFirst==true){
  //             element.isFirst=true;
  //             isFirst=false;
  //           }else {
  //             element.isFirst=false;
  //           }

  //           // alert(isEnable + "" + element.isFirst);
  //           this.goodss.push(element);
  //         }
  //        }
  //        else{// ถ้า isEnable ไม่ได้ระบุอะไรแสดงว่า เอาทุกตัว
  //         this.goodss.push(element);
  //        }
  //     }
  //   });
  // }

//   getDataForBlog(ind: number,isEnable?:number,isFirst? :number){
//    this.goodss.splice(0,this.goodss.length);//clear all
//    this.t_goodss.forEach( (element : any) =>  {
//      if (element.groupId==ind ){//ถ้าเป็นกลุ่มสินค้าและบริการ
//         if (isEnable==1){//ถ้าสั่งให้ตรวจสอบสถานะด้วย
//          if (element.isEnable==1){
//            this.goodss.push(element);
//          }
//         }else if (isEnable==2){ 
//          if (element.isEnable==0){//เลือกเฉพาะตัวที่ยกเลิก
//            if (isFirst==1){
//             this.goodss.push(element);
//             isFirst=-1;
//            }else if (isFirst == 2){
//             isFirst=-2;//ไม่เอารอบแรก
//            }else if (isFirst == -2){
//             this.goodss.push(element);
//            }
//          }
//         }
//         else{// ถ้า isEnable ไม่ได้ระบุอะไรแสดงว่า เอาทุกตัว
//          this.goodss.push(element);
//         }
//      }
//    });
//  }

getDataForBlog(ind: number,isEnable?:number,fromSelect? :number ,toSelect? :number){
  let count :number=0;
  this.goodss.splice(0,this.goodss.length);//clear all
  this.t_goodss.forEach( (element : any) =>  {
    if (element.groupId==ind ){//ถ้าเป็นกลุ่มสินค้าและบริการ
       if (isEnable==1){//ถ้าสั่งให้ตรวจสอบสถานะด้วย
        if (element.isEnable==1){
          element.imgPathTemp1= GlobalConstants.httpPath + element.imgPath1;
          element.imgPathTemp2= GlobalConstants.httpPath + element.imgPath2;
          this.goodss.push(element);
        }
       }else if (isEnable==2){ 
        if (element.isEnable==0){//เลือกเฉพาะตัวที่ยกเลิก
          count++;
          if (count >= Number(fromSelect)  && count <= Number(toSelect) ){

            element.imgPathTemp1= GlobalConstants.httpPath + element.imgPath1;
            element.imgPathTemp2= GlobalConstants.httpPath + element.imgPath2;
            element.count= count;
           this.goodss.push(element);
          }
        }
       }
       else{// ถ้า isEnable ไม่ได้ระบุอะไรแสดงว่า เอาทุกตัว
        element.imgPathTemp1= GlobalConstants.httpPath + element.imgPath1;
        element.imgPathTemp2= GlobalConstants.httpPath + element.imgPath2;
        this.goodss.push(element);
       }
    }
  });
}


  addGoods(blogId: number){
    this.goods = new Goods();
    this.goods.groupId=blogId;

    this.showModal = true; // Show-Hide Modal Check
    this.BlogId=blogId;
    this.eventsFlag="A";
    this.eventsText ="เพิ่มข้อมูล";
  }

   updateGoods(id: number,blogId: number){
     this.id=id;
     this.goodsService.getGoodsById(this.id).subscribe(data => {
       this.goods = data;
     }, error => console.log(error));
    this.showModal = true; // Show-Hide Modal Check
    this.BlogId=blogId;
    this.eventsFlag="E";
    this.eventsText ="แก้ไขข้อมูล";
   }
 
   deleteGoods(id: number,blogId: number,fileId1? : string,fileId2? : string ){
    let isConfirm : Boolean | any ;
    isConfirm=confirm("Are you sure to delete "+ id)
  
    if(isConfirm===false) {
      return;
    }
  
      this.goodsService.deleteGoods(id).subscribe( data => {
        console.log(data);
        this.getGoods();
    
      // this.deleteFiles(fileId1);
      // this.deleteFiles(fileId2);
      })
      this.BlogId=blogId;
      this.eventsFlag="D";
    
  }

  deleteFiles(id : string | any){
    this.uploadService.deleteFileById(id).subscribe( data => {
      console.log(data);
    })
  }

  scrollToElement($element : any): void {
    console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
}
 
 //for Submit form when action
   onSubmit(AddEditMode : string){
   
     if (AddEditMode=="A") {
       this.goodsService.createGoods(this.goods).subscribe( data =>{
         console.log(data);
          this.getGoods();//ย้อนกลับมาหน้าตัวเอง
       },
       error => console.log(error));
     }else if (AddEditMode=="E"){
       this.goodsService.updateGoods(this.id, this.goods).subscribe( data =>{
         
         console.log(data);
          this.getGoods();//ย้อนกลับมาหน้าตัวเอง
       }
       , error => console.log(error));
     }
      this.hide();
   }
 
 //for popup form
   showModal: boolean | any;
   registerForm: FormGroup | any;
   submitted = false;
 
  show()
    { 
      this.showModal = true;
    }

   hide()
   {
     this.showModal = false;
   }

   verifyUser(Us : boolean){
     GlobalConstants.role1=Us;
     this.role1=GlobalConstants.role1;
   }

      //for upload file
  selectFile(event: any): void 
  {
   
   this.selectedFiles = event.target.files;
   
   const files = event.target.files;
   for (let file of files) {
      GlobalConstants.partDirName=`${file.name}`;
   }

  }

  upload(ind : string): void {
   this.fileInfos = this.uploadService.getFiles();

   this.progress = 0;
 
   if (this.selectedFiles) {
     const file: File | null = this.selectedFiles.item(0);
     
     if (file) {
       this.currentFile = file;
       //  alert(this.currentFile);
       this.uploadService.upload(this.currentFile).subscribe(
         (event: any) => {
           if (event.type === HttpEventType.UploadProgress) {
             this.progress = Math.round(100 * event.loaded / event.total);
           } else if (event instanceof HttpResponse) {
             this.message = event.body.responseMessage;
             this.fileInfos = this.uploadService.getFiles(); 
             //this.fileInfos = this.uploadService.getFilesById(this.currentFile?.name);
           }
           this.fileInfos = this.uploadService.getFiles(); 
          // this.fileInfos = this.uploadService.getFilesById(this.currentFile?.name);
         },
         (err: any) => {
           console.log(err);
           this.progress = 0;
 
           if (err.error && err.error.message) {
             this.message = err.error.message;
           } else {
             this.message = 'Could not upload the file!';
           }
           this.currentFile = undefined;
         });
     }
     this.selectedFiles = undefined;
    }
    
    if(ind=="imgPath1"){
      this.goods.imgPath1 ="files/" + GlobalConstants.partDirName;
     }else if(ind=="imgPath2"){
      this.goods.imgPath2 ="files/" + GlobalConstants.partDirName;
     }
    
 }

 setVisitCount(id : number=0,isCount : number =0)
  {

    this.goods.countVisits=isCount +1;

    this.goodsService.updateVisits(id, this.goods).subscribe( data =>{

      // alert(this.goods.countVisits);
         
      console.log(data);
      //  this.getGoods();//ย้อนกลับมาหน้าตัวเอง
    }
    , error => console.log(error));
  }
 
 }

