<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name=viewport content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>

<body class="ht">

  <div #back></div>
  <div id="bottom" (click)="scrollToElement(back)" >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
   </div>


   <div class="bg_2_1">
    <div class="bg_1_2">
      <div class ="bg_0_3">
    <!-- <div>{{getDataForBlog(611)}}</div> -->
    <!-- <div *ngFor="let goods of goodss"> -->
      <!-- <section id="sushi-header" style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
      </section> -->
      
      <div class="btn-animated margin_head">
        <section id="sushi-header" >
          <div class="Iam">
            <p style="color: #fff;">ติดต่อเราได้ที่</p>
            <b style="color: red;">
              <div class="innerIam">
                บริษัท<br/>
                มิตรภาพอาหารสัตว์<br/>
                จำกัด<br/>
              </div>
            </b>
          </div>    
          </section> 
      </div>

      <div class="container margin_head3">
        <div class="card-">
          <div class="row text-center">
            <div  class="col-xl-1 col-md-1 col-sm-1"> </div>
  
            <div  class="col-xl-3 col-md-3 col-sm-1"> 
              <div class="card3- text-center">
                <a class="nav-link" [routerLink]="['/contactus-component']" routerLinkActive="router-link" href="#">
                  <div class="item-zoom">
                    <img src="{{httpPath}}files/location-ho.png" alt="Jim Hawkins" title="ที่ตั้งสำนักงานใหญ่" class="round2 text-center">
                  </div>
                </a>
              
                <p class="f-white fs9 btn-animated">
                  ที่ตั้งสำนักงานใหญ่
                </p>
              </div>
            </div>

            <div  class="col-xl-4 col-md-4 col-sm-1"> 
              <div class="card3- text-center">
                <a class="nav-link " href="#link2">
                  <div class="item-zoom">
                    <img src="{{httpPath}}files/location-sub.png" alt="Jim Hawkins" title="ที่ตั้งโรงงาน" class="round2 text-center">
                  </div>
                </a>
              
                <p class="f-white fs9 btn-animated">
                  ที่ตั้งโรงงาน
                </p>
              </div>
            </div>

            <div  class="col-xl-3 col-md-3 col-sm-1"> 
                <div class="card3- text-center">
                <a class="nav-link " href="#link3">
                    <div class="item-zoom">
                      <img src="{{httpPath}}files/contact-data.png" alt="Jim Hawkins" title="ข้อมูลติดต่อ" class="round2 text-center">
                    </div>
                  </a>
                
                  <p class="f-white fs9 btn-animated">
                    ข้อมูลติดต่อ
                  </p>
                </div>
              </div>

            <div  class="col-xl-1 col-md-1 col-sm-1"> </div>
          </div>
        </div>
      </div>

    </div>

  <div class="bg_4_3 margin_head2 btn-animated" >
    <div class="container">
      <div class="row">
          <div class="col-md-8 col-sm-8 btn-animated-">
          <div class="container text-left" id="myHeader-">
            <h2 class="f-white text-left">
              <i class="fa fa-quote-left" aria-hidden="true" ></i> 
              บริษัท มิตรภาพอาหารสัตว์ จำกัด
              <i class="fa fa-quote-right" aria-hidden="true" ></i>
            </h2>
          </div>

          <div class="container">
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
              <div class="col-md-1"></div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 btn-animated-"></div>
      </div>
    </div>
  </div>
</div>
</div>


  <div [style.display]="role1 ? 'block' : 'none'">
    <table class="table table-striped">
      <thead>
        <tr>
          <th> First Name</th>
          <th> Last Name </th>
          <th> Tel</th>
          <th> Email Address</th>
          <th> Note </th>
          <th> Actions </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let contact of contacts">
          <td> {{ contact.firstName }} </td>
          <td> {{ contact.lastName }} </td>
          <td> {{ contact.tel }} </td>
          <td> {{ contact.emailAddr }} </td>
          <td> {{ contact.note }} </td>
          <td>
            <button (click)="updateContact(contact.id)" class="btn btn-info"> Update</button>
            <button (click)="deleteContact(contact.id)" class="btn btn-danger" style="margin-left: 10px">
              Delete</button>
            <button (click)="contactDetails(contact.id)" class="btn btn-info" style="margin-left: 10px"> View</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>


<div id="link1" class="id-top"></div> 
<div class="bg_1_1">
 <div class="bg_1_2">
  <div class="bg_2_3 margin_head2"></div>
 
  <!-- <b class="F5 fs5" id="block">{{goods.namePoint}}</b>
  <p class="F6 fs6" id="block2">{{goods.desc1}}</p> -->

<div id="link2" class="id-top"></div> 
<div class="bg_3_3-">
  <br>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 card">
        <br>
        <h3 style="background-color: #358821">ที่ตั้งโรงงาน</h3><br>
        <!-- <h3><font color="#ooooFF"> MITRAPHAP GROUP</font></h3> -->
        <h4 class="f-green fs4">MITTRAPHAP PRODUCT COMPANY LIMITED (FEEDMILLS)</h4>
        <h6 class="f-green fs6">บริษัท มิตรภาพอาหารสัตว์ จำกัด (โรงงาน)</h6>
        <!-- <p class="font-weight-bold" style="color: rgb(27, 194, 55);">สำนักงานใหญ่ :</p> -->
        <p class="F6 fs12">120/1 ตำบล พัฒนานิคม อำเภอพัฒนานิคม ลพบุรี 15140</p>
        <p class="F6 fs12 font-weight-bold">เบอร์โทรศัพท์ : 036-491777</p>
        <!-- <p class="font-weight-bold">แฟกซ์ : 036-491666</p> -->

        
        <!-- <p class="font-weight-bold" style="color: rgb(27, 194, 55);">แฟกซ์ :</p>02-377-9246 -->
        <!-- <a class="google_map_color" href="opengoogle_map.php">
              <figure class="map-holder">
                  <iframe width="100%" height="290" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.co.th/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=Target+over+here+(Hello+from) @13.8679,100.56071&amp;aq=&amp;z=14&amp;sll=13.8679,100.56071&amp;ie=UTF8&amp;oe=UTF8&amp;hnear=&amp;cbp=12&amp;ll=13.8679,100.56071&amp;t=m&amp;output=embed"></iframe>                                </figure>
          </a> -->
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d750.2322682574272!2d100.93372499624864!3d14.849229220075248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311e0bbb8b310149%3A0xed1ecde9d5b58e45!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4oeC4tOC4leC4o-C4oOC4suC4nuC4reC4suC4q-C4suC4o-C4quC4seC4leC4p-C5jCDguIjguLPguIHguLHguJQ!5e1!3m2!1sth!2sth!4v1646030962981!5m2!1sth!2sth" 
          class="map" width="510" height="350" style="border:0;" allowfullscreen="" loading="lazy">
        </iframe>
        <br>
      </div>


    </div>
    <br>
  </div>
</div>


<div id="link3" class="id-top"></div> 
  <div class="bg_3_3-">
    <br>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 card">
            <br>
            <h3 style="background-color: #358821;">แบบฟอร์มติดต่อ</h3>
            <form (ngSubmit)="onSubmit()">
              <div class="form-group"><br>
                <label class="F6 fs6" for="firstName"> ชื่อ :</label>
                <input type="text" class="form-control" id="firstName" placeholder="Please enter your name"
                  [(ngModel)]="contact.firstName" name="firstName">
                <label class="F6 fs6" for="lastName">นามสกุล :</label>
                <input type="text" class="form-control" id="lastName" placeholder="Please enter last name"
                  [(ngModel)]="contact.lastName" name="lastName">
                <label class="F6 fs6" for="Tel">เบอร์โทร :</label>
                <input type="text" class="form-control" id="tel" placeholder="Please enter your phone number"
                  [(ngModel)]="contact.tel" name="tel">
                <label class="F6 fs6" for=" e-mail">อีเมล :</label>
                <input type="text" class="form-control" id="emailAddr" placeholder="Please enter your email"
                  [(ngModel)]="contact.emailAddr" name="emailAddr">
                <label  class="F6 fs6" for="comment">แสดงความคิดเห็น :</label>
                <!-- <textarea class="form-control" rows="7" id="comment" name="text"></textarea> -->
                <textarea class="form-control" rows="7" id="note" [(ngModel)]="contact.note" name="note"></textarea>
              </div>
          <br>
              <div class="form-group text-center">
                <button type="submit" class="btn btn-success" id="submit">ตกลง</button>&nbsp;
                <!-- <button type="submit" name="ok" class="btn btn-primary">ตกลง</button> -->
                <button type="reset" name="reset" class="btn btn-danger">ยกเลิก</button>
              </div>
            </form>
            <br>
          </div>

    </div>
  </div>
  </div>

    <div class="bg_5_3 margin_footer"></div>
    </div>
    </div>
</body>

</html>