import { Component, OnInit } from '@angular/core';
import { Job } from '../../app.class/job';
import { JobService } from '../../app.service/job.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThisReceiver } from '@angular/compiler';
import { GlobalConstants } from '../../common/global-constants';
import { Observable } from 'rxjs';
import { filedata } from 'src/app/app.class/filedata';
import { UploadFilesService } from 'src/app/app.service/upload-files.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';



@Component({
  selector: 'app-work-with-us-items',
  templateUrl: './work-with-us-items.component.html',
  styleUrls: ['./work-with-us-items.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})
export class WorkWithUsItemsComponent implements OnInit {
  t_jobs: Job[]=new Array();//ไว้สำหรับนำมาแสดง
  jobs: Job[]=new Array();//ไว้สำหรับนำมาแสดง
  job: Job = new Job();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข

  detailLists: any[]=new Array();
  detailList: String="";

  css: String="";

  id: number=0 ;
  isLoggedIn: boolean=true;
  eventsFlag : string ="A";
  eventsText : string="";
  role1 : boolean= false;
  BlogId : number=0;
  GId: number=-99;
  countLoop : number=0;
  isForm : string="";

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  fileInfos: Observable<any> | undefined;
  t_fileInfos : filedata[] =new Array();
  file: filedata = new filedata();

  constructor(private jobService: JobService,
    private router: Router,private formBuilder: FormBuilder,private route: ActivatedRoute,private uploadService: UploadFilesService) {
       this.role1=GlobalConstants.role1;
    }

   ngOnInit(): void {
      this.getJob();
      this.GId = this.route.snapshot.params['id'];
      this.isForm= this.route.snapshot.params['id2'];
      this.getDataForId(this.GId);
   }

   go(){
     if (this.isForm=='fem'){
      this.router.navigate(['/','work-with-us-feedmills-component']);
     }else if (this.isForm=='farm') {
      this.router.navigate(['/','work-with-us-farm-component']);
     }
     
	}
 
   private getJob(){
     this.jobService.getJobList().subscribe(data => {
         this.t_jobs=data;
     });
   }

   getDataForBlog(ind: number){
    let check : boolean |true;
    this.countLoop=0;

    this.jobs.splice(0,this.jobs.length);//clear all
    this.t_jobs.forEach( (element : any) =>  {
      if (element.groupId==ind){//ถ้าเป็นกลุ่มสินค้าและบริการ
        if (check){
          check=false;
        }else{
          check=true;
        }
         element.isSwap=check;

         element.imgPathTemp1= GlobalConstants.httpPath + element.imgPath1;
         element.imgPathTemp2= GlobalConstants.httpPath + element.imgPath2;
          
         this.jobs.push(element);
         this.countLoop++;
      }
    });
  }

  getDataForId(ind: number){
    let check : boolean |true;
    this.countLoop=0;

    this.jobs.splice(0,this.jobs.length);//clear all
    this.t_jobs.forEach( (element : any) =>  {
      if (element.id==ind){//ถ้าเป็นกลุ่มสินค้าและบริการ
        if (check){
          check=false;
        }else{
          check=true;
        }
         element.isSwap=check;

         element.imgPathTemp1= GlobalConstants.httpPath + element.imgPath1;
         element.imgPathTemp2= GlobalConstants.httpPath + element.imgPath2;
          
         this.jobs.push(element);
      }
    });
  }

  getDataFormId(ind: number){

    // if (this.GId==-99 ) {
    //   ind=this.getFirstData(62);
    //  }
    this.jobs.splice(0,this.jobs.length);//clear all
    this.t_jobs.forEach( (element : any) =>  {
      if (element.id==ind){//ถ้าเป็น id ของกลุ่มสินค้าและบริการนั้นๆ
         element.imgPathTemp1= GlobalConstants.httpPath + element.imgPath1;
         element.imgPathTemp2= GlobalConstants.httpPath + element.imgPath2;
         this.jobs.push(element);
      }
    });
  }

  // getFirstData(ind: number) :number {
  //   let isFirst: boolean=true;
  //   let vall:any;
  //   this.t_goodss.forEach( (element : any) =>  {
  //     if (element.groupId==ind){
        
  //       if (isFirst)
  //       {
  //         vall=`${element.id}`
  //         isFirst=false;
  //       }
  //     }
  //   });
  //  return vall;
  // }

  addJob(blogId: number){
    this.job = new Job();
    this.job.groupId=blogId;

    this.showModal = true; // Show-Hide Modal Check
    this.BlogId=blogId;
    this.eventsFlag="A";
    this.eventsText="เพิ่มข้อมูล"
  }

   updateJob(id: number,blogId: number){
     this.id=id;
     this.jobService.getJobById(this.id).subscribe(data => {
       this.job = data;
     }, error => console.log(error));
    this.showModal = true; // Show-Hide Modal Check
    this.BlogId=blogId;
    this.eventsFlag="E";
    this.eventsText="แก้ไขข้อมูล"
   }
 
   deleteJob(id: number,blogId: number,fileId1? : string,fileId2? : string ){
    let isConfirm : Boolean | any ;
    isConfirm=confirm("Are you sure to delete "+ id)
  
    if(isConfirm===false) {
      return;
    }
  
      this.jobService.deleteJob(id).subscribe( data => {
        console.log(data);
        this.getJob();
    
        // this.deleteFiles(fileId1);
        // this.deleteFiles(fileId2);
      })
      this.BlogId=blogId;
      this.eventsFlag="D";
    
  }
  
  deleteFiles(id : string | any){
    this.uploadService.deleteFileById(id).subscribe( data => {
      console.log(data);
    })
  }
 
 //for Submit form when action
   onSubmit(AddEditMode : string){
 
     if (AddEditMode=="A") {
       this.jobService.createJob(this.job).subscribe( data =>{
         console.log(data);
          this.getJob();//ย้อนกลับมาหน้าตัวเอง
       },
       error => console.log(error));
     }else if (AddEditMode=="E"){
       this.jobService.updateJob(this.id, this.job).subscribe( data =>{
         console.log(data);
          this.getJob();//ย้อนกลับมาหน้าตัวเอง
       }
       , error => alert("Error : " +error));
     }
      this.hide();
   }
 
 //for popup form
   showModal: boolean | any;
   registerForm: FormGroup | any;
   submitted = false;
 
  show()
    { 
      this.showModal = true;
    }

   hide()
   {
     this.showModal = false;
   }

   verifyUser(Us : boolean){
     GlobalConstants.role1=Us;
     this.role1=GlobalConstants.role1;
   }
   
   scrollToElement($element : any): void {
    console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
   }

   //for upload file
  selectFile(event: any): void 
  {
   
   this.selectedFiles = event.target.files;
   
   const files = event.target.files;
   for (let file of files) {
      GlobalConstants.partDirName=`${file.name}`;
   }

  }

  upload(ind : string): void {
   this.fileInfos = this.uploadService.getFiles();

   this.progress = 0;
 
   if (this.selectedFiles) {
     const file: File | null = this.selectedFiles.item(0);
     
     if (file) {
       this.currentFile = file;
       //  alert(this.currentFile);
       this.uploadService.upload(this.currentFile).subscribe(
         (event: any) => {
           if (event.type === HttpEventType.UploadProgress) {
             this.progress = Math.round(100 * event.loaded / event.total);
           } else if (event instanceof HttpResponse) {
             this.message = event.body.responseMessage;
             this.fileInfos = this.uploadService.getFiles(); 
             //this.fileInfos = this.uploadService.getFilesById(this.currentFile?.name);
           }
           this.fileInfos = this.uploadService.getFiles(); 
          // this.fileInfos = this.uploadService.getFilesById(this.currentFile?.name);
         },
         (err: any) => {
           console.log(err);
           this.progress = 0;
 
           if (err.error && err.error.message) {
             this.message = err.error.message;
           } else {
             this.message = 'Could not upload the file!';
           }
           this.currentFile = undefined;
         });
     }
     this.selectedFiles = undefined;
    }
    
    if(ind=="imgPath1"){
      this.job.imgPath1 = GlobalConstants.httpPath + "files/" +  GlobalConstants.partDirName;
     }else if(ind=="imgPath2"){
      this.job.imgPath2 = GlobalConstants.httpPath + "files/" +  GlobalConstants.partDirName;
     }
    
 }

  splitStringFormat(str: String){
    this.detailLists = str.split("^"); 
      if (this.detailLists.length > 1){
        this.detailLists.splice(0,1);
      }
    }

    
 }


