import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { Job } from '../app.class/job';
import { map } from 'rxjs/operators';
import { GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  private baseURL = GlobalConstants.httpPath + "job";
  private baseVisitURL = GlobalConstants.httpPath + "job_count";
  private person : any;

  constructor(private httpClient: HttpClient) { }
  
  getJobList(): Observable<Job[]>{
    this.person= this.httpClient.get(this.baseURL)
    .pipe(map((response: any) => response.json()));

    return this.httpClient.get<Job[]>(`${this.baseURL}`);
  }

  createJob(job: Job): Observable<Object>{
    return this.httpClient.post(`${this.baseURL}`, job);
  }

  getJobById(id: number): Observable<Job>{
    return this.httpClient.get<Job>(`${this.baseURL}/${id}`);
  }

  updateJob(id: number, job: Job): Observable<Object>{
    return this.httpClient.put(`${this.baseURL}/${id}`, job);
  }

  updateVisits(id: number, job: Job): Observable<Object>{
    return this.httpClient.put(`${this.baseVisitURL}/${id}`, job);
  }

  deleteJob(id: number): Observable<Object>{
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }
}
