<!DOCTYPE html>
<html lang="en">


<head>
    <meta charset="UTF-8">
    <meta name=viewport content="width=device-width, initial-scale=1">
    <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
    <title>Document</title>
</head>


<body class="ht">
  <div #back></div>
  <div id="bottom" (click)="scrollToElement(back)" >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
  </div>


  <div class="bg_2_1">
    <div class="bg_1_2-">
      <div class="bg_0_3">
        <div>{{getDataForBlog(611)}}</div>
        <div *ngFor="let goods of goodss">
          <!-- <section id="sushi-header" style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
          </section> -->
          
          <div class="btn-animated margin_head">
            <h1 class="f-white fs1" >{{goods.namePoint}}</h1><br>
            <h5 class="f-white fs7" >{{goods.desc1}}</h5>
          </div>

            <div *ngIf="role1">
                <div class="container">
                  <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                  <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
            </div>
        </div>
     </div>
     <div class="bg_4_3 margin_head2 btn-animated" >
      <div class="container">
        <div class="row">
            <div class="col-md-8 col-sm-8 btn-animated-">
            <div>{{getDataForBlog(611)}}</div>
             <div *ngFor="let goods of goodss">
               
              <h1 class="F3 fs3">{{goods.namePoint}}</h1>
              <h6 class="f-white fs2">
                <i class="fa fa-quote-left f-white fs2" aria-hidden="true" ></i>
                  {{goods.desc2}}
                <i class="fa fa-quote-right f-white fs2" aria-hidden="true" ></i>
              </h6>
  
              <!-- <div><img class="mtp_underline" src="assets\img\underline\underline.png"> </div> -->
  
              <div *ngIf="role1">
                <div>
                  <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                  <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 btn-animated-"></div>
        </div>
      
      </div>
    </div>
</div>
</div>
<div #back2></div>
<div class="bg_3_2">

  
  <div class="bg_2_3">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-4 btn-animated">
          <div>{{getDataFormId(GId)}}</div>
          <div *ngFor="let goods of goodss">
              <h1 class="f-white fs3 text-center">{{goods.namePoint}}</h1>
              <!-- <h5 class="f-white fs2 text-center">{{goods.desc1}}</h5> -->
              <div class="container">
                <div class="row">
                  <div class="col-xl-1 col-md-1"></div>
                  <div class="col-xl-10 col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
                  <div class="col-xl-1 col-md-1"></div>
                </div>
              </div>

          <div *ngIf="role1">
            <div>
              <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
              <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-sm-8 btn-animated-"></div>
      </div>
    </div>
  </div>


    
      <div class="container">
       <div class="row text-right">
         <div class="col-md-8 ">
          <div>{{getDataFormId(GId)}}</div>
           <div class="columns1s" *ngFor="let goods of goodss">
            
             <div class="container">
                <div class="row">
                 <div class="col-md-12 col-image text-left">
                   <!-- <b class="f-green fs5" id="block">{{goods.namePoint}}</b> -->
                   <div class="">
                    <br>
                     <p class="f-red fs7" id="block2">{{goods.desc1}}</p>
                  </div>
  
                   <div class="card">
                    <img src={{goods.imgPathTemp1}} alt="" class="card-img-top"> 
                  </div>
                 </div>
                 <div class="col-md-2 "></div>
               </div>
  
               <!-- <div class="row" >
                 <div class="col-md-12 text-left">
                   <div class="">
                     <br>
                      <p class="f-green fs7" id="block2">{{goods.desc1}}</p>
                   </div>
                 </div>
                 <div class="col-md-2 "></div>
               </div> -->
  
               <div class="row">
                <div class="col-md-12 col-image text-left">
                  <div class="card">
                   <img src={{goods.imgPathTemp2}} alt="" class="card-img-top">
                 </div>
                </div>
                <div class="col-md-2 "></div>
              </div>
        
              <div class="row" >
                <div class="col-md-12 text-left">
                  <div class="">
                    <br>
                     <p class="f-red fs7" id="block2">{{goods.desc2}}</p>
                    
                    <div *ngIf="role1">
                      <br>
                      <div>
                        <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                        <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                      </div>
                      <br>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 "></div>
              </div>
  
  
             </div>
           
            </div>
       
            <!-- <div class="container-fluid text-center">
             <a [routerLink]="['/article-component']" routerLinkActive="router-link"><h6 class="FS1">กลับสู่หน้าหลัก</h6></a>
            </div> -->
            
            <div *ngIf="role1">
             <br>
             <div class="content">
               <button type="button" class="btn btn-primary" (click)="addGoods(BlogId)">Add new ({{BlogId}})</button>
             </div>
             <br>
           </div>
         <br><br>
  
  
         </div>
         <div class="col-md-4">
          <br><br><br><br>
          <h3 class="text-left f-red">บทความล่าสุด</h3>
          <section id="gallery-">
            <div class="container-fluid">
              <div>{{getDataForBlog(62)}}</div>
              
              <div class="row" *ngFor="let goods of goodss">
                <div class="card">
                <div class="col-lg-12 col-mb-12" >
                <div class="card">
                  <a class="" (click)="toBlog(goods.id,back2)" [routerLink]="" routerLinkActive="router-link" (click)="setVisitCount(goods.id,goods.countVisits)">
                  <img src={{goods.imgPathTemp1}} alt="" class="card-img-top zoom2 ">
                </a>
                </div>
                

                <a class="" (click)="toBlog(goods.id,back2)" [routerLink]="" routerLinkActive="router-link" (click)="setVisitCount(goods.id,goods.countVisits)">
                  <div class="card-body text-left">
                    <b class="card-title f-red" id="block">{{goods.namePoint}}</b>
                    <p class="f-gray " id="block2">{{goods.desc1}}</p>

                    
                    <h5 class="card-text f-gray fs12">
                      <p>วันที่เขียน : {{goods.createDate}} </p>

                      <div class="f-gray fs13">เข้าชม : {{goods.countVisits}} ครั้ง</div>
                      <!-- <p>ปรับปรุงล่าสุด : {{goods.modifyDate}} </p>  -->
                     </h5>
                  <!-- <a class="btn btn-outline-success btn-sm" [routerLink]="['/article-item-component',goods.groupIdRef1]" routerLinkActive="router-link">อ่านเพิ่ม</a> -->
                  <!-- <button type="button" (click)="toBlog(goods.groupIdRef1)" class="btn btn-info" style="margin-left: 10px"> อ่านเพิ่ม</button> -->
                  </div>
                  </a>
          
                </div>
              </div>
              
              <div *ngIf="role1">
                <br>
                  <div class="container">
                    <h5 class="f-gray"><p>order : {{goods.orderNo}} 
                      <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                      <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                    </p></h5>
                  
                  </div>
                  <br>
              </div>
            </div>
          

            <div *ngIf="role1">
              <div class="container text-center">
                <button type="button" (click)="addGoods(62)" class="btn btn-primary" style="margin-left: 10px">Add new (62)</button>
              </div>
            </div>
          </div>
          </section>
           
         </div>
       </div>
      </div>


      <div class="bg_5_3 margin_footer"></div>
    </div>


<!-- </div> -->

  <!-- </div> -->

    <!-- Creates the bootstrap modal where the image will appear -->
    <div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <form (ngSubmit)="onSubmit(eventsFlag)">
      <div class="modal-dialog" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable" role="document">
          <div class="modal-content">
            
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
              <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
            </div>
            <div class="modal-body">
    
              <tr>
                <td><label> Groups : {{BlogId}}</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupId"
                    name="groupId"></td>
              </tr>
              <tr>
                <td><label> Order No : </label></td>
                <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="goods.orderNo" name="orderNo"></td>
              </tr>
              <tr>
                <td><label> GroupsRef1</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef1"
                    name="groupIdRef1"></td>
              </tr>
              <tr>
                <td><label> GroupsRef2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef2"
                    name="groupIdRef2"></td>
              </tr>
              <tr>
                <td><label> GroupsRef3</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef3"
                    name="groupIdRef3"></td>
              </tr>
              <tr>
                <td><label> Href1</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href1" name="href1">
                </td>
              </tr>
              <tr>
                <td><label> Href2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href2" name="href2">
                </td>
              </tr>
              <tr>
                <td><label> Href3</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href3" name="href3">
                </td>
              </tr>
              <tr>
                <td><label> Name</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.namePoint"
                    name="namePoint"></td>
              </tr>
              <tr>
                <td><label> Path1</label></td>
                <td><input type="text" class="form-control control-size" id="imgPath1" [(ngModel)]="goods.imgPath1"
                    name="imgPath1"></td>
              </tr>
              <tr>
                <td>
                  <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                      (click)="upload('imgPath1')">Upload</button></div>
                </td>
                <td>
                  <div class="col-8">
                    <label class="btn btn-default p-0 control-size">
                      <input type="file" (change)="selectFile($event)" />
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td><label> Path2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.imgPath2"
                    name="imgPath2"></td>
              </tr>
              <tr>
                <td>
                  <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                      (click)="upload('imgPath2')">Upload</button></div>
                </td>
                <td>
    
    
                  <div class="col-8">
                    <label class="btn btn-default p-0 control-size">
                      <input type="file" (change)="selectFile($event)" />
                    </label>
                  </div>
    
    
                </td>
              </tr>
              <tr>
                <td><label> Desc1</label></td>
                <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc1"
                    name="desc1"></textarea></td>
              </tr>
              <tr>
                <td><label> Desc2</label></td>
                <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc2"
                    name="desc2"></textarea></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div *ngIf="currentFile" class="progress my-3">
                    <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                      attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                      [ngStyle]="{ width: progress + '%' }">
                      {{ progress }}%
                    </div>
                  </div>
                </td>
              </tr>
            </div>
            <div class="modal-footer">
              <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
              <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
            </div>
         
          </div>
        </div>
      </div>
     </form>
    </div>
      <!-- end  Creates the bootstrap modal where the image will appear -->

</body>

</html>