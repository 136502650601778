import { Component, OnInit } from '@angular/core';
import { Contact } from '../../app.class/contact';
import { ContactService } from '../../app.service/contact.service';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../common/global-constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})
export class QuestionnaireComponent implements OnInit {
   contacts: Contact[] | any;
   contacts2: Contact[] | any;
   contact: Contact = new Contact();
   contact2: Contact = new Contact();
   id: number=0 ;
   isLoggedIn: boolean=true;
   eventsFlag : string ="A";
   eventsText : string="";
   role1 : boolean= false;
   role2 : boolean= false;
   BlogId : number=0;

   inputPass: boolean=false;
   result:string="";
   result2:string="";
   resultGrade:number=0;
   bgColor:string="";

   q1yn :any ="Y";

   txtValue :string | undefined;
   message : string | undefined;

  
  constructor(private contactService: ContactService,
    private router: Router) { 
      this.role1=GlobalConstants.role1;
      this.role2=GlobalConstants.role2;
    }

  ngOnInit(): void {
    this.getContacts();
  }

  private getContacts(){
    this.contactService.getContactsList().subscribe(data => {
      this.contacts = data;
      // this.getDataForBlog(2,data);
    });
  }

  // getDataForBlog(ind: number,data : Contact|any){
    
  //   this.contacts.splice(0,this.contacts.length);//clear all
  //   data.forEach( (element : any) =>  {
  //     alert(data.groupId);
  //     if (element.groupId === ind){//ถ้าเป็นกลุ่มที่ต้องการ
  //        this.contacts.push(element);
  //     }
  //   });
  // }


  contactDetails(id: number){
    this.router.navigate(['contact-details', id]);
  }

  addContact(blogId: number){
    this.contact = new Contact();
    // this.contact.groupId=blogId;
   
    this.showModal = true; 
    // this.BlogId=blogId;
    this.eventsFlag="A";
    this.eventsText ="กรอกแบบสอบถาม";
   }
   
   updateContact(id: number,blogId?: number){
     this.id=id;
     this.contactService.getContactById(this.id).subscribe(data => {
       this.contact = data;
     }, error => console.log(error));
    this.showModal = true; // Show-Hide Modal Check
    // this.BlogId=blogId;
    this.eventsFlag="E";
    this.eventsText ="แก้ไขแบบสอบถาม";
   }
   
   deleteContact(id: number,blogId?: number,fileId1? : string,fileId2? : string ){
     let isConfirm : Boolean | any ;
     isConfirm=confirm("Are you sure to delete "+ id)
   
     if(isConfirm===false) {
       return;
     }
   
       this.contactService.deleteContact(id).subscribe( data => {
         console.log(data);
         this.getContacts();
     
         // this.deleteFiles(fileId1);
         // this.deleteFiles(fileId2);
       })
      //  this.BlogId=blogId;
       this.eventsFlag="D";
     
   }

   
   onTextChange(value : any,name?: string)
   {
    this.inputPass=false;
     this.txtValue = name;
     if(value == '')
     {
       this.message="กรุณาระบุ ";
       this.inputPass=true;
     }
   }

  saveContact(){
    this.contactService.createContact(this.contact ).subscribe( data =>{
      console.log(data);
      this.getContacts();//ย้อนกลับมาหน้าตัวเอง
      //this.goToContactList();
    },
    error => console.log(error));
  }

  goToContactList(){
    this.router.navigate(['/contactus-component']);
  }
  
  // onSubmit(){
  //   console.log(this.contact );
  //   this.saveContact();
  // }

  //for Submit form when action
onSubmit(AddEditMode : string){
this.verifyData(this.contact);

if (this.inputPass===false){
  this.contact2=this.contact;
  this.processResult(this.contact2);
//บังคับให้อยู่กลุ่ม 2 เป็นผู้ติดต่อ เรื่องแบบสอบถาม ว่าซั่น
this.contact.groupId=2;
  if (AddEditMode=="A") {
    this.contactService.createContact(this.contact).subscribe( data =>{
      console.log(data);
      
       this.getContacts();//ย้อนกลับมาหน้าตัวเอง
    },
    error => console.log(error));
  }else if (AddEditMode=="E"){
    this.contactService.updateContact(this.id, this.contact).subscribe( data =>{
      
      console.log(data);
       this.getContacts();//ย้อนกลับมาหน้าตัวเอง
    }
    , error => console.log(error));
  }
  this.hide();
   
  }

  // this.processResult(this.contact);
}

  scrollToElement($element : any): void {
    console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
   }

   //for popup form
showModal: boolean | any;
registerForm: FormGroup | any;
submitted = false;

show()
 { 
   this.showModal = true;
 }

hide()
{
  this.showModal = false;
}

verifyData(data : Contact |any) {
  this.inputPass=false;

  if(`${data.firstName}`===""){
    alert("กรุณาระบุชื่อจริง");
    this.inputPass=true;
    return ;
  }

  if(`${data.lastName}`===""){
    alert("กรุณาระบุนามสกุล");
    this.inputPass=true;
    return ;
  }

  if(`${data.tel}`===""){
    alert("กรุณาระบุเบอร์ติดต่อ");
    this.inputPass=true;
    return ;
  }

  
  if(data.groupPeple==0){
    alert("กรุณาระบุกลุ่มบุคลากร");
    this.inputPass=true;
    return ;
  }

  if(data.groupPeple==4 && data.groupPepleDetail==""){
    alert("กรุณาระบุข้อมูลของกลุ่มอื่นๆ");
    this.inputPass=true;
    return ;
  }
  

  if(`${data.q1yn}`===""){
    alert("กรุณาตอบคำถามที่ 1");
    this.inputPass=true;
    return ;
  }

  if(`${data.q2yn}`===""){
    alert("กรุณาตอบคำถามที่ 2");
    this.inputPass=true;
    return ;
  }

  if(`${data.q3yn}`===""){
    alert("กรุณาตอบคำถามที่ 3");
    this.inputPass=true;
    return ;
  }

  if(`${data.q4yn}`===""){
    alert("กรุณาตอบคำถามที่ 4");
    this.inputPass=true;
    return ;
  }

  if(`${data.q5yn}`===""){
    alert("กรุณาตอบคำถามที่ 5");
    this.inputPass=true;
    return ;
  }

  if(data.q61yn===false && data.q62yn===false && data.q63yn===false && data.q64yn===false && data.q65yn===false && data.q66yn===false){
    alert("กรุณาตอบคำถามที่ 6");
    this.inputPass=true;
    return ;
  }

}



processResult(data : Contact |any) {
  
  this.result="";
  this.resultGrade=0;
  // alert(`${data.q1yn}`);
  if (`${data.q1yn}`==="N" && `${data.q2yn}`==="N" && `${data.q3yn}`==="N" && `${data.q4yn}`==="N" && `${data.q5yn}`==="N"
  && data.q61yn===false && data.q62yn===false && data.q63yn===false && data.q64yn===false && data.q65yn===false && data.q66yn===true)
  {
    this.resultGrade=1;
    this.getResult();
    return ;
  }

  if (`${data.q1yn}`==="N" && `${data.q2yn}`==="N" && `${data.q3yn}`==="N" && `${data.q4yn}`==="N" && `${data.q5yn}`==="N"
  && data.q61yn===false && data.q62yn===false && data.q63yn===false && data.q64yn===false && data.q65yn===false && data.q66yn===false )
  {
    this.resultGrade=1;
    this.getResult();
    return ;
  }

  if (`${data.q1yn}`==="N" && `${data.q2yn}`==="N" && `${data.q3yn}`==="N" && `${data.q4yn}`==="N" && `${data.q5yn}`==="N"
  && data.q61yn===false && data.q62yn===true && data.q63yn===false && data.q64yn===false && data.q65yn===false )
  {
    this.resultGrade=2;
    this.getResult();
    return ;
  }

  if (`${data.q1yn}`==="N" && `${data.q2yn}`==="N" && `${data.q3yn}`==="N" && `${data.q4yn}`==="N" && `${data.q5yn}`==="N"
  && data.q61yn===false && data.q62yn===false && data.q63yn===true && data.q64yn===false && data.q65yn===false )
  {
    this.resultGrade=2;
    this.getResult();
    return ;
  }


  if (`${data.q1yn}`==="N" && `${data.q2yn}`==="N" && `${data.q3yn}`==="N" && `${data.q4yn}`==="N" && `${data.q5yn}`==="N"
  && data.q61yn===false && data.q62yn===true && data.q63yn===true && data.q64yn===false && data.q65yn===false )
  {
    this.resultGrade=2;
    this.getResult();
    return ;
  }


  //กรณีเข้าเงื่่อนไขอย่างใดอย่างหนึ่ง

  if ((`${data.q1yn}`==="Y" || `${data.q2yn}`==="Y" || `${data.q3yn}`==="Y" || `${data.q4yn}`==="Y" || `${data.q5yn}`==="Y")
  && (data.q61yn===true || data.q62yn===true || data.q63yn===true || data.q64yn===true || data.q65yn===true || data.q66yn===true))
  { 
    this.resultGrade=4;
    this.getResult();
    return ;
  }

  if ((`${data.q1yn}`==="N" || `${data.q2yn}`==="N" || `${data.q3yn}`==="N" || `${data.q4yn}`==="N" || `${data.q5yn}`==="N")
  && (data.q61yn===true || data.q62yn===true || data.q63yn===true || data.q64yn===true || data.q65yn===true || data.q66yn===false))
  { 
    this.resultGrade=4;
    this.getResult();
    return ;
  }

 }

 getResult(){
  this.result="";
  this.bgColor="";
  if (this.resultGrade==5){
    this.result="คุณมีความเสี่ยงสูงมากในการสัมผัสโรค COVID 19 ไม่อนุญาติให้เข้าทำงาน";
    this.bgColor="red"
   }else if (this.resultGrade==4){
    this.result="คุณมีความเสี่ยงในการสัมผัสโรค COVID 19 ไม่อนุญาติให้เข้าทำงาน";
    this.bgColor="red"
   }else if (this.resultGrade==3){
    this.result="คุณมีความเสี่ยงในการสัมผัสโรค COVID 19 ที่ต้องเฝ้าระวังอาการ ไม่อนุญาติให้เข้าทำงาน";
    this.bgColor="red"
   }else if (this.resultGrade==2){
    this.result="คุณไม่มีความเสี่ยงในการสัมผัสโรค COVID 19 แต่เฝ้าระวังอาการ อนุญาติให้เข้าทำงานได้";
    this.bgColor="yellow"
   }else if (this.resultGrade==1){
    this.result="คุณยังไม่มีความเสี่ยงในการสัมผัสโรค COVID 19 อนุญาติให้เข้าทำงานได้";
    this.bgColor="green"
   }else if (this.resultGrade==0){
    this.result="กรุณากรอกแบบสอบถามให้ครบทุกข้อ";
    this.bgColor="pink"
   }
 }

 clearResult(id?:number){
  this.result="";
  this.bgColor="";
 }

}

