<!-- <!DOCTYPE html>
<html lang="en">


<head>
    <meta charset="UTF-8">
    <meta name=viewport content="width=device-width, initial-scale=1">
    <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
    <title>Document</title>
</head>
<app-menubar></app-menubar>

<body>
    <div class="ma">
    <div class="container">
        <div class="row "id="id1">
            <div class="col-md-5" style="margin-top: 11vh;">
                <div id="myCarousel" class="carousel slide" data-ride="carousel" style="text-align:center" >
                    <div class="carousel-inner">
                        <div class="carousel-item active"> <img src="https://www.helloshopsonline.com/media/catalog/product/b/l/blackmores-marine-q10-collagen-advance-_-60-_.jpg" class="rounded">
                        </div>
                        <div class="carousel-item"> <img src="https://th-test-11.slatic.net/p/529e2f5963fcb0814d3baff189f0d65e.jpg" class="rounded"> </div>
                        <div class="carousel-item"> <img src="https://th-test-11.slatic.net/p/5e02271a38c4c85c33af9e7d1e5d8c65.jpg" class="rounded"> </div>
                    </div>
                    <ol class="carousel-indicators list-inline">
                        <li class="list-inline-item active"> <a id="carousel-selector-0" class="selected"
                                data-slide-to="0" data-target="#myCarousel"> <img src="https://www.helloshopsonline.com/media/catalog/product/b/l/blackmores-marine-q10-collagen-advance-_-60-_.jpg"
                                    class="img-fluid rounded"> </a> </li>
                        <li class="list-inline-item"> <a id="carousel-selector-1" data-slide-to="1"
                                data-target="#myCarousel"> <img src="https://th-test-11.slatic.net/p/529e2f5963fcb0814d3baff189f0d65e.jpg"
                                    class="img-fluid rounded"> </a> </li>
                        <li class="list-inline-item"> <a id="carousel-selector-2" data-slide-to="2"
                                data-target="#myCarousel"> <img src="https://th-test-11.slatic.net/p/5e02271a38c4c85c33af9e7d1e5d8c65.jpg"
                                    class="img-fluid rounded"> </a> </li>
                    </ol>
                </div>
            </div>
            <div class="col-md-7" style="margin-top: 11vh;">
                <p id="t1">{{productname}}</p> 
                <hr style="border-top: 2px dashed #ddd8d8;">
                <div id="price">
                    <p id="p2" style="color: rgb(248, 90, 33);">{{price}}</p>
                </div><br>
                <p>การจัดส่ง:<span><img id="img"src="assets/img/free.png" width="17px"></span><span style="margin-left: 8px;color: #000;">ฟรีค่าจัดส่ง</span></p>
                <p id="Ar2">ฟรีค่าจัดส่ง เมื่อสั่งสินค้าขั้นต่ำ฿1000</p>

                <p id="Ar4">
                    <svg id="Ar3" xmlns="http://www.w3.org/2000/svg"width="20" height="20" fill="currentColor"
                         viewBox="0 0 16 16" style="color: #000;">
                        <path
                            d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                    </svg>
                    การจัดส่ง ถึง<span> <select >
                    <option >กรุงเทพมหานคร</option>
                    <option >กำแพงเพชร</option>
                    <option >กาญจนบุรี</option>
                    <option >กระบี่</option>
                  </select></span></p>
                <p id="Ar5">ค่าจัดส่งเริ่มต้น<span style="color: #000;">฿0 -
                        ฿50</span></p>
                <p style="line-height:100px;">จำนวน</p>  
              <form name=form1>
                     <input type=button value=" + " onclick="document.form1.input1.value++">
                    <input name=input1 value=" 1" size=5 onfocus="buffer=this.value" onchange="if (isNaN(this.value)) { this.value=buffer}">
                    <input type=button value=" - " onclick="document.form1.input1.value--">
                </form><br> 
        <button type="button" class="btn btn-danger">ซื้อสินค้า</button>
            </div>
        </div>
        <div class="container" style="background-color: #fff"><br>
        <h4>{{title}}</h4><br>
        <h5>หมวดหมู่:<span id="c1"style="color: #7f7d3f" >{{Category}}</span></h5>
        <h5>ยี่ห้อ:<span id="c2"style="color: #7f7d3f" >{{brand}}</span></h5>
        <h5>หมายเลขอย.:<span id="c3"style="color: #7f7d3f" >{{id}}</span></h5>
        <h5>ลักษณะ:<span id="c4"style="color: #7f7d3f" >{{look}}</span></h5>
        <h4 >{{title1}}</h4><br>
        <p>{{ details}}</p>
        <div class="list">
        <p style="color: #000;">> จุดเด่นและคุณสมบัติ < </p>
        <p>{{property}}</p>
        <p>{{property1}}</p>
        <p>{{property2}}</p><br>
        <p id="e1" style="color: #000;">> วิธีการรับประทาน < </p>
        <p>{{ item}}</p><br>
        <p style="color: #000;">> ส่วนประกอบ < </p>
        <p>{{ingredient}}</p><br>
        </div>
        </div>
        <br>
        <h3>สินค้าแนะนำ</h3><hr class="style2"><br>
     
         <div class="container">
            <div class="row">
               <div class="col-md-3 col-sm-6">
                <div class="card">
                    <img class="card-img-top" src="https://www.blackmores.co.th/media/product/thmb-1019.jpg" alt="Card image cap" >
                    <div class="card-body"><a  href="#id1" ><i>
                      <p class="card-text">Blackmores Multi Vitamins+Minerals แบลคมอร์ส วิตามินและเกลือแร่รวม</p>
                      <b>฿500</b></i> </a>
                    </div>
                  </div>
               </div>
               <div class="col-md-3 col-sm-6">
                <div class="card">
                    <img class="card-img-top" src="https://www.blackmores.co.th/media/product/thmb-1023.jpg" alt="Card image cap">
                    <div class="card-body">
                      <p class="card-text">Blackmores Bio Zinc  แบลคมอร์ส ไบโอ ซิงค์</p>
                      <b>฿500</b>
                    </div>
                    <br>
                  </div>
               </div>
               <div class="col-md-3 col-sm-6">
                <div class="card">
                    <img class="card-img-top" src="https://www.blackmores.co.th/media/product/thmb-1022.jpg" alt="Card image cap">
                    <div class="card-body">
                      <p class="card-text">Blackmores Choles-Bloc
                        แบลคมอร์ส โคเลส-บลอค (ผลิตภัณฑ์เสริมอาหาร)</p>
                        <b>฿500</b>
                    </div>
                  </div>
               </div>
               <div class="col-md-3 col-sm-6">
                <div class="card">
                    <img class="card-img-top" src="https://www.blackmores.co.th/media/product/thmb-2058.jpg" alt="Card image cap">
                    <div class="card-body">
                      <p class="card-text">Blackmores 12+ Multi
                        แบลคมอร์ส 12+ มัลติ (ผลิตภัณฑ์เสริมอาหาร)</p>
                      <b>฿500</b>
                    </div>
                  </div>
               </div>
            </div>
        </div> 
        <br>
    </div>
</div>
</body>
<app-footerbar></app-footerbar>

</html> -->


<!DOCTYPE html>
<html lang="en">


<head>
    <meta charset="UTF-8">
    <meta name=viewport content="width=device-width, initial-scale=1">
    <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
    <title>Document</title>
</head>


<body class="ht">
   
   <div #back></div>
   <div id="bottom" (click)="scrollToElement(back)" >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
  </div>

  <div class="bg_2_3" >
    <div class="container">
      <div class="row">
       
        <div class="col-md-9 col-sm-9 btn-animated ">
          
        <div>{{getDataForBlog(BlogId)}}</div>
        <div *ngFor="let goods of goodss"><br><br>
            <h1 class="f-white fs3 text-center">{{goods.namePoint}}</h1>
            <!-- <h5 class="f-white fs2 text-center">{{goods.desc1}}</h5> -->
            <div class="container">
              <div class="row">
                <div class="col-xl-1 col-md-1"></div>
                <div class="col-xl-10 col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
                <div class="col-xl-1 col-md-1"></div>
              </div>
            </div>

            

        <div *ngIf="role1">
          <div>
            <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
            <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-sm-2 btn-animated-"></div>
    <div class="col-md-1 col-sm-1 btn-animated">
      <div class="container text-center">
        <a [routerLink]="['/kingdom-feedmills-component']" routerLinkActive="router-link"><h6 class="fs3 f-red">X</h6></a>
      </div>
    </div>
    </div>
  </div>
</div>


    <div class="container">
        <div>{{getDataForBlog(BlogId)}}</div>
        <div *ngFor="let goods of goodss">


        <div class="row" id="id1">
          <div class="col-md-5" style="margin-top: 2vh;">
              <div id="myCarousel" class="carousel slide" data-bs-ride="carousel" style="text-align:center" >
                  <div class="carousel-inner zoom3">
                      <div class="carousel-item active"> <img src={{goods.imgPathTemp1}} class="rounded"></div>
                      <div class="carousel-item"> <img src={{goods.imgPathTemp2}} class="rounded"> </div>
                  </div>
                  <!-- <ol class="carousel-indicators list-inline">
                      <li class="list-inline-item active"> <a id="carousel-selector-0" class="selected"
                              data-slide-to="0" data-target="#myCarousel"> <img src={{goods.imgPathTemp1}} class="img-fluid rounded"> </a> </li>
                      <li class="list-inline-item"> <a id="carousel-selector-1" data-slide-to="1"
                              data-target="#myCarousel"> <img src={{goods.imgPathTemp2}} class="img-fluid rounded"> </a> </li>
                  </ol> -->
              </div>
          </div>

           <div class="col-md-7" style="margin-top: 5vh;">
                <!-- <h4>ชื่อสินค้า</h4><br>
                <h5 class="F7">{{goods.namePoint}}</h5>
                <br> -->
                <h4 class="head-label f-white">ข้อมูลจำเพาะ</h4><br>
                <!-- <h5 class="F7">{{goods.desc1}}</h5> -->

                <div>{{splitStringFormat(goods.desc1)}}</div>
                <ul >
                    <li *ngFor="let detailList of detailLists; let i = index" class="li2">{{detailList}}</li>
                </ul>

                <br>
                <h4 class="head-label f-white">รายละเอียดสินค้า</h4><br>
                <!-- <h5 class="F8">{{goods.desc2}}</h5> -->

                <div>{{splitStringFormat(goods.desc2)}}</div>
                <ul >
                    <li *ngFor="let detailList of detailLists; let i = index" class="li2">{{detailList}}</li>
                </ul>

            
                <div *ngIf="role1">
                  <br>
                  <div>
                    <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                    <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                </div>

               
           </div>
        </div>

        <br><br>
        <div class="row" id="id1">
          <div class="col-md-5">
            <h4 class="head-label f-white">ติดต่อฝ่ายขาย</h4><br>
            <ul >
              <li class="li2">Facebook :  <a  href="https://www.facebook.com/mittraphapmm" target="_blank">มิตรภาพอาหารสัตว์ จำกัด</a></li>
              <li class="li2">เบอร์ติดต่อ : 086-325-1683</li>
              <li class="li2">ที่อยู่ : 120/1 หมู่ 14 ตำบล พัฒนานิคม  อำเภอ พัฒนานิคม  จังหวัด ลพบุรี   15140
                <br>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d750.2322682574272!2d100.93372499624864!3d14.849229220075248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311e0bbb8b310149%3A0xed1ecde9d5b58e45!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4oeC4tOC4leC4o-C4oOC4suC4nuC4reC4suC4q-C4suC4o-C4quC4seC4leC4p-C5jCDguIjguLPguIHguLHguJQ!5e1!3m2!1sth!2sth!4v1646030962981!5m2!1sth!2sth" 
                class="map" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy">
              </iframe>
              
              </li>
            </ul>
          </div>
          <div class="col-md-7" >
            <h4 class="head-label f-white">ความพึงพอใจของลูกค้า </h4><br>
            <div>{{getSrc(goods.href1)}}</div>

            <iframe  [src]="safeSrc" width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
          </div>
        </div>

       

      
      
  
    </div>
    <br><br><br><br><br>
    <div *ngIf="role1">
        <div class="content">
          <button type="button" class="btn btn-primary" (click)="addGoods(BlogId)">Add new ({{BlogId}})</button>
        </div>
      </div>



        <div class="bg_2_3" style="margin-top: 2vh;" >
          <div class="container">
            <div class="row">
              <div class="col-md-8 col-sm-8 btn-animated">
                  <h1 class="f-white fs3 text-center">สินค้าแนะนำ</h1>
                 
                  <div class="container">
                    <div class="row">
                      <div class="col-xl-1 col-md-1"></div>
                      <div class="col-xl-10 col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
                      <div class="col-xl-1 col-md-1"></div>
                    </div>
                  </div>
              </div>
          <div class="col-md-4 col-sm-4 btn-animated-"></div>
          </div>
        </div>
      </div>


        <div class="container">
          <div>{{getDataForBlog(MainBlogId)}}</div>
          <div class="row">
             <div class="col-md-3 col-sm-6" *ngFor="let goods of goodss">
              <a  [routerLink]="['/product-and-services-detail-component',goods.groupIdRef1,goods.groupId]" routerLinkActive="router-link" (click)="setVisitCount(goods.id,goods.countVisits)">
                <div class="card text-center">
                  <div class="card2- text-center" >
                    <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
                  </div>
                  <div class="f-green fs6">{{goods.namePoint}}</div>
                  <div class="f-gray fs6">{{goods.desc1}}</div>
                  <div class="f-gray fs13">เข้าชม : {{goods.countVisits}} ครั้ง</div>
                </div>


               </a>
                <div *ngIf="role1">
                  <br>
                  <div>
                    <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                    <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPath1,goods.imgPath2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                </div>
                <br>
             </div>
          </div>
          <div *ngIf="role1">
            <div class="content">
              <button type="button" class="btn btn-primary" (click)="addGoods(399)">Add new ({{MainBlogId}})</button>
            </div>
          </div>
      </div> 
     
         <!-- <div class="container">
            <div class="row">
               <div class="col-md-3 col-sm-6">
                <div class="card">
                    <img class="card-img-top" src="https://www.blackmores.co.th/media/product/thmb-1019.jpg" alt="Card image cap" >
                    <div class="card-body"><a  href="#id1" ><i>
                      <p class="card-text">Blackmores Multi Vitamins+Minerals แบลคมอร์ส วิตามินและเกลือแร่รวม</p>
                      <b>฿500</b></i> </a>
                    </div>
                  </div>
               </div>
               <div class="col-md-3 col-sm-6">
                <div class="card">
                    <img class="card-img-top" src="https://www.blackmores.co.th/media/product/thmb-1023.jpg" alt="Card image cap">
                    <div class="card-body">
                      <p class="card-text">Blackmores Bio Zinc  แบลคมอร์ส ไบโอ ซิงค์</p>
                      <b>฿500</b>
                    </div>
                    <br>
                  </div>
               </div>
               <div class="col-md-3 col-sm-6">
                <div class="card">
                    <img class="card-img-top" src="https://www.blackmores.co.th/media/product/thmb-1022.jpg" alt="Card image cap">
                    <div class="card-body">
                      <p class="card-text">Blackmores Choles-Bloc
                        แบลคมอร์ส โคเลส-บลอค (ผลิตภัณฑ์เสริมอาหาร)</p>
                        <b>฿500</b>
                    </div>
                  </div>
               </div>
               <div class="col-md-3 col-sm-6">
                <div class="card">
                    <img class="card-img-top" src="https://www.blackmores.co.th/media/product/thmb-2058.jpg" alt="Card image cap">
                    <div class="card-body">
                      <p class="card-text">Blackmores 12+ Multi
                        แบลคมอร์ส 12+ มัลติ (ผลิตภัณฑ์เสริมอาหาร)</p>
                      <b>฿500</b>
                    </div>
                  </div>
               </div>
            </div>
        </div>  -->
  
    </div>

   

    <div class="bg_4_3-">
      <div class="container text-center">
        <a [routerLink]="['/kingdom-feedmills-component']" routerLinkActive="router-link"><h6 class="fs3 f-red">X</h6></a>
      </div>
    </div>

<!-- Creates the bootstrap modal where the image will appear -->
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <form (ngSubmit)="onSubmit(eventsFlag)">
  <div class="modal-dialog" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
          <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
        </div>
        <div class="modal-body">

          <tr>
            <td><label> Groups : {{BlogId}}</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupId"
                name="groupId"></td>
          </tr>
          <tr>
            <td><label> Order No : </label></td>
            <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="goods.orderNo" name="orderNo"></td>
          </tr>
          <tr>
            <td><label> GroupsRef1</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef1"
                name="groupIdRef1"></td>
          </tr>
          <tr>
            <td><label> GroupsRef2</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef2"
                name="groupIdRef2"></td>
          </tr>
          <tr>
            <td><label> GroupsRef3</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef3"
                name="groupIdRef3"></td>
          </tr>
          <tr>
            <td><label> Href1</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href1" name="href1">
            </td>
          </tr>
          <tr>
            <td><label> Href2</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href2" name="href2">
            </td>
          </tr>
          <tr>
            <td><label> Href3</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href3" name="href3">
            </td>
          </tr>
          <tr>
            <td><label> Name</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.namePoint"
                name="namePoint"></td>
          </tr>
          <tr>
            <td><label> Path1</label></td>
            <td><input type="text" class="form-control control-size" id="imgPath1" [(ngModel)]="goods.imgPath1"
                name="imgPath1"></td>
          </tr>
          <tr>
            <td>
              <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                  (click)="upload('imgPath1')">Upload</button></div>
            </td>
            <td>
              <div class="col-8">
                <label class="btn btn-default p-0 control-size">
                  <input type="file" (change)="selectFile($event)" />
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td><label> Path2</label></td>
            <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.imgPath2"
                name="imgPath2"></td>
          </tr>
          <tr>
            <td>
              <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                  (click)="upload('imgPath2')">Upload</button></div>
            </td>
            <td>


              <div class="col-8">
                <label class="btn btn-default p-0 control-size">
                  <input type="file" (change)="selectFile($event)" />
                </label>
              </div>


            </td>
          </tr>
          <tr>
            <td><label> Desc1</label></td>
            <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc1"
                name="desc1"></textarea></td>
          </tr>
          <tr>
            <td><label> Desc2</label></td>
            <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc2"
                name="desc2"></textarea></td>
          </tr>
          <tr>
            <td></td>
            <td>
              <div *ngIf="currentFile" class="progress my-3">
                <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                  attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                  [ngStyle]="{ width: progress + '%' }">
                  {{ progress }}%
                </div>
              </div>
            </td>
          </tr>
        </div>
        <div class="modal-footer">
          <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
          <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
        </div>
     
      </div>
    </div>
  </div>
 </form>
</div>
  <!-- end  Creates the bootstrap modal where the image will appear -->

</body>

</html>