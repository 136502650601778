<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>

<body class="ht">

   <div #back></div>
   <div id="bottom" (click)="scrollToElement(back)" >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
    </div>

    <div class="bg_2_1">
      <div class="bg_1_2-">
        <div class="bg_0_3">
      <div>{{getDataForBlog(30101)}}</div>
      <div *ngFor="let goods of goodss">
        <!-- <section id="sushi-header" style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
        </section> -->
        
 
        <div class="btn-animated margin_head">
          <h1 class="f-white fs1" >{{goods.namePoint}}</h1><br>
          <h5 class="F2 fs2" >{{goods.desc1}}</h5>

        </div>
        
          <div *ngIf="role1">
              <div class="container">
                <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
          </div>
      </div>


      <div class="container margin_head2">
        <div class="card-">
        <div>{{getDataForBlog(30102)}}</div>
          <div class="row text-center">
            <div  class="col-xl-3 col-md-3 col-sm-1"> </div>

              <div  class="col-xl-2 col-md-2 col-sm-1" *ngFor="let goods of goodss"> 
              <div class="card3- text-center">
                <a href={{goods.href1}}>
                  <div class="item-zoom">
                    <img src={{goods.imgPathTemp1}} alt="Jim Hawkins" title={{goods.namePoint}} class="round2 text-center">
                  </div>
                </a>
                <div *ngIf="role1">
                  <div>
                    <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                    <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                </div>

                <p class="f-white fs9 btn-animated">
                  {{goods.namePoint}}
                </p>
              </div>
             
              
            </div>
            <div  class="col-xl-3 col-md-3 col-sm-1"> </div>

            <div *ngIf="role1">
              <div class="container text-center">
                <button type="button" (click)="addGoods(30102)" class="btn btn-primary" style="margin-left: 10px">Add new (30102)</button>
              </div>
            </div>

          </div>
        </div>
        </div>
 
 
      </div>

    <div class="bg_4_3 margin_head2 btn-animated" >
      <div class="container">
        <div class="row">
            <div class="col-md-8 col-sm-8 btn-animated-">
            <div>{{getDataForBlog(30101)}}</div>
             <div *ngFor="let goods of goodss">
               
              <h1 class="F3 fs3">{{goods.namePoint}}</h1>
              <h6 class="f-white fs2">
                <i class="fa fa-quote-left f-white fs2" aria-hidden="true" ></i>
                  {{goods.desc2}}
                <i class="fa fa-quote-right f-white fs2" aria-hidden="true" ></i>
              </h6>

              <!-- <div><img class="mtp_underline" src="assets\img\underline\underline.png"> </div> -->

              <div *ngIf="role1">
                <div>
                  <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                  <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 btn-animated-"></div>
        </div>
      
      </div>
    </div>
  </div>
</div>



     
        <!-- <div>{{getDataForBlog(221)}}</div>
        <div *ngFor="let goods of goodss">
          <section id="sushi-header" style="background: url({{goods.imgPathTemp1}}); background-repeat: no-repeat; background-size: 100% 100%;" >
          </section>
          
          <br><br>
          <div class="btn-animated">
            <h1 class="F1 fs1" >{{goods.namePoint}}</h1><br><br>
            <h5 class="F2 fs2" >{{goods.desc1}}</h5>
          </div>
  
            <div *ngIf="role1">
                <div class="container">
                  <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info" style="margin-left: 10px"> Update</button>
                  <button type="button" (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
              </div>
        </div>
        <br><br> -->

<div id="home" class="id-top"></div>
<div class="bg_1_1">
  <div class="bg_2_2">

    <div class="bg_2_3" >
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-8 btn-animated">
            
          <div>{{getDataForBlog(30201)}}</div>
          <div *ngFor="let goods of goodss">
              <h1 class="f-white fs3 text-center">{{goods.namePoint}}</h1>
              <h5 class="f-white fs2 text-center">{{goods.desc1}}</h5>
              <div class="container">
                <div class="row">
                  <div class="col-xl-1 col-md-1"></div>
                  <div class="col-xl-10 col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
                  <div class="col-xl-1 col-md-1"></div>
                </div>
              </div>
  
          <div *ngIf="role1">
            <div>
              <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
              <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-4 btn-animated-"></div>
      </div>
    </div>
  </div>

  <div class="container">
      <div>{{getDataForBlog(30301)}}</div>
        <div *ngFor="let goods of goodss">
          <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>    
          <ng-template #content>
            <div class="row bg3" >
              <div id="first" class="col-md-6">
                <div class="card2 text-center" >
                  <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
                </div>
                <br>
                <!-- <img src={{goods.imgPath1}} class="card-img-1" alt="Title5" id="block1"> style="width: 100%; height: 100%;" -->
              </div>
              <div id="second"  class="col-md-6">
                <div class="content-text">
                  <b class="f-green fs5" id="block">{{goods.namePoint}}</b>
                  <p class="f-gray fs6" id="block2">{{goods.desc1}}</p>

                  <div *ngIf="goods.isNotShowAddition;then canshow2 else notshow2">here is ignored</div>    
                  <ng-template #canshow2>
                    <button type="button" class="btn btn-info" data-bs-toggle="collapse" [attr.data-bs-target]="'#i' + goods.id" >>>></button>
                    <div id="i{{goods.id}}" class="collapse">
                      <p class="f-gray fs6" id="block2">{{goods.desc2}}</p>
                    </div>
                  </ng-template>
                  <ng-template #notshow2>
                      
                  </ng-template>
                </div>
              </div>
          </div>
            

            <div *ngIf="role1">
              <div>
                <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
            </div>
            <br>
            
          </ng-template>

          <ng-template #other_content>
            <div class="row bg4">
              <div id="second" class="col-md-6"> 
                <div class="content-text">
                  <b class="f-green fs5" id="block">{{goods.namePoint}}</b>
                  <p class="f-gray fs6" id="block2">{{goods.desc1}}</p>

                  <div *ngIf="goods.isNotShowAddition;then canshow2 else notshow2">here is ignored</div>    
                  <ng-template #canshow2>
                    <button type="button" class="btn btn-info" data-bs-toggle="collapse" [attr.data-bs-target]="'#i' + goods.id" >>>></button>
                    <div id="i{{goods.id}}" class="collapse">
                      <p class="f-gray fs6" id="block2">{{goods.desc2}}</p>
                    </div>
                  </ng-template>
                  <ng-template #notshow2>
                      
                  </ng-template>
                </div>
              </div>
              <div id="first" class="col-md-6">
                <div class="card2 text-center" >
                  <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
                </div>
                <br>
              </div>
          </div>
 

            <div *ngIf="role1">
              <div>
                <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
            </div>
            <br>
          </ng-template>

         

      </div>

      <div *ngIf="role1">
        <div class="container text-center">
          <button type="button" (click)="addGoods(30301)" class="btn btn-primary" style="margin-left: 10px">Add new (30301)</button>
        </div>
      </div>
    </div>
    <div class="bg_5_3 margin_footer"></div>
  </div>
</div>


<div id="home2" class="id-top"></div>
<div class="bg_2_1">
  <div class="bg_2_2">
    <div class="bg_2_3" >
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-8 btn-animated">
            
          <div>{{getDataForBlog(30202)}}</div>
          <div *ngFor="let goods of goodss">
              <h1 class="f-white fs3 text-center">{{goods.namePoint}}</h1>
              <h5 class="f-white fs2 text-center">{{goods.desc1}}</h5>
              <div class="container">
                <div class="row">
                  <div class="col-xl-1 col-md-1"></div>
                  <div class="col-xl-10 col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
                  <div class="col-xl-1 col-md-1"></div>
                </div>
              </div>
  
          <div *ngIf="role1">
            <div>
              <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
              <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-4 btn-animated-"></div>
      </div>
    </div>
  </div>

  
  <div class="container">
      <div>{{getDataForBlog(30302)}}</div>
        <div *ngFor="let goods of goodss">
          <div *ngIf="goods.isSwap;then content else other_content">here is ignored</div>    
          <ng-template #content>
            <div class="row bg3" >
              <div id="first" class="col-md-6">
                <div class="card2 text-center" >
                  <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
                </div>
                <br>
                <!-- <img src={{goods.imgPath1}} class="card-img-1" alt="Title5" id="block1"> -->
              </div>
              <div id="second"  class="col-md-6">
                <div class="content-text"><br>
                  <b class="f-green fs5" id="block">{{goods.namePoint}}</b>
                  <p class="f-gray fs6" id="block2">{{goods.desc1}}</p>

                  <div *ngIf="goods.isNotShowAddition;then canshow2 else notshow2">here is ignored</div>    
                  <ng-template #canshow2>
                    <button type="button" class="btn btn-info-" data-bs-toggle="collapse" [attr.data-bs-target]="'#i' + goods.id" >>>></button>
                    <div id="i{{goods.id}}" class="collapse">
                      <p class="f-gray fs6" id="block2">{{goods.desc2}}</p>
                    </div>
                  </ng-template>
                  <ng-template #notshow2>
                      
                  </ng-template>
                </div>
              </div>
          </div>
            

            <div *ngIf="role1">
              <div>
                <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
            </div>
            <br>
            
          </ng-template>

          <ng-template #other_content>
            <div class="row bg3">
              <div id="second" class="col-md-6"> 
                <div class="content-text"><br>
                  <b class="f-green fs5" id="block">{{goods.namePoint}}</b>
                  <p class="f-gray fs6" id="block2">{{goods.desc1}}</p>

                  <div *ngIf="goods.isNotShowAddition;then canshow2 else notshow2">here is ignored</div>    
                  <ng-template #canshow2>
                    <button type="button" class="btn btn-info-" data-bs-toggle="collapse" [attr.data-bs-target]="'#i' + goods.id" >>>></button>
                    <div id="i{{goods.id}}" class="collapse">
                      <p class="f-gray fs6" id="block2">{{goods.desc2}}</p>
                    </div>
                  </ng-template>
                  <ng-template #notshow2>
                      
                  </ng-template>
                </div>
              </div>
              <div id="first" class="col-md-6">
                <div class="card2 text-center" >
                  <img class="card-img-top" style="width: 100%; height: 100%;" src={{goods.imgPathTemp1}} alt="Card image cap">
                </div>
               <br>
              </div>
          </div>
 

            <div *ngIf="role1">
              <div>
                <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
            </div>
            <br>
          </ng-template>

         

      </div>

      <div *ngIf="role1">
        <div class="container text-center">
          <button type="button" (click)="addGoods(30302)" class="btn btn-primary" style="margin-left: 10px">Add new (30302)</button>
        </div>
      </div>
    </div>
    <div class="bg_5_3 margin_footer"></div>
    </div>
  </div>

  <div id="home3" class="id-top"></div>
  <div class="bg_1_1">
    <div class="bg_2_2">
      <div class="bg_2_3" >
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-sm-8 btn-animated">
              
            <div>{{getDataForBlog(30203)}}</div>
            <div *ngFor="let goods of goodss">
                <h1 class="f-white fs3 text-center">{{goods.namePoint}}</h1>
                <h5 class="f-white fs2 text-center">{{goods.desc1}}</h5>
                <div class="container">
                  <div class="row">
                    <div class="col-xl-1 col-md-1"></div>
                    <div class="col-xl-10 col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
                    <div class="col-xl-1 col-md-1"></div>
                  </div>
                </div>
    
            <div *ngIf="role1">
              <div>
                <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 btn-animated-"></div>
        </div>
      </div>
    </div>
  
  
    <div class="container">
      <div class="bg3" >
        <section id="about" class="about">
          <div>{{getDataForBlog(30303)}}</div>
          <ul class="grid">
            <li  *ngFor="let goods of goodss"> 
              <!-- <a class="nav-link" [routerLink]="['/productandservices-component',goods.href1]"  routerLinkActive="router-link">
                <img src={{goods.imgPathTemp1}} alt="Jim Hawkins" title="Jim Hawkins" class="round zoom2" width="200px" height="200px">
              </a> -->
              <a href={{goods.href1}}>
                <img src={{goods.imgPathTemp1}} alt="Jim Hawkins" title="Jim Hawkins" class="round zoom2-" width="200px" height="200px">
              </a>

              <!-- <button type="button" class="btn btn-info" data-toggle="collapse" [attr.data-target]="'#i' + goods.id" >เพิ่มเติม >></button>
              <div id="i{{goods.id}}" class="collapse"> -->
              <br>
              <br>
              <h4 class="f-green fs5">{{goods.namePoint}}</h4>
              <h6 class="f-gray fs6">{{goods.desc1}}</h6>
  
              <div *ngIf="role1">
                <div>
                  <button type="button" (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                  <button type="button" (click)="deleteGoods(goods.id,goods.groupId,goods.imgPathTemp1,goods.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
              </div>
              <br>
            </li>
  
            <div *ngIf="role1">
              <div class="container text-center">
                <button type="button" (click)="addGoods(30303)" class="btn btn-primary" style="margin-left: 10px">Add new (30303)</button>
              </div>
            </div>
          </ul>
        </section>
       </div>
      </div>
      <div class="bg_5_3 margin_footer"></div>
    </div>
  </div>
  
  <div class="bg_4_1">
  <div class="bg_2_2">
    
  
      
  <div>{{getDataForBlog(361)}}</div>
    <div *ngFor="let goods of goodss">

      <div id={{goods.href1}} class="id-top"></div>

      <div class="bg_3_3-">
        <div class="container" id="myHeader">
        <br>
        
        <div class="bg3">
          <div class="bg_2_3" >
            <div class="container">
              <div class="row">
                <div class="col-md-8 col-sm-8 btn-animated">
                    <h1 class="f-white fs3 text-center">{{goods.namePoint}}</h1>
                    <h5 class="f-white fs2 text-center">{{goods.desc1}}</h5>
                    <div class="container">
                      <div class="row">
                        <div class="col-xl-1 col-md-1"></div>
                        <div class="col-xl-10 col-md-10"><img class="mtp_underline" src="assets\img\underline\underline.png"></div>
                        <div class="col-xl-1 col-md-1"></div>
                      </div>
                    </div>
        
                <div *ngIf="role1">
                  <div>
                    <button (click)="updateGoods(goods.id,goods.groupId)" class="btn btn-info"> Update</button>
                    <button (click)="deleteGoods(goods.id,goods.groupId)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                  </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 btn-animated-"></div>
            </div>
          </div>
        </div>
    

        <section id="about" class="about">
          <div class="row">
            <div>{{getDataForBlogL2(goods.groupIdRef1)}}</div>
            <div class="col-md-3 col-sm-6" *ngFor="let goodsL2 of goodssL2">
              <div class="product-grid4" >
                <a [routerLink]="['/product-and-services-detail-component',goodsL2.groupIdRef1,goodsL2.groupId]" routerLinkActive="router-link" (click)="setVisitCount(goodsL2.id,goodsL2.countVisits)">
                <div class="product-image4 zoom-">
                  
                    <img class="pic-1" src={{goodsL2.imgPathTemp1}}>
                    <img class="pic-2" src={{goodsL2.imgPathTemp2}}>
                    
                </div>
                <div class="product-content">
                  <div class="f-green fs6">{{goodsL2.namePoint}}</div>
                  <div class="f-gray fs6">{{goodsL2.desc1}}</div>
                  <!-- <div>{{getVisitCount()}}</div> -->
                  <div class="f-gray fs13">เข้าชม : {{goodsL2.countVisits}} ครั้ง</div>
                </div>
              </a>
              </div>
              <br>
              <div *ngIf="role1">
                <div>
                  <button type="button" (click)="updateGoods(goodsL2.id,goodsL2.groupId)" class="btn btn-info"> Update</button>
                  <button type="button" (click)="deleteGoods(goodsL2.id,goodsL2.groupId,goodsL2.imgPathTemp1,goodsL2.imgPathTemp2)" class="btn btn-danger" style="margin-left: 10px"> Delete</button>
                </div>
              </div>
              <br>
            </div>

            <div *ngIf="role1">
              <div class="content">
                <button type="button" class="btn btn-primary" (click)="addGoods(goods.groupIdRef1)">Add new ({{goods.groupIdRef1}})</button>
              </div>
            </div>

          </div>
        </section>
        </div>
      </div>
    </div><br>
   </div>

  <div *ngIf="role1">
    <div class="content">
      <button type="button" class="btn btn-primary" (click)="addGoods(361)">Add new (361)</button>
    </div>
  </div>

  <div class="bg_5_3 margin_footer"></div>
</div>
</div>

  
    <!-- Creates the bootstrap modal where the image will appear -->
    <div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <form (ngSubmit)="onSubmit(eventsFlag)">
      <div class="modal-dialog" id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable" role="document">
          <div class="modal-content">
            
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
              <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
            </div>
            <div class="modal-body">
    
              <tr>
                <td><label> Groups : {{BlogId}}</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupId"
                    name="groupId"></td>
              </tr>
              <tr>
                <td><label> Order No : </label></td>
                <td><input type="text" class="form-control control-size"  id="Name" [(ngModel)]="goods.orderNo" name="orderNo"></td>
              </tr>
              <tr>
                <td><label> GroupsRef1</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef1"
                    name="groupIdRef1"></td>
              </tr>
              <tr>
                <td><label> GroupsRef2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef2"
                    name="groupIdRef2"></td>
              </tr>
              <tr>
                <td><label> GroupsRef3</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.groupIdRef3"
                    name="groupIdRef3"></td>
              </tr>
              <tr>
                <td><label> Href1</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href1" name="href1">
                </td>
              </tr>
              <tr>
                <td><label> Href2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href2" name="href2">
                </td>
              </tr>
              <tr>
                <td><label> Href3</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.href3" name="href3">
                </td>
              </tr>
              <tr>
                <td><label> Name</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.namePoint"
                    name="namePoint"></td>
              </tr>
              <tr>
                <td><label> Path1</label></td>
                <td><input type="text" class="form-control control-size" id="imgPath1" [(ngModel)]="goods.imgPath1"
                    name="imgPath1"></td>
              </tr>
              <tr>
                <td>
                  <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                      (click)="upload('imgPath1')">Upload</button></div>
                </td>
                <td>
                  <div class="col-8">
                    <label class="btn btn-default p-0 control-size">
                      <input type="file" (change)="selectFile($event)" />
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td><label> Path2</label></td>
                <td><input type="text" class="form-control control-size" id="Name" [(ngModel)]="goods.imgPath2"
                    name="imgPath2"></td>
              </tr>
              <tr>
                <td>
                  <div class="col-4"><button class="btn btn-success btn-sm" [disabled]="!selectedFiles"
                      (click)="upload('imgPath2')">Upload</button></div>
                </td>
                <td>
    
    
                  <div class="col-8">
                    <label class="btn btn-default p-0 control-size">
                      <input type="file" (change)="selectFile($event)" />
                    </label>
                  </div>
    
    
                </td>
              </tr>
              <tr>
                <td><label> Desc1</label></td>
                <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc1"
                    name="desc1"></textarea></td>
              </tr>
              <tr>
                <td><label> Desc2</label></td>
                <td><textarea class="form-control control-size" rows="2" id="note" [(ngModel)]="goods.desc2"
                    name="desc2"></textarea></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div *ngIf="currentFile" class="progress my-3">
                    <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                      attr.aria-valuenow="{{ progress }}" aria-valuemin="0" aria-valuemax="100"
                      [ngStyle]="{ width: progress + '%' }">
                      {{ progress }}%
                    </div>
                  </div>
                </td>
              </tr>
            </div>
            <div class="modal-footer">
              <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
              <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
            </div>
         
          </div>
        </div>
      </div>
     </form>
    </div>
      <!-- end  Creates the bootstrap modal where the image will appear -->

</body>
</html>