import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { GlobalConstants } from '../../common/global-constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService,private router: Router,private route: ActivatedRoute
    ,private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    if (! this.tokenStorageService.checkUser())//ถ้ายังอยู่ใน user ให้เครียร์ออกก่อน
    {
      this.tokenStorage.signOut();
    }

    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
    }
    
  }

  onSubmit(): void {

    this.authService.login(this.form).subscribe(
      data => {
        this.tokenStorage.saveToken(data.accessToken);

        // alert(data.username)
        this.tokenStorage.saveUser(data.username);

        this.isLoginFailed = false;
        this.isLoggedIn = true;


        this.roles = this.tokenStorage.getUser().roles;

 
        this.reloadPage();
        this.router.navigate(['/menubar-component']);
      },
      err => {
        // alert(err.error.message);
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }

  // onSubmit(): void {
  //   this.authService.login(this.form).subscribe(
  //     data => {
  //       this.tokenStorage.saveToken(data.accessToken);
  //       this.tokenStorage.saveUser(data);

  //       this.isLoginFailed = false;
  //       this.isLoggedIn = true;



    

  //       this.roles = this.tokenStorage.getUser().roles;
  //       // this.reloadPage();

  //       // alert(GlobalConstants.role1);

  //     },
  //     err => {
  //       this.errorMessage = err.error.message;
  //       this.isLoginFailed = true;
  //     }
  //   );
  // }

  reloadPage(): void {
    window.location.reload();
  }

}
